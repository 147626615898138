import {iconMap} from './iconMap'

export const Categories = (categories: any) => {
    if (categories) {
        categories.map((category: any) => {
            let filteredIcon = iconMap.filter((icon) => {
                return icon.value === category.icon
            });
            if (filteredIcon.length > 0) {
                category.icon = filteredIcon[0].icon;
            }
        })
        return categories;
    } else {
        return [];
    }
};
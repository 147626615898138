import { useStateValue } from "../../context/StateProvider";
import { useEffect, useState} from "react";
import OrderItem from "./Item"
import {useTranslation} from "react-i18next";

const Body = ({ action }: { action: any }) => {
  const { t, i18n } = useTranslation();
  const [{ orders }, dispatch] = useStateValue();
  const [tableTotal, setTableTotal] = useState<any>(0)

  function calculateTableTotal() {
    let total = 0;
    orders.map((order: any)=>{
      total += order.totalPrice.amount
    })
    return total/100;
  }
  useEffect(() => {
    if(orders) {
      setTableTotal(calculateTableTotal());
    }
  },[orders])

  return (
    <div className='w-full h-full bg-cartBg flex flex-col'>
        <div className='w-full h-[650px] px-6 py-10 flex flex-col gap-3 overflow-y-scroll scrollbar-hidden'>
          {
            orders && orders.map((item:any, index:number) => {
              return <OrderItem key={index} item={item} />
            } )
          }
          <div className="w-full p-1 px-2 rounded-lg bg-cartItem hover:shadow-md flex items-center justify-between gap-2 cursor-default ">
            <div className=" flex items-center  gap-2">
              <div className="flex flex-col gap-0 ">
                <p className="text-base text-gray-50">{t('tableTotal')}:{' '}<span className="text-xs text-red-600">₺</span>{tableTotal}</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Body;

import { motion } from "framer-motion";
import {AiOutlineCheck} from "react-icons/ai";
import {useStateValue} from "../../../context/StateProvider";
import { useState} from "react";
import {toast} from "react-toastify";
import {addOrderItem} from "../../../utils/functions";
import {useTranslation} from "react-i18next";


export const OrderItemAdd = ({
  orderId
}: {
  orderId: string;
}) => {
  const { t, i18n } = useTranslation();
  const [{ foodItems, sessions, socket }, dispatch] = useStateValue();
  const [quantity, setQuantity] = useState("");
  const [foodItem, setFoodItem] = useState("");

  const validateNumber = (value: any) => {
    if (isNaN(value)) {
        toast.error("Please enter a valid number", {toastId: 123});
        return "";
    }
    return value;
  };

  const clearForm = () => {
    setQuantity("");
    setFoodItem("");
  };
  const addItem = async () => {
    if (!foodItem || !quantity) {
      toast.error(t('missingRequiredFieldError'));
      return;
    }
    addOrderItem(orderId, foodItem, quantity, sessions, dispatch, socket);
    clearForm();
  }

  return (
    <>
      <motion.div
        className={`w-full flex flex-row justify-center gap-3 rounded-md bg-white`}
      >
        <p className="text-textColor font-semi-bold text-lg items-center justify-center">{t('addFoodItem')}</p>
      </motion.div>
      <motion.div
        className={`w-full flex flex-row justify-center gap-3 rounded-md`}
      >
        <div className="w-full flex justify-left flex-row gap-2 px-3 py-1 items-center">
          <div className="w-full group flex items-center gap-2  cursor-pointer justify-left">
          <select name="categories" id="categories" className="bg-transparent outline-none w-full capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer" value={foodItem} onChange={(e) => setFoodItem(e.target.value)}>
            <option defaultValue="Select item" className="bg-white capitalize">
              {t('selectProduct')}
            </option>
            {foodItems
              // .filter((cat) => cat.tag !== selected)
              .map((foodItem : any, index: any) => (
                <option key={index} value={foodItem.id} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                  {foodItem.title}
                </option>
            ))}
          </select>
          </div>
          <div className="w-full group flex items-center gap-2  cursor-pointer justify-end">
            <input
              type="text"
              required
              placeholder={t('enterQuantity')}
              autoFocus
              className="bg-transparent outline-none w-full capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer placeholder:text-black-400"
              value={quantity}
              onChange={(e) => setQuantity(validateNumber(e.target.value))}
            />
          </div>
        </div>
        <div className="w-full flex flex-row justify-end gap-2 px-3 py-1">
          <motion.div
              whileTap={{scale: 1.1}}
              whileHover={{scale: 1.2}}
              className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-green-600 flex items-center justify-center cursor-pointer"
              title={t('confirm')}
              onClick={addItem}
          >
              <AiOutlineCheck className="text-white md:text-xl"/>
          </motion.div>
        </div>

      </motion.div>
    </>

  );
};

import React, { useState } from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";

const Accordion = (props: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className=" my-3 duration-300 bg-white rounded-lg">
      {/* Question */}
      <div
        className="flex justify-between items-center my-5 duration-300 flex px-8 cursor-pointer"
        onClick={handleVisibility}
      >
        <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex flex-col text-textColor">
          {props.question}
        </p>
        <button>
            {
                isVisible ? <FiMinusCircle  className="text-2xl text-textColor" /> : <FiPlusCircle  className="text-2xl text-textColor" />
            }
        </button>
      </div>
      {/* Answer */}
      <div
        className={`  duration-300    ${
          isVisible
            ? "visible mb-5 h-full opacity-100 flex flex-col px-8"
            : "invisible mb-0  h-0 opacity-0 "
        }`}
      >
        <p className="text-md lg:text-md text-textColor">
          {props.answer}
        </p>
      </div>
      {/* Line */}
      <hr className=" text-Grayishpurple opacity-10 border" />
    </div>
  );
};

export default Accordion;
import React from 'react'
import { EmptyCartImg } from '../Assets'
import {useTranslation} from "react-i18next";

const EmptyOrder = () => {
    const { t, i18n } = useTranslation();
  return (
    <div className='w-full p-5 flex flex-col items-center gap-4 justify-center'>
        <img className='h-[340px]' width={50} height={50} src={EmptyCartImg} alt='Order Master - Empty Order Image' title="Order Master - Empty Order Image" loading="lazy"/>
        <p className="text-textColor  font-semibold">{t('thereIsNoOrderYet')}</p>
    </div>
  )
}

export default EmptyOrder
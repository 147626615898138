import React from "react";
import {useStateValue} from "../../context/StateProvider";
import {motion} from "framer-motion";
import {addToCart, deleteFood} from "../../utils/functions";
import {MdAddShoppingCart, MdDeleteForever, MdOutlineRestaurantMenu} from "react-icons/md";
import {BiEditAlt} from "react-icons/bi";
import {FoodItem} from "../../types";
import UpdateFood from "../../components/Admin/UpdateFood"
import Modal from 'react-modal';
import {useTranslation} from "react-i18next";
import { Alert } from '../Admin/Alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

const Action = ({food, admin, isFromAdmin}: { food: FoodItem; admin?: boolean; isFromAdmin?: boolean }) => {
    const {t, i18n} = useTranslation();
    const [{cartItems, foodItems, socket, table, orders, org}, dispatch] = useStateValue();

    let subtitle: any;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function remove() {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <Alert onClose={onClose} message={t('confirmationDeleteFood')} onConfirm={()=>{deleteFood(food, foodItems, dispatch);}}/>
              );
            }
        });
    };

    return (
        <div className="flex flex-col gap-2">
            {(admin && isFromAdmin) ? (
                <>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        contentLabel="Update Food Modal"
                    >
                        <div style={{display: 'flex'}}>
                            <div className="flex justify-between w-full text-xl font-bold text-gray-600 pb-2 ">
                                {t('updateProduct')}
                            </div>
                            <div style={{marginLeft: "auto"}}>
                                <motion.div
                                    whileTap={{scale: 0.9}}
                                    initial={{opacity: 0, x: 200}}
                                    animate={{opacity: 1, x: 0}}
                                    exit={{opacity: 0, x: 200}}
                                    className="text-textColor"
                                    onClick={closeModal}
                                >
                                    <MdOutlineRestaurantMenu className="text-headingColor text-2xl"/>
                                </motion.div>
                            </div>
                        </div>
                        <UpdateFood id={food.id} closeModal={closeModal}/>
                    </Modal>
                    <motion.div
                        whileTap={{scale: 1.1}}
                        whileHover={{scale: 1.2}}
                        className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-green-600 flex items-center justify-center cursor-pointer"
                        title={t('edit')}
                        onClick={openModal}
                    >
                        <BiEditAlt className="text-white md:text-xl"/>
                    </motion.div>

                    <motion.div
                        whileTap={{scale: 1.1}}
                        whileHover={{scale: 1.2}}
                        className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer"
                        onClick={remove}
                        title={t('delete')}
                    >
                        <MdDeleteForever className="text-white md:text-xl"/>
                    </motion.div>
                </>
            ) : (org && org.settings.feature.orderMode) ? (
                <motion.div
                    whileTap={{scale: 1.1}}
                    whileHover={{scale: 1.2}}
                    className="w-12 h-12 md:w-10 md:h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer"
                    onClick={() => addToCart(cartItems, foodItems, food.id, dispatch, socket, table.title, orders)}
                    title={t('addToCart')}
                >
                    <MdAddShoppingCart className="text-white md:text-2xl text-3xl"/>
                </motion.div>
            ) : ''}
        </div>
    );
};

export default Action;

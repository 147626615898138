import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../context/StateProvider";
import {useTranslation} from "react-i18next";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";

const Kitchen = () => {
    const { t, i18n } = useTranslation();
    const [{ org }, dispatch] = useStateValue();
    const [url, setUrl] = useState("");
    useEffect(() => {
        if (org) {
            setUrl(window.location.hostname + '/kitchen?oid=' + org.id)
        }
    }, []);
    function copyToClipboard() {
        navigator.clipboard.writeText(url);
        toast.success(i18n.t('successCopy'));
    }
  return (
    <div className="w-full flex flex-col gap-2 px-4 py-1 items-center rounded-lg my-2">
        <p className="text-textColor font-semi-bold text-xl text-center items-center justify-center p-2">Aşağıdaki URL'yi kullanarak mutfak sayfasına istediğiniz cihaz ve ekrandan erişebilir ve onaylanmış siparişleri kontrol edebilirsiniz.</p>
        {
            org && <div className="flex items-center justify-center">
                <FaRegCopy onClick={copyToClipboard} className="text-xl text-textColor cursor-pointer mx-2"/> <a href={"/kitchen?oid=" + org.id} target="_blank" className="text-blue-600 underline text-xl text-center items-center justify-center p-2">{url}</a>
            </div>
        }
    </div>
  );
};

export default Kitchen;

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "missingRequiredFieldError": "Please fill required fields ⚠️",
            "dashboard": "Dashboard",
            "activeOrders": "Active Orders",
            "activeTables": "Active Tables",
            "addProduct": "Add Product",
            "menu": "Menu",
            "categories": "Categories",
            "addTable": "Add Table",
            "tables": "Tables",
            "settings": "Settings",
            "fieldValidationError": "Fill out fields correctly",
            "save": "Save",
            "removingPhoto": "Removing photo...",
            "uploadStatus": "Upload status",
            "savePending": "Saving...",
            "deletePending": "Deleting...",
            "updatePending": "Updating...",
            "saveSuccess": "Saved successfully ✅",
            "deleteSuccess": "Deleted successfully ✅",
            "updateSuccess": "Updated successfully ✅",
            "saveError": "Error while saving, please try again ⚠️",
            "companyName": "Company name",
            "address": "Address",
            "removePhoto": "Remove photo",
            "loginPending": "Logging in...",
            "loginSuccess": "Login successful, welcome!",
            "loginError": "Error during login, please try again ⚠️",
            "registerPending": "Registering user...",
            "registerSuccess": "Register successful, verify your e-mail address",
            "registerError": "Error during register, please try again ⚠️",
            "createOrderPending": "Creating order...",
            "createOrderSuccess": "Order created successfully",
            "createOrderError": "Error creating order, please try again ⚠️",
            "emailAddress": "Email address",
            "password": "Password",
            "forgotPassword": "Forgot password",
            "dontHaveAnAccount": "Don't have an account?",
            "alreadyHaveAnAccount": "Already have an account?",
            "register": "Register",
            "login": "Login",
            "searchTable": "Search table",
            "searchProduct": "Search product",
            "searchOrder": "Search order",
            "selectCategory": "Select category",
            "addCategory": "Add Category",
            "productCategoryMissing": "Please fill category field or add category before saving product ⚠️",
            "enterProductName": "Enter product name",
            "enterPrice": "Enter price (12,45)",
            "enterDescription": "Enter short description",
            "enterTableName": "Enter table name",
            "confirm": "Confirm",
            "close": "Close",
            "confirmationQuestion": "Are you sure?",
            "delete": "Delete",
            "enterCategoryName": "Enter category name",
            "selectIcon": "Select icon",
            "selectProduct": "Select product",
            "enterQuantity": "Enter quantity",
            "receipt": "Receipt",
            "print": "Print",
            "edit": "Edit",
            "quantityAbbreviation": "Q.",
            "description": "Description",
            "total": "Total",
            "thanksForYourPurchase": "Thanks for your purchase!",
            "home": "Home",
            "services": "Services",
            "aboutUs": "About us",
            "contactUs": "Contact us",
            "contactSaveSuccess": "Your request has been received, we will get back to you as soon as possible.",
            "logout": "Logout",
            "language": "Language",
            "languageChanging": "Changing language...",
            "languageTurkish": "🇹🇷 Turkish",
            "languageEnglish": "🇬🇧 English",
            "orderMode": "Order Mod",
            "orderModeMessage1": "You must upgrade to ",
            "orderModeMessage2": " to use the order mode.",
            "analyticMessage1": "You must upgrade to ",
            "analyticMessage2": " to use the analytic page.",
            "analytic": "Analytic",
            "itemAlreadyInCart": "Item already in cart",
            "addToCart": "Add to cart",
            "addToCartPending": "Adding to cart...",
            "addToCartSuccess": "Added to cart successfully ✅",
            "addToCartError": "Error while adding to cart, please try again ⚠️",
            "cartIsAlreadyEmpty": "Cart is already empty",
            "paymentSessionConfirmation": "Did you get your payment?",
            "closeSessionConfirmation": "Do you want to close this session?",
            "paymentReceived": "Payment received",
            "closeSession": "Close",
            "details": "Details",
            "administrator": "Administrator",
            "superadministrator": "Super Administrator",
            "profile": "Profile",
            "updateTable": "Update table",
            "updateEmail": "Update Email",
            "updateProduct": "Update product",
            "uploadImageText": "Click here to upload",
            "uploadImageHint": "PNG, JPG or GIF (MAX. 800x400px)",
            "tableTotal": "Table total",
            "cartTotal": "Cart total",
            "orderTotal": "Order total",
            "orderTime": "Order time",
            "thereIsNoOrderYet": "There is no order yet",
            "cartIsEmpty": "Cart is empty",
            "approveOrderConfirmation": "Do you want to approve this order?",
            "deleteOrderConfirmation": "Do you want to delete this order?",
            "note": "Note / Request",
            "addNote": "Add note",
            "order": "Order",
            "previousOrders": "Previous orders",
            "cart": "Cart",
            "clearCart": "Clear",
            "featureIsDisabled": "This feature is disabled by business",
            "forgotPasswordEmailPending": "Sending instructions to your e-mail address...",
            "forgotPasswordEmailSuccess": "Instructions are sent to your email address",
            "forgotPasswordEmailError": "Error while resetting password, please try again ⚠️",
            "resetPassword": "Reset password",
            "email": "Email",
            "resetMyPassword": "Reset My Password",
            "deleteAccount": "Delete Account",
            "resetPasswordConfirmMessage": "Do you want to reset your password? If you approve, an email will be sent to your email address and you will logout automatically. You can change your password with this email and login again.",
            "updateEmailConfirmMessage": "Do you want to update your email? If you approve, an email will be sent to your email address and you will logout automatically. You can verify your email with this email and login again.",
            "confirmationDeleteCategory": "Do you want to delete this category?",
            "confirmationDeleteFood": "Do you want to delete this menu item?",
            "confirmationCallWaiter": "Do you want to call waiter?",
            "waiterCallSuccess": "Waiter calling request sent",
            "waiterCallFail": "An error occured while sending waiter calling request",
            "notifications": "Notifications",
            "waiterCallMessage": " calls waiter.",
            "orderMessage": " created an order.",
            "tableMessage": " is activated",
            "emailNotVerifiedError": "Your email address has not been verified yet",
            "homepageTitle": "The Fastest QR Menu - Order Tracking and Management System",
            "homepageBody": "Create your menu easily and quickly, take orders, and manage them effortlessly. You can sign up for our app right away and enjoy a free 2-month trial without entering any payment information.",
            "homepageInfo1": "Add value to your business with QR Menu Integration",
            "homepageInfo2": "Say goodbye to traditional paper menus. With our QR Menu Integration, we're adding a modern touch. Customers can easily access your menu by scanning the QR code, allowing you to provide them with a seamless and interactive dining experience.",
            "homepageInfo3": "Dynamic Menu Control lets you easily update and manage menus effortlessly",
            "homepageInfo4": "Take control of your menu with Dynamic Menu Control. Easily update prices, enable or disable products, and adapt to rapidly changing trends in real-time. This feature provides flexibility, allowing you to align your offerings with market demands. Say goodbye to outdated systems and unnecessary paper expenses.",
            "homepageInfo5": "Easy Order Management helps you save time and costs",
            "homepageInfo6": "Experience a new level of efficiency. Track incoming orders in real-time, reduce errors, and increase overall service speed. Maintain control of the kitchen and ensure every order is delivered promptly and accurately.",
            "homepageInfo7": "Instant Notifications",
            "homepageInfo8": "Receive instant notifications when customers are seated, place orders, or request assistance. Enhance communication between staff, management, and customers to provide a seamless and efficient dining environment.",
            "homepageInfo9": "Easy Installation - Set up your own menu system within 10 minutes",
            "homepageInfo10": "No installation of any app or device needed. Signup and start using right away.",
            "homepageInfo11": "Live support is there to assist you in every step",
            "homepageInfo12": "Reach us through the 'Contact Us' button above whenever you're in need of export support tailed to your every need.",
            "homepageRegister": "Try free for 2 months",
            "goToSampleMenu": "Go to sample menu",
            "showcaseStaticItem1Title": "Strawberries",
            "showcaseStaticItem1Desc": "Fresh Strawberries",
            "showcaseStaticItem2Title": "Chicken",
            "showcaseStaticItem2Desc": "Mixed Kebab",
            "showcaseStaticItem3Title": "Tilapia",
            "showcaseStaticItem3Desc": "Roasted Tilapia",
            "showcaseStaticItem4Title": "Ice Cream",
            "showcaseStaticItem4Desc": "Chocolate & Vanilla",
            "help": "Help",
            "emailUpdateError": "An error occured while updating email.",
            "passwordError": "Wrong password.",
            "emailInUseError": "Email account is in use by other account.",
            "sameEmailError": "Email is same with existing email.",
            "nameSurname": "Name-Surname",
            "subject": "Subject",
            "message": "Message",
            "sendMessage": "Send",
            "businessEmail": "ordermasterapp@gmail.com",
            "businessPhone": "+90 538 897 7052",
            "whatsappPhone": "+905388977052",
            "whatsappMessage": "Hi, I want to get information about Order Master.",
            "contactUsDescription": "Contact us for requests, suggestions, complaints or information",
            "status": "Status (Active/Inactive)",
            "defaultIcon": "Default Icon",
            "foodItems": "Food Items",
            "orderNotes": "Order Notes",
            "addFoodItem": "Add Food Item",
            "faqs": "FAQs",
            "totalTurnoverChart": "Total Turnover Chart",
            "chartNumberOfItem": "# of order",
            "peakDatesChart": "Peak Dates",
            "mostOrderedItemsChart": "Most Ordered Items - Top 5",
            "chartDailyTotalTurnover": "Daily Turnover",
            "totalTurnover": "Total Turnover",
            "last30Days": "Last 30 days",
            "january": "January",
            "february": "February",
            "march": "March",
            "april": "April",
            "may": "May",
            "june": "June",
            "july": "July",
            "august": "August",
            "september": "September",
            "october": "October",
            "november": "November",
            "december": "December",
            "expireMessage": "Your subscription expires at {{date}}. Click here to see our subscription plans.",
            "kitchen": "Kitchen",
            "successCopy": "Copied to clipboard.",
            "orders": "Orders",
            "organizations": "Organizations",
            "menuItemHasLinkError": "Product is used in active orders!",
            "categoryHasLinkError": "There are products linked to this category",
            "saveSorting": "Save sorting.",
            "homeMetaDesc": "QR Menu and Order Tracking System Dynamic menu, easy order management, fast and easy installation. 2 months free trial. Make more valuable your businesses such as restaurants and cafes.",
            "homeMetaTitle": "Order Master - QR Menu and Order Tracking System",
            "loginMetaDesc": "QR Menu and Order Tracking System - Login page",
            "loginMetaTitle": "Order Master - Login",
            "registerMetaDesc": "QR Menu and Order Tracking System - Register Page",
            "registerMetaTitle": "Order Master - Register",
            "servicesMetaDesc": "QR Menu and Order Tracking System - Dynamic menu control for Restaurant and Cafe, easy order management, quick and easy setup. 2 months free trial.",
            "servicesMetaTitle": "Order Master - Services Page",
            "aboutMetaDesc": "Order Master - QR Menu and Order Tracking System - Dynamic menu control for Restaurant and Cafe, easy order management, quick and easy setup. 2 months free trial.",
            "aboutMetaTitle": "Order Master - About Us Page",
            "pricingMetaDesc": "Order Master - QR Menu and Order Tracking System - Pricing Page",
            "pricingMetaTitle": "Order Master - Pricing Page",
            "pricing": "Pricing",
            "pricingHead": "Choose the perfect plan for your needs. Flexible pricing options tailored for both small shops to large enterprises! You do not need to pay or save credit car for now. You have 2 month free PREMIUM usage. After that you can select and buy your subscription plan.",
            "starter": "Starter",
            "starterHead": "Suitable for businesses that only need a QR Menu.",
            "cartMessage": "The cart is common to all users at the table and is updated live",
            "starterPrice": "499",
            "month": "month",
            "starterList1": "Self installation",
            "starterList2": "No setup or extra hidden fees",
            "starterList3": "Add unlimited categories and products",
            "starterList4": "Add unlimited tables",
            "starterList5": "Call waiter from table feature",
            "starterList6": "7/24 live support",
            "standard": "Standard",
            "standardHead": "Suitable for businesses that need a QR Menu and need to manage their orders.",
            "standardPrice": "999",
            "standardList1": "Self installation",
            "standardList2": "No setup or extra hidden fees",
            "standardList3": "Add unlimited categories and products",
            "standardList4": "Add unlimited tables",
            "standardList5": "Call waiter from table feature",
            "standardList6": "Taking orders from the table feature",
            "standardList7": "Print an order receipt",
            "standardList8": "Print an payment receipt",
            "standardList9": "Unlimited kitchen screens",
            "standardList10": "7/24 live support",
            "premium": "Premium",
            "premiumHead": "Suitable for businesses that need a QR Menu, managing their orders and analytics page.",
            "premiumPrice": "1499",
            "premiumList1": "Self installation",
            "premiumList2": "No setup or extra hidden fees",
            "premiumList3": "Add unlimited categories and products",
            "premiumList4": "Add unlimited tables",
            "premiumList5": "Call waiter from table feature",
            "premiumList6": "Taking orders from the table feature",
            "premiumList7": "Print an order receipt",
            "premiumList8": "Print an payment receipt",
            "premiumList9": "Unlimited kitchen screens",
            "premiumList10": "Monthly turnover chart",
            "premiumList11": "Most ordered products chart",
            "premiumList12": "Peak times chart",
            "premiumList13": "7/24 live support",
            "getStarted": "Get Started"

        }
    },
    tr: {
        translation: {
            "missingRequiredFieldError": "Lütfen gerekli alanları doldurun ⚠️",
            "dashboard": "Anasayfa",
            "activeOrders": "Aktif Siparişler",
            "activeTables": "Aktif Masalar",
            "addProduct": "Ürün ekle",
            "menu": "Menü",
            "categories": "Kategoriler",
            "addTable": "Masa Ekle",
            "tables": "Masalar",
            "settings": "Ayarlar",
            "fieldValidationError": "Alanları kontrol edin",
            "save": "Kaydet",
            "removingPhoto": "Fotoğraf siliniyor...",
            "uploadStatus": "Yükleme durumu",
            "savePending": "Kaydediliyor...",
            "deletePending": "Siliniyor...",
            "updatePending": "Güncelleniyor...",
            "saveSuccess": "Başarıyla kaydedildi ✅",
            "deleteSuccess": "Başarıyla silindi ✅",
            "updateSuccess": "Başarıyla güncellendi ✅",
            "saveError": "Kaydetme sırasında hata, lütfen tekrar deneyin ⚠️",
            "companyName": "İşletme adı",
            "address": "Adres",
            "removePhoto": "Fotoğrafı sil",
            "loginPending": "Giriş yapılıyor...",
            "loginSuccess": "Giriş başarılı, hoşgeldiniz!",
            "loginError": "Giriş sırasında hata, lütfen tekrar deneyin ⚠️",
            "registerPending": "Kullanıcı kaydediliyor...",
            "registerSuccess": "Kullanıcı kaydı başarılı, e-posta adresinizi doğrulayın",
            "registerError": "Kullanıcı kaydı sırasında hata, lütfen tekrar deneyin ⚠️",
            "createOrderPending": "Sipariş kaydediliyor...",
            "createOrderSuccess": "Sipariş kaydı başarılı",
            "createOrderError": "Sipariş kaydedilirken hata oluştu, lütfen tekrar deneyin ⚠️",
            "emailAddress": "E-posta adresi",
            "password": "Şifre",
            "forgotPassword": "Şifremi unuttum",
            "dontHaveAnAccount": "Hesabınız yok mu?",
            "alreadyHaveAnAccount": "Zaten hesabınız var mı?",
            "register": "Kaydol",
            "login": "Giriş yap",
            "searchTable": "Masa ara",
            "searchProduct": "Ürün ara",
            "searchOrder": "Sipariş ara",
            "selectCategory": "Kategori ara",
            "addCategory": "Kategori ekle",
            "productCategoryMissing": "Kategori alanını doldurun veya ürünü kaydetmeden bir kategori ekleyin ⚠️",
            "enterProductName": "Ürün adı girin",
            "enterPrice": "Fiyat girin (12,45)",
            "enterDescription": "Açıklama girin",
            "enterTableName": "Masa adı girin",
            "confirm": "Onayla",
            "close": "Kapat",
            "confirmationQuestion": "Emin misiniz?",
            "delete": "Sil",
            "enterCategoryName": "Kategori adı girin",
            "selectIcon": "Simge seçin",
            "selectProduct": "Ürün seçin",
            "enterQuantity": "Adet girin",
            "receipt": "Fiş",
            "print": "Yazdır",
            "edit": "Düzenle",
            "quantityAbbreviation": "Ad.",
            "description": "Açıklama",
            "total": "Toplam",
            "thanksForYourPurchase": "Teşekkürler!",
            "home": "Anasayfa",
            "services": "Hizmetler",
            "aboutUs": "Hakkımızda",
            "contactUs": "Bize Ulaşın",
            "contactSaveSuccess": "Talebiniz alındı, en kısa sürede dönüş yapacağız.",
            "logout": "Çıkış yap",
            "language": "Dil",
            "languageChanging": "Dil değiştiriliyor...",
            "languageTurkish": "🇹🇷 Türkçe",
            "languageEnglish": "🇬🇧 İngilizce",
            "orderMode": "Sipariş Modu",
            "orderModeMessage1": "Sipariş modunu kullanmak için ",
            "orderModeMessage2": " a geçiş yapmalısınız.",
            "analyticMessage1": "Analiz sayfasını kullanmak için ",
            "analyticMessage2": " a geçiş yapmalısınız.",
            "analytic": "Analiz",
            "itemAlreadyInCart": "Ürün zaten sepetinizde mevcut",
            "addToCart": "Sepete ekle",
            "addToCartPending": "Sepete ekleniyor...",
            "addToCartSuccess": "Ürün sepete eklendi ✅",
            "addToCartError": "Sepete eklenemedi, lütfen tekrar deneyin ⚠️",
            "cartIsAlreadyEmpty": "Sepet zaten boş",
            "paymentSessionConfirmation": "Ödeme aldınız mı?",
            "closeSessionConfirmation": "Oturumu sonlandırmak istiyor musunuz?",
            "paymentReceived": "Ödeme alındı",
            "closeSession": "Sonlandır",
            "details": "Detay",
            "administrator": "Yönetim",
            "superadministrator": "Ana yönetim",
            "profile": "Profil",
            "updateTable": "Masa düzenle",
            "updateProduct": "Ürünü düzenle",
            "updateEmail": "Email Güncelle",
            "uploadImageText": "Yüklemek için tıklayın",
            "uploadImageHint": "PNG, JPG ve GIF (maks. 800x400px)",
            "tableTotal": "Masa toplamı",
            "cartTotal": "Sepet toplamı",
            "orderTotal": "Sipariş toplamı",
            "orderTime": "Sipariş zamanı",
            "thereIsNoOrderYet": "Henüz sipariş yok",
            "cartIsEmpty": "Sepet boş",
            "approveOrderConfirmation": "Siparişi onaylamak istiyor musunuz?",
            "deleteOrderConfirmation": "Siparişi silmek istiyor musunuz?",
            "note": "Not / İstek",
            "addNote": "Not ekle",
            "order": "Sipariş",
            "previousOrders": "Geçmiş siparişler",
            "cart": "Sepet",
            "clearCart": "Temizle",
            "featureIsDisabled": "Bu özellik işletme tarafından kapatıldı",
            "forgotPasswordEmailPending": "Gerekli bilgiler e-posta adresinize gönderiliyor...",
            "forgotPasswordEmailSuccess": "Gerekli bilgiler e-posta adresinize gönderildi",
            "forgotPasswordEmailError": "Sorun oluştu, lütfen tekrar deneyin ⚠️",
            "resetPassword": "Şifremi sıfırla",
            "email": "Email",
            "resetMyPassword": "Şifremi Resetle",
            "deleteAccount": "Hesabı Sil",
            "resetPasswordConfirmMessage": "Şifrenizi resetlemek istiyor musunuz? Eğer onaylarsanız mail adresinize bir email gönderilecek ve otomatik olarak çıkış yapacaksınız. Gönderilen email ile şifrenizi yeniden oluşturup tekrar giriş yapabilirsiniz.",
            "updateEmailConfirmMessage": "Email güncellemek istiyor musunuz? Eğer onaylarsanız mail adresinize bir email gönderilecek ve otomatik olarak çıkış yapacaksınız. Gönderilen email ile yeni emailinizi doğrulayıp tekrar giriş yapabilirsiniz.",
            "confirmationDeleteCategory": "Kategoriyi silmek istiyor musunuz?",
            "confirmationDeleteFood": "Ürünü silmek istiyor musunuz?",
            "confirmationCallWaiter": "Garson çağırmak istiyor musunuz?",
            "waiterCallSuccess": "Garson çağırma isteği gönderildi",
            "waiterCallFail": "Garson çağırırken bir hata oluştu",
            "notifications": "Bildirimler",
            "waiterCallMessage": " garson talep ediyor.",
            "orderMessage": " sipariş oluşturdu.",
            "tableMessage": " aktif edildi.",
            "emailNotVerifiedError": "E-posta adresinizi doğrulamadan giriş yapamazsınız",
            "homepageTitle": "Yeni Nesil QR Menü - Sipariş Takip ve Yönetim Sistemi",
            "homepageBody": "Basit ve hızlı bir şekilde menünüzü yaratın, sipariş alın ve kolayca yönetin. Uygulamamıza hemen kaydolup herhangi bir ödeme bilgisi girmeden ücretsiz 2 ay deneme hakkına sahip olabilirsiniz.",
            "homepageInfo1": "QR Menü Entegrasyonu ile işletmenize değer katın",
            "homepageInfo2": "Geleneksel kağıt menülerle vedalaşın. QR Menü Entegrasyonumuz ile modern bir dokunuş ekliyoruz. Müşteriler, QR kodunu tarayarak menünüze kolayca erişebilir, onlara sorunsuz ve etkileşimli bir yemek deneyimi sunabilirsiniz.",
            "homepageInfo3": "Dinamik Menü Kontrolü ile menüleri kolayca güncelleyip yönetin",
            "homepageInfo4": "Dinamik Menü Kontrolü ile menünüzü kontrol altına alın. Fiyatları kolayca güncelleyin, ürünleri görünür veya gizli hale getirin ve anlık olarak değişen trendlere adapte olun. Bu özellik esneklik sağlar ve tekliflerinizi piyasa talepleriyle uyumlu tutmanıza olanak tanır. Gereksiz efor ve kağıt masrafından kurtulun.",
            "homepageInfo5": "Kolay Sipariş Yönetimi sayesinde fazla efordan ve maliyetten kurtulun",
            "homepageInfo6": "Kolay Sipariş Yönetimi sistemimizle yeni bir verimlilik seviyesi yaşayın. Gelen siparişleri anlık olarak izleyin, hataları azaltın ve genel hizmet hızını artırın. Mutfak kontrolünü elinizde tutun ve her siparişin zamanında ve doğru bir şekilde teslim edilmesini sağlayın.",
            "homepageInfo7": "Anlık Bildirimler ile işletmenizin her noktasına hakim ve müşteri ile iletişim halinde olun",
            "homepageInfo8": "Anlık Bildirimler özelliğimizle restoran sahiplerinizi haberdar edin. Müşteriler oturduğunda, sipariş verdiklerinde veya yardım istediklerinde anında bildirim alın. Personel, yönetim ve müşteri arasındaki iletişimi artırarak sorunsuz ve hızlı bir yemek ortamı sağlayın.",
            "homepageInfo9": "Kolay Kurulum sayesinde kendi menü sisteminizi 10 dk içinde kendiniz kurun ve yönetin",
            "homepageInfo10": "Hiçbir uygulama veya ek cihaz kurulumu gerekmeden sisteme giriş yapın, kullanmaya başlayın.",
            "homepageInfo11": "Canlı Danışmanlık ve Destek sayesinde ürünü destek alarak kolayca kurup kullanın",
            "homepageInfo12": "Yukarıda bulunan bize ulaşın butonundan, bize tüm iletişim bilgileriyle ulaşıp, her konuda destek alabilirsiniz.",
            "homepageRegister": "2 Ay Ücretsiz Dene",
            "goToSampleMenu": "Örnek Menü",
            "showcaseStaticItem1Title": "Çilek",
            "showcaseStaticItem1Desc": "Taze Çilek",
            "showcaseStaticItem2Title": "Tavuk",
            "showcaseStaticItem2Desc": "Karışık Kebap",
            "showcaseStaticItem3Title": "Çipura",
            "showcaseStaticItem3Desc": "Çipura Izgara",
            "showcaseStaticItem4Title": "Dondurma",
            "showcaseStaticItem4Desc": "Çikolata & Vanilya",
            "help": "Yardım",
            "emailUpdateError": "Email güncellenirken hata oluştu.",
            "passwordError": "Şifre hatalı.",
            "emailInUseError": "Email adresi başka hesap tarafından kullanımda.",
            "sameEmailError": "Girdiğiniz email eskisi ile aynı.",
            "nameSurname": "İsim-Soyisim",
            "subject": "Konu",
            "message": "Mesaj",
            "sendMessage": "Gönder",
            "businessEmail": "ordermasterapp@gmail.com",
            "businessPhone": "+90 538 897 7052",
            "whatsappPhone": "+905388977052",
            "whatsappMessage": "Merhaba, Order Master hakkında bilgi almak istiyorum.",
            "contactUsDescription": "Talep, öneri, şikayet veya bilgi için bize ulaşın",
            "status": "Durum (Aktif/İnaktif)",
            "defaultIcon": "Varsayılan Simge",
            "foodItems": "Ürünler",
            "orderNotes": "Sipariş Notları",
            "addFoodItem": "Ürün Ekle",
            "faqs": "Sıkça Sorulan Sorular",
            "totalTurnoverChart": "Toplam Ciro Grafiği",
            "chartNumberOfItem": "Sipariş sayısı",
            "peakDatesChart": "Yoğun tarihler",
            "mostOrderedItemsChart": "Popüler Ürünler - İlk 5",
            "chartDailyTotalTurnover": "Günlük Ciro",
            "totalTurnover": "Toplam Ciro",
            "last30Days": "Son 30 gün",
            "january": "Ocak",
            "february": "Şubat",
            "march": "Mart",
            "april": "Nisan",
            "may": "Mayıs",
            "june": "Haziran",
            "july": "Temmuz",
            "august": "Ağustos",
            "september": "Eylül",
            "october": "Ekim",
            "november": "Kasım",
            "december": "Aralık",
            "expireMessage": "Aboneliğinizin süresi {{date}} tarihinde bitiyor. Buraya tıklayarak yeni abonelik planlarımızı görebilirisiniz.",
            "kitchen": "Mutfak",
            "successCopy": "Panoya kopyalandı.",
            "orders": "Siparişler",
            "organizations": "Organizasyonlar",
            "menuItemHasLinkError": "Ürün aktif siparişlerde kullanılıyor. Silmek için öncelikle siparişleri sonlandırmalısınız",
            "categoryHasLinkError": "Kategoriyi kullanan ürünler var. Silmek için öncelikle ilgili ürünlerin kategorisini değiştirin, veya silin",
            "saveSorting": "Sıralamayı Kaydet",
            "homeMetaDesc": "Yeni Nesil QR Menü ve Sipariş Takip Sistemi - Restoran ve Cafe için dinamik menü kontrolü, kolay sipariş yönetimi, hızlı ve kolay kurulum. 2 ay ücretsiz deneme hakkı.",
            "homeMetaTitle": "Order Master - QR Menü ve Sipariş Takip Sistemi",
            "loginMetaDesc": "QR Menü ve Sipariş Takip Sistemi - Giriş yapma Sayfası",
            "loginMetaTitle": "Order Master - Giriş Yap",
            "registerMetaDesc": "QR Menü ve Sipariş Takip Sistemi - Kaydolma Sayfası",
            "registerMetaTitle": "Order Master - Kayıt Ol",
            "servicesMetaDesc": "QR Menü ve Sipariş Takip Sistemi - Restoran ve Cafe için dinamik menü kontrolü, kolay sipariş yönetimi, hızlı ve kolay kurulum. 2 ay ücretsiz deneme hakkı.",
            "servicesMetaTitle": "Order Master - Hizmetlerimiz Sayfası",
            "aboutMetaDesc": "QR Menü ve Sipariş Takip Sistemi - Restoran ve Cafe için dinamik menü kontrolü, kolay sipariş yönetimi, hızlı ve kolay kurulum. 2 ay ücretsiz deneme hakkı.",
            "aboutMetaTitle": "Order Master - Hakkımızda Sayfası",
            "pricingMetaDesc": "QR Menü ve Sipariş Takip Sistemi - Fiyatlandırma Sayfası.",
            "pricingMetaTitle": "Order Master - Fiyatlandırma Sayfası",
            "pricing": "Fiyatlandırma",
            "pricingHead": "İhtiyaçlarınıza uygun mükemmel planı seçin. Hem küçük mağazalardan hem de büyük işletmelere özel esnek fiyatlandırma seçenekleri! Şimdilik kredi kartı ödemenize veya birikim yapmanıza gerek yok. 2 ay ücretsiz PREMIUM kullanımınız var. Bundan sonra abonelik planınızı seçip satın alabilirsiniz.",
            "starter": "Başlangıç",
            "starterHead": "Sadece Qr Menüye ihtiyacı olan işletmeler için uygun.",
            "cartMessage": "Sepet masadaki tüm kullanıcılar için ortaktır ve canlı güncellenir",
            "starterPrice": "499",
            "month": "ay",
            "starterList1": "Bireysel kolay kurulum",
            "starterList2": "Kurulum veya gizli herhangi bir ücret yok",
            "starterList3": "Sınırsız kategori ve ürün ekleme",
            "starterList4": "Sınırsız masa ekleme",
            "starterList5": "Masadan garson çağırma özelliği",
            "starterList6": "7/24 canlı destek",
            "standard": "Standart",
            "standardHead": "QR Menüye ve siparişlerini takip etmeye ihtiyacı olan işletmelere uygun.",
            "standardPrice": "999",
            "standardList1": "Bireysel kolay kurulum",
            "standardList2": "Kurulum veya gizli herhangi bir ücret yok",
            "standardList3": "Sınırsız kategori ve ürün ekleme",
            "standardList4": "Sınırsız masa ekleme",
            "standardList5": "Masadan garson çağırma özelliği",
            "standardList6": "Masadan sipariş alma özelliği",
            "standardList7": "Sipariş fişi yazdırma",
            "standardList8": "Hesap fişi yazdırma",
            "standardList9": "Sınırsız mutfak ekranı",
            "standardList10": "7/24 canlı destek",
            "premium": "Premium",
            "premiumHead": "QR Menüye, siparişlerini takip etmeye ve analiz verilerine ihtiyacı olan işletmelere uygun.",
            "premiumPrice": "1499",
            "premiumList1": "Bireysel kolay kurulum",
            "premiumList2": "Kurulum veya gizli herhangi bir ücret yok",
            "premiumList3": "Sınırsız kategori ve ürün ekleme",
            "premiumList4": "Sınırsız masa ekleme",
            "premiumList5": "Masadan garson çağırma özelliği",
            "premiumList6": "Masadan sipariş alma özelliği",
            "premiumList7": "Sipariş fişi yazdırma",
            "premiumList8": "Hesap fişi yazdırma",
            "premiumList9": "Sınırsız mutfak ekranı",
            "premiumList10": "Aylık ciro grafiği",
            "premiumList11": "Popüler ürünler grafiği",
            "premiumList12": "Yoğun tarihler grafiği",
            "premiumList13": "7/24 canlı destek",
            "getStarted": "Hemen Başla"

        }
    }
};

export const buildLocalizedPath = (subpath) => {
    return "/" + i18n.language + subpath;
}

export const buildLocalizedCanonicalPath = (subpath) => {
    return window.location.protocol + "//" + window.location.hostname + "/" + i18n.language + subpath;
}

const detectOptions = {
    // order and from where user language should be detected
    order: ['path'],
    lookupFromPathIndex: 0, // ordermaster.net/en OR ordermaster.net/tr
}

await i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        lng: "tr",
        detection: detectOptions,
        fallbackLng: 'tr',
        supportedLngs: ['en', 'tr'],
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })

await i18n.changeLanguage()

export default i18n;
import Dashboard from "./Dashboard";
import Home from "../Home";
import { useStateValue } from "../../context/StateProvider";
import { isSuperAdmin } from "../../utils/functions";
import { useNavigationType, NavigationType } from "react-router-dom";
import { useEffect } from "react";
import { ToggleAdminMode } from "../../utils/functions";

const SuperAdmin = () => {
  const [{user}, dispatch] = useStateValue();
  const navigationType = useNavigationType();
  useEffect(() => {
      if (navigationType === NavigationType.Pop) {
        ToggleAdminMode(dispatch, true);
      }
  },[navigationType]);
  return (
     <>
     {isSuperAdmin(user) ? <Dashboard /> : <Home />}
     </>
  );
};

export default SuperAdmin;

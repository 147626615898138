import React from "react"
import { useEffect, useState } from "react";
import { useStateValue } from "../../context/StateProvider";
import {useTranslation} from "react-i18next";
import { KitchenOrder } from "../../components/KitchenOrder";

const Kitchen = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const { t, i18n } = useTranslation()
  const [{ org, kitchenOrders }, dispatch] = useStateValue();
  const [isExpired, setIsExpired] = useState(false);
  function checkIsExpired() {
    const today = new Date().getTime();
    const expireDate = new Date(org.settings.subscriptionPlan.expiresAt).getTime();
    if (expireDate < today) {
      setIsExpired(true);
    }
  }

  useEffect(() => {
    if (queryParameters.get('oid')) {
      if (org) {
        checkIsExpired(); 
      }
    }    
  }, []);

  return (
    <div className='flex w-full h-auto flex-col items-center justify-center'>
        <p className="text-textColor font-bold text-3xl items-center justify-center uppercase mt-4 ">{t('kitchen')}</p>
        <div className="grid md:grid-cols-4 grid-cols-1 gap-4 w-full h-auto">
            {
                isExpired ? t('featureIsDisabled') : kitchenOrders && kitchenOrders.map((item: any)=>{
                    return <KitchenOrder key={item.orderId} order={item} />
                })
            }
        </div>
    </div>
  );
}

export default Kitchen;
export const actionTypes = {
    SET_USER: 'SET_USER',
    SET_FOOD_ITEMS: 'SET_FOOD_ITEMS',
    SET_CHART_DATA: 'SET_CHART_DATA',
    SET_FAQS: 'SET_FAQS',
    SET_TABLES: 'SET_TABLES',
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    TOGGLE_CART: 'TOGGLE_CART',
    SET_CARTITEMS: 'SET_CARTITEMS',
    SET_CARTNOTES: 'SET_CARTNOTES',
    SET_KITCHENORDERS: 'SET_KITCHENORDERS',
    SET_TABLE: 'SET_TABLE',
    SET_ORDERS: 'SET_ORDERS',
    SET_SESSIONS: 'SET_SESSIONS',
    SET_CART_TOTAL: 'SET_CART_TOTAL',
    SET_ADMIN_MODE: 'SET_ADMIN_MODE',
    SET_USERS: 'SET_USERS',
    UPDATE_USER: 'UPDATE_USER',
    SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
    UPDATE_CHECKOUT_DATA: 'UPDATE_CHECKOUT_DATA',
    TOGGLE_CONTACT_FORM: 'TOGGLE_CONTACT_FORM',
    SET_ORG: 'SET_ORG',
    SET_SOCKET: 'SET_SOCKET'
}

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case actionTypes.SET_FOOD_ITEMS:
            return {
                ...state,
                foodItems: action.foodItems,
            };
        case actionTypes.SET_CHART_DATA:
            return {
                ...state,
                chartData: action.chartData,
            };
        case actionTypes.SET_FAQS:
            return {
                ...state,
                faqs: action.faqs,
            };
        case actionTypes.SET_TABLES:
            return {
                ...state,
                tables: action.tables,
            };
        case actionTypes.SET_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };
        case actionTypes.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications,
            };
        case actionTypes.SET_SOCKET:
            return {
                ...state,
                socket: action.socket,
            };
        case actionTypes.TOGGLE_CART:
            return {
                ...state,
                showCart: action.showCart,
            };
        case actionTypes.SET_CARTITEMS:
            return {
                ...state,
                cartItems: action.cartItems,
            };
        case actionTypes.SET_CARTNOTES:
            return {
                ...state,
                cartNotes: action.cartNotes,
            };
        case actionTypes.SET_KITCHENORDERS:
            return {
                ...state,
                kitchenOrders: action.kitchenOrders,
            };
        case actionTypes.SET_TABLE:
            return {
                ...state,
                table: action.table,
            };
        case actionTypes.SET_ORDERS:
            return {
                ...state,
                orders: action.orders,
            };
        case actionTypes.SET_SESSIONS:
            return {
                ...state,
                sessions: action.sessions,
            };
        case actionTypes.SET_CART_TOTAL:
            return {
                ...state,
                cartTotal: action.cartTotal,
            };
        case actionTypes.SET_ADMIN_MODE:
            return {
                ...state,
                adminMode: action.adminMode,
            };
        case actionTypes.SET_USERS:
            return {
                ...state,
                users: action.users,
            };
        case actionTypes.UPDATE_USER:
            return {
                ...state,
                user: action.user
            };
        case actionTypes.SET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.paymentMethod
            };
        case actionTypes.UPDATE_CHECKOUT_DATA:
            return {
                ...state,
                checkoutData: action.checkoutData
            };
        case actionTypes.TOGGLE_CONTACT_FORM:
            return {
                ...state,
                showContactForm: action.showContactForm
            };
        case actionTypes.SET_ORG:
            return {
                ...state,
                org: action.org,
            };
        default:
            return state;
    }
}

export default reducer;
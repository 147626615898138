import React from "react";
import {useStateValue} from "../../context/StateProvider";
import {motion} from "framer-motion";
import {deleteTable, getPayment, closeSession} from "../../utils/functions";
import {MdDeleteForever, MdOutlineRestaurantMenu} from "react-icons/md";
import {AiOutlineClose, AiOutlineCheck} from "react-icons/ai";
import {CgDetailsMore} from "react-icons/cg";
import {Table} from "../../types";
import UpdateTable from "../../components/Admin/UpdateTable";
import Modal from 'react-modal';
import { Alert } from '../Admin/Alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Receipt } from "../Admin/Receipt";
import {useTranslation} from "react-i18next";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

const Action = ({admin, isFromAdmin, tableOpen, setTableId, tableId}: { admin?: boolean; isFromAdmin?: boolean; tableOpen: any; setTableId: any; tableId: string;}) => {
    let [{ sessions, socket }, dispatch] = useStateValue();
    const { t, i18n } = useTranslation();

    function payment() {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <Alert onClose={onClose} message={t('paymentSessionConfirmation')} onConfirm={()=>{getPayment(tableId, sessions, dispatch, socket);}}/>
              );
            }
        });
    };

    function close() {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <Alert onClose={onClose} message={t('closeSessionConfirmation')} onConfirm={()=>{closeSession(tableId, sessions, dispatch, socket);}}/>
              );
            }
        });
    };

    return (
        <div className="w-full flex flex-row items-center gap-2 justify-center">
            <motion.div
                whileTap={{scale: 1.1}}
                whileHover={{scale: 1.2}}
                className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-green-600 flex items-center justify-center cursor-pointer"
                title={t('paymentReceived')}
                onClick={payment}
            >
                <AiOutlineCheck className="text-white md:text-xl"/>
            </motion.div>

            <motion.div
                whileTap={{scale: 1.1}}
                whileHover={{scale: 1.2}}
                className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer"
                onClick={close}
                title={t('closeSession')}
            >
                <AiOutlineClose className="text-white md:text-xl"/>
            </motion.div>

            <motion.div
                whileTap={{scale: 1.1}}
                whileHover={{scale: 1.2}}
                className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-orange-600 flex items-center justify-center cursor-pointer"
                onClick={() => {setTableId(tableId); tableOpen(true); }}
                title={t('details')}
            >
                <CgDetailsMore className="text-white md:text-xl"/>
            </motion.div>
            <Receipt key={tableId} id={tableId} isOrder={false}></Receipt>
        </div>
    );
};

export default Action;

import {useEffect, useState} from "react";
import {MdOutlineDataSaverOn, MdOutlineFastfood} from "react-icons/md";

import {motion} from "framer-motion";
import {toast} from "react-toastify";
import {useStateValue} from "../../../context/StateProvider";
import {fetchTableData, updateTableData} from "../../../utils/functions";
import {useTranslation} from "react-i18next";

const UpdateTable = (props: any) => {
    const { t, i18n } = useTranslation();
    const [title, setTitle] = useState("");
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState("");
    const [loaderMessage, setLoadermessage] = useState("");
    const [{tables}, dispatch] = useStateValue();
    const [btnText, setBtnText] = useState(t('save'))

    const saveChanges = async () => {
        setBtnText(t('savePending'));
        if (!title) {
            toast.error(t('missingRequiredFieldError'))
            setBtnText(t('save'))
        } else {
            const data = {
                id: id,
                title: title,
                imageUrl: image
            };

            await updateTableData(data, dispatch, true)
            await fetchTableData(dispatch);
            setBtnText(t('save'));
            props.closeModal();
        }

    };

    const setFormItems = async (id: string) => {
        const table = tables.filter((item: any) => id === item.id)[0];
        setTitle(table.title);
        setId(table.id);
        setImage(table.imageUrl)
    }

    useEffect(() => {
        setFormItems(props.id);
    }, []);


    return (
        <>

            <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
                <MdOutlineFastfood className="text-xl text-gray-600"/>
                <input
                    type="text"
                    required
                    placeholder={t('enterProductName')}
                    autoFocus
                    className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>

            <div className="w-full flex items-center justify-center">
                <motion.button
                    whileHover={{scale: 1.1}}
                    className="ml-0 flex justify-center items-center gap-2 flex-row-reverse md:ml-auto w-full md:w-auto border-none outline-none rounded bg-orange-500 px-12 py-2 text-lg text-white"
                    onClick={() => saveChanges()}
                >
                    <MdOutlineDataSaverOn/> {btnText}
                </motion.button>
            </div>

        </>
    );
};

export default UpdateTable;

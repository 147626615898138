import {motion} from "framer-motion";
import {AiOutlineClose} from "react-icons/ai";
import {useStateValue} from "../../context/StateProvider";
import {deleteCartNote} from "../../utils/functions";
import React from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useTranslation} from "react-i18next"; // Import css

export const SingleNote = ({
                               id,
                               message
                           }: {
    id: any;
    message: string;
}) => {
    const {t, i18n} = useTranslation();
    const [{cartNotes, socket}, dispatch] = useStateValue();

    function remove() {
        deleteCartNote(cartNotes, id, dispatch, socket);
    };

    return (
        <div className="w-full py-1 flex items-center gap-2">
            <p className=" h-full w-full text-gray-400 text-base md:text-sm ">{message}</p>
            <motion.div
                whileTap={{scale: 1.1}}
                whileHover={{scale: 1.2}}
                className="w-7 h-7 md:w-5 md:h-5 rounded-lg bg-red-600 flex items-center justify-center cursor-pointer"
                title={t('close')}
                onClick={remove}
            >
                <AiOutlineClose className="text-white text-md md:text-md"/>
            </motion.div>
        </div>
    );
};

import {  BiArch } from "react-icons/bi";
import {BsFillHouseFill} from "react-icons/bs";
import {
  MdOutlineDataSaverOn,
  MdDeleteOutline,
  MdOutlineLanguage,
  MdWorkspacePremium
} from "react-icons/md";
import { Link} from "react-router-dom";
import { ToggleAdminMode } from "../../../utils/functions";
import { useState} from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useStateValue } from "../../../context/StateProvider";
import { AssetUploader, Loader } from "../../../components";
import { updateOrgData } from "../../../utils/functions";
import { firebaseRemoveUploadedImage } from "../../../Firebase";
import { useTranslation } from "react-i18next";
import './settings.css'

// ****************UPDATE ORG WILL BE DONE**********************
const Settings = () => {
  const { t, i18n } = useTranslation();
  const [{ org, user }, dispatch] = useStateValue();
  const [displayName, setDisplayName] = useState(org.displayName)
  const [photoUrl, setPhotoURL] = useState(org.photoUrl)
  const [loading, setLoading] = useState(false)
  const [address, setAddress] = useState(org.address)
  const [language, setLanguage] = useState(org.settings.language)
  const [orderMode, setOrderMode] = useState(org.settings.feature.orderMode)
  const [btnText, setBtnText] = useState(t('save'))
  const [loaderMessage, setLoadermessage] = useState("");

  const deleteImage = async () => {
    setLoadermessage(t('removingPhoto'));
    firebaseRemoveUploadedImage(photoUrl, setPhotoURL, setLoading);
    const data = { ...org, photoUrl: null };
    await updateOrgData(data, dispatch, false);
  };
  const saveChanges = async () => {
    if (!displayName || !address) {
      toast.error(t('missingRequiredFieldError'));
      return;
    }
    setBtnText(t('savePending'));
    if(displayName.length < 0 || address.length < 0 )
    {
      toast.error(t('fieldValidationError'))
      setBtnText(t('save'))
    }else{
      const data = {
        ...org,
        displayName,
        address,
        photoUrl,
        settings: {
          ...org.settings,
          language,
          feature:{
            ...org.settings.feature,
            orderMode
          }
        },
      }

      // update UI language if changed
      if(language !== org.settings.language) {
        toast.info(t('languageChanging'))
        await i18n.changeLanguage(language)
      }
      await updateOrgData(data, dispatch, true);
      setBtnText(t('save'));
    }

  };

  const updatePhotoUrl = async (newUrl: string) => {
    setPhotoURL(newUrl);
    const data = { ...org, photoUrl: newUrl };
     await updateOrgData(data, dispatch, false)
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div
          className="border w-full  md:w-[60%]  flex border-gray-300 items-center rounded-lg p-4 flex-col justify-center gap-4  mt-8 mg:mt-10">
        <div
            className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-[225px]  md:h-[420px] round-lg">
          {
            loading ? (
                <Loader progress={loaderMessage}/>
            ) : (
                <>
                  {photoUrl ? (
                      <>
                        <div className="relative h-full">
                          <img
                              src={photoUrl}
                              alt="uploaded food"
                              className="w-full h-full object-cover"
                              loading="lazy"
                              title="Order Master - Organization Logo"
                          />
                          <motion.button
                              whileTap={{scale: 1.1}}
                              whileHover={{scale: 1.2}}
                              title={t('removePhoto')}
                              className="absolute bottom-3 right-3 rounded-full p-2 md:p-5 bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out"
                              onClick={() => deleteImage()}
                          >
                            <MdDeleteOutline className="text-white"/>
                          </motion.button>
                        </div>
                      </>
                  ) : (
                      <AssetUploader
                          action={updatePhotoUrl}
                          progressHandler={setLoadermessage}
                          promise={setLoading}
                      />
                  )}
                </>
            )
          }
        </div>

        <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
          <BiArch className="text-xl text-gray-600"/>
          <input
              type="text"
              required
              placeholder={t('companyName')}
              autoFocus
              className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
          />
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-3">
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <BsFillHouseFill className="text-gray-600 text-2xl"/>
            <input
                type="text"
                required
                placeholder={t('address')}
                className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-3">
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <MdOutlineLanguage className="text-gray-600 text-2xl"/>
              <select
                  className="bg-transparent outline-none w-full capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer"
                  value={language}
                  onChange={e => setLanguage(e.target.value)}>
                <option value="tr">{t('languageTurkish')}</option>
                <option value="en">{t('languageEnglish')}</option>
              </select>
          </div>
        </div>

        <div className={"w-full flex flex-col md:flex-row items-center gap-3" + ((org && org.settings.subscriptionPlan.type === "STARTER") ? " disabled" : "")}>
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2 settings">
            <MdWorkspacePremium className="text-gray-600 text-2xl"/>
            {t('orderMode')}
            <label className="switch">
              <input
                  className="bg-transparent outline-none w-full capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer"
                  value={12}
                  type="checkbox"
                  checked={orderMode}
                  onChange={e => setOrderMode(e.target.checked)}>
              </input>
              <span className="slider"></span>
            </label>
          </div>
        </div>
        {
            (org && org.settings.subscriptionPlan.type === "STARTER") &&
            <p className="text-textColor font-semi-bold text-sm items-center justify-center ">{t("orderModeMessage1")}<Link onClick={() => ToggleAdminMode(dispatch, false)} to={"/payment"} className="text-lg text-blue-800">PREMIUM</Link>{t("orderModeMessage2")}</p>
          }

        <div className="w-full flex items-center justify-center">
          <Link onClick={() => ToggleAdminMode(dispatch, false)} to={"/payment"}>
            <div className="items-center flex justify-center mr-32 pr-8 pl-2 rounded-lg bg-white cursor-pointer">
              <MdWorkspacePremium className="text-gray-600 text-3xl w-24"/>
              <p className="px-2 py-2 text-textColor font-semi-bold text-md items-center justify-center text-center"> { org && t('expireMessage', {date: new Date(org.settings.subscriptionPlan.expiresAt).toISOString().split('T')[0]})}</p>
            </div>
          </Link>
          <motion.button
              whileHover={{scale: 1.1}}
              className="ml-0 flex justify-center items-center gap-2 flex-row-reverse md:ml-auto w-full md:w-auto border-none outline-none rounded bg-orange-500 px-12 py-2 text-lg text-white"
              onClick={() => saveChanges()}
          >
            <MdOutlineDataSaverOn/> {btnText}
          </motion.button>
        </div>
        <div className="w-full flex items-end justify-end">

        </div>
      </div>
    </div>
  );
};

export default Settings;

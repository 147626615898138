import { motion } from "framer-motion";
import {useTranslation} from "react-i18next";


export const OrderNotes = ({
  notes
}: {
  notes: any;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <motion.div
        className={`w-full flex flex-row justify-center gap-3 rounded-md bg-white`}
      >
        <p className="text-textColor font-semi-bold text-lg items-center justify-center">{t('orderNotes')}</p>
      </motion.div>
      <motion.div
        className={`w-full flex flex-row justify-center gap-3 rounded-md`}
      >
        <div className="flex-1 my-2 mx-6 rounded-xl overflow-y-scroll scrollbar-hidden  h-24">
          {
            notes.map((note: any) => (
              <p className="text-textColor font-semi-bold text-lg items-center justify-center">{note.message}</p>
            ))
          }
        </div>
      </motion.div>
    </>

  );
};

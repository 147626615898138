import React from 'react';
import {useEffect} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import i18n from '../../../i18n/i18n'
import {useTranslation} from "react-i18next";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options: () => any = () => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: i18n.t('totalTurnover'),
      },
      datalabels: {
        display: true,
        formatter: (value: any) => value + ' ₺',
        anchor: 'end',
        offset: -20,
        align: 'start',
      },
    },
  };
};

const BarChart = ({
    chartData
  }: {
    chartData: any;
  }) => {
  const { t } = useTranslation();

    const labels = chartData.labels;
    const data = {
    labels,
    datasets: [
        {
            label: t('chartDailyTotalTurnover'),
            data: chartData.data,
            backgroundColor: 'rgba(34, 139, 34, 0.5)',
        },
    ],
    };
    useEffect(() => {
        
    }, []);

    return (
        <>
            {
                <Bar options={options()} data={data} />
            }
        </>
    );
}

export default BarChart
import ServicesEn from "./services_en";
import ServicesTr from "./services_tr";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {buildLocalizedCanonicalPath, buildLocalizedPath} from "../../i18n/i18n";

const Services = () => {
    const {t, i18n} = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('servicesMetaTitle')}</title>
                <meta name="description" content={t('servicesMetaDesc')}/>
                <link rel="canonical" href={buildLocalizedCanonicalPath("/services")} hrefLang={i18n.language}/>
            </Helmet>
            <h1 className="hidden"> {t('servicesMetaTitle')}</h1>
            {i18n.language === 'tr' ? <ServicesTr/> : <ServicesEn/>}
        </>
    );
}

export default Services;
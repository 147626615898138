import { FaSearch } from "react-icons/fa";
import { Session } from "../../../types";
import OrderList from "../OrderList";
import { SessionTable } from "../../SessionTable";
import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../context/StateProvider";
import {useTranslation} from "react-i18next";
import Spinner from "../../Spinner";

const ActiveTables = () => {
    const { t, i18n } = useTranslation();
    const [{ sessions }, dispatch] = useStateValue();
    const [filteredSessions, setFilteredSessions] = useState<Session[]>(sessions);
    const [tableOpen, setTableOpen] = useState(false);
    const [tableId, setTableId] = useState("");

    const filterSession = (value: any) => {
        if(value.length === 0) {
          setFilteredSessions(sessions);
        }else{
          const filteredSessions = sessions.filter((session:Session) => session.tableTitle.toLowerCase().includes(value.toLowerCase()));
          setFilteredSessions(filteredSessions);
        }
    }
    const searchTable = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterSession(e.target.value);
    }
    useEffect(() => {
      if (sessions) {
        setFilteredSessions(sessions);
      }
    }, [sessions]);
  return (
    <>
    {tableOpen ? (
      <OrderList tableId={tableId} handler={setTableOpen} />
    ) : (
        <>
          <div className="w-full flex flex-col justify-center">
            {/* search bar */}
            <div className="w-full flex justify-center p-2 bg-white mb-4 rounded-lg">
              <input
                className="w-full p-2 outline-none rounded-lg "
                type="text"
                placeholder={t('searchTable')}
                onChange={(e) => searchTable(e)}
              />
              {/* search button */}
              <button className="flex items-center justify-center gap-3 text-orange-700 font-bold py-2 px-4 rounded-lg">
                <FaSearch />
              </button>
            </div>
            {
              (!filteredSessions || !sessions) ? <Spinner color={"#e80013"} loading={filteredSessions}/> :
                <div className="w-full flex items-center justify-center gap-3 overflow-x-hidden flex-wrap">
                {
                    filteredSessions.map((item: Session) => (
                        <SessionTable key={item.tableId} tableOpen={setTableOpen} setTableId={setTableId} tableId={item.tableId} col admin isFromAdmin = {true}/>
                    ))
                }
                </div>
      }
    </div>
    </>
      )}
    </>
  );
};

export default ActiveTables;

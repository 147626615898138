import {motion} from "framer-motion";
import {AiOutlineClose, AiOutlineCheck} from "react-icons/ai";
import {useTranslation} from "react-i18next";

export const Alert = ({
  message,
  onClose,
  onConfirm
}: {
  message: string;
  onClose: any;
  onConfirm: any;
}) => {
    const { t, i18n } = useTranslation();
  return (
    <div className="w-96 flex flex-col items-center gap-2 justify-center">
      <div className="w-full flex flex-col items-center gap-2 justify-center">
        <h1 className="text-center text-textColor font-bold">{t('confirmationQuestion')}</h1>
        <p className="text-center text-textColor">{message}</p>
      </div>
      <div className="w-full flex flex-row items-center gap-2 justify-center">
        <motion.div
            whileTap={{scale: 1.1}}
            whileHover={{scale: 1.2}}
            className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-green-600 flex items-center justify-center cursor-pointer"
            title={t('confirm')}
            onClick={()=>{
              onConfirm();
              onClose();
            }}
        >
            <AiOutlineCheck className="text-white md:text-xl"/>
        </motion.div>

        <motion.div
            whileTap={{scale: 1.1}}
            whileHover={{scale: 1.2}}
            className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer"
            onClick={onClose}
            title={t('close')}
        >
            <AiOutlineClose className="text-white md:text-xl"/>
        </motion.div>
      </div>
    </div>

  );
};

import {FoodCategory} from "../../../types";
import {CategoryItem} from "../../Admin/CategoryItem";
import {CategoryItemAdd} from "../../Admin/CategoryItemAdd";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {useStateValue} from "../../../context/StateProvider";
import Spinner from "../../Spinner";
import {useTranslation} from "react-i18next";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {MdOutlineDataSaverOn} from "react-icons/md";
import { toast } from "react-toastify";
import { updateAllCategories } from "../../../db";
import { fetchCategories } from "../../../utils/functions";

const Categories = () => {
    const {t, i18n} = useTranslation();
    const [{categories}, dispatch] = useStateValue();
    const [animationParent] = useAutoAnimate();
    const [categoryList, setCategoryList] = useState(categories);
    
    const moveUp = (category: any) => {
        const categoryIndex = categoryList.findIndex((f: any) => f.id === category.id);
        if (categoryIndex === 0) return;
        const newCategories = [...categoryList];
        newCategories[categoryIndex] = categoryList[categoryIndex - 1];
        newCategories[categoryIndex - 1] = category;
        setCategoryList(newCategories);
    };

    const moveDown = (category: any) => {
        const categoryIndex = categoryList.findIndex((f: any) => f.id === category.id);
        if (categoryIndex === categoryList.length - 1) return;
        const newCategories = [...categoryList];
        newCategories[categoryIndex] = categoryList[categoryIndex + 1];
        newCategories[categoryIndex + 1] = category;
        setCategoryList(newCategories);
    };

    const saveSort = () => {
        console.log('clck');
        
        toast
        .promise(updateAllCategories(categoryList), {
          pending: t('savePending'),
          success: t('saveSuccess'),
          error: t('saveError'),
        })
        .then(() => {
            fetchCategories(dispatch);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {  
        if(categories) {
            setCategoryList(categories);
        }        
    }, [categories]);

    return (
        <>
            <div className="w-full flex flex-col justify-center">
                <div className="w-full flex items-center justify-center gap-3 overflow-x-hidden flex-wrap">
                    {
                        !categoryList ? <Spinner color={"#e80013"} loading={categoryList}/> :
                            <>  
                                <ul ref={animationParent} className="flex-1 my-2 mx-6 rounded-xl overflow-y-scroll scrollbar-hidden  h-96">
                                    {
                                        categoryList.map((item: FoodCategory, index: any) => (
                                            <CategoryItem key={item.id} moveUp={moveUp} moveDown={moveDown} categoryItem={item} index={index} len={categoryList.length}/>
                                        ))
                                    }
                                </ul>
                                <div className="w-full flex justify-center p-2 bg-white mb-4 rounded-lg">
                                <div className="w-full flex items-center justify-center mr-2">
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        className="ml-0 flex justify-center items-center gap-2 flex-row-reverse md:ml-auto w-full md:w-auto border-none outline-none rounded bg-orange-500 px-8 py-2 text-lg text-white"
                                        onClick={saveSort}
                                    >
                                        <MdOutlineDataSaverOn /> {t('saveSorting')}
                                    </motion.button>
                                    </div>
                                </div>
                                <p className="text-xl font-bold text-gray-600">{t('addCategory')}</p>
                                <CategoryItemAdd/>
                            </>
                    }
                </div>
            </div>
        </>
    );
};

export default Categories;

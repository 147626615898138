import { motion } from "framer-motion";
import Button from "./Button";
import {  FoodCategory } from "../../types";
import { BiRestaurant } from "react-icons/bi";
import {useStateValue} from "../../context/StateProvider";
import { useEffect } from "react";

const Filters = ({filter, setFilter}: {filter:string, setFilter: any}) => {
  const [{categories}, dispatch] = useStateValue();
  useEffect(() => {  
    if(categories && categories.length) {
      setFilter(categories[0].tag);
    }        
}, [categories]);
  return (
    <motion.div
      initial={{ opacity: 0, x: 200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 200 }}
      className={`w-full py-5 flex items-center justify-start lg:justify-center  h-auto gap-4 md:gap-8  px-2  overflow-x-scroll scrollbar-hidden  scroll-smooth`}
    >
      {/* <Button category={{sortIndex: -1, id: '666', name: "Menu", tag: "all", icon: <BiRestaurant />}} filter = {filter} setFilter = {setFilter} /> */}
        {
            categories && categories.map((category: FoodCategory) =>{
                return <Button key = {category.id} category = {category} filter = {filter} setFilter = {setFilter} />
            })
        }

    </motion.div>
  );
};

export default Filters;

import React from 'react'
import { motion } from "framer-motion";
import Body from "./body";
import Header from "./Header";
import EmptyOrder from "./EmptyOrder";

import { useStateValue } from "../../context/StateProvider";

const Orders = ({handler}: {handler: any}) => {
  const [{ orders }] = useStateValue();
  return (
    <motion.div
      initial={{ opacity: 0, x: 200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 200 }}
      className="w-full h-screen md:w-[350px] bg-white md:backdrop-blur-sm flex flex-col z-[101] drop-shadow-xl fixed top-0 right-0"
    >
      <Header action={handler} />
      {orders && orders.length > 0 ? (
        <Body action={handler} />
      ) : (
        <div className="h-full w-full flex-1 flex items-center justify-center">
          <EmptyOrder />
        </div>
      )}
    </motion.div>
  );
};

export default Orders;

import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {buildLocalizedCanonicalPath} from "../../i18n/i18n";
import PricingBody from "./pricingBody";

const Pricing = () => {
    const {t, i18n} = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('pricingMetaTitle')}</title>
                <meta name="description" content={t('pricingMetaDesc')}/>
                <link rel="canonical" href={buildLocalizedCanonicalPath("/pricing")} hrefLang={i18n.language}/>
            </Helmet>
            <h1 className="hidden"> {t('pricingMetaTitle')}</h1>
            <PricingBody/>
        </>
    );
}

export default Pricing;
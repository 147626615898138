import { FaSearch } from "react-icons/fa";
import { Order, Session } from "../../../types";
import { SingleOrder } from "../../Order";
import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../context/StateProvider";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { motion } from "framer-motion";
import OrderDetail from "../OrderDetail";
import Spinner from "../../Spinner";
import {useTranslation} from "react-i18next";

const OrderList = ({
  tableId,
  handler
}: {
  tableId: string;
  handler: any;
}) => {
    const [{ sessions }, dispatch] = useStateValue();
    const [filteredSessions, setFilteredSessions] = useState<Session[]>([]);
    const [orderOpen, setOrderOpen] = useState(false);
    const [orderId, setOrderId] = useState("");
    const { t, i18n } = useTranslation();

    const filterOrder = (value : any) => {
        if(value.length === 0) {
          setFilteredSessions(sessions);
        }else{
          let filteredSessions = sessions.filter((session:Session) => session.tableTitle.toLowerCase().includes(value.toLowerCase()));
          setFilteredSessions(filteredSessions);
        }
    }
    const searchOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterOrder(e.target.value);
    }
    useEffect(() => {  
      if(sessions) {
        if (tableId === "") {
          setFilteredSessions(sessions);
        } else {
          let filteredSessions = sessions.filter((session:Session) => session.tableId === tableId);
          setFilteredSessions(filteredSessions);
        }
      }
    }, [sessions]);
  return (
    <>
    {orderOpen ? (
      <OrderDetail orderId={orderId} handler={setOrderOpen} />
      ) : (
      <div className="w-full flex flex-col justify-center">
        {(tableId === "") ? (
          <div className="w-full flex justify-center p-2 bg-white mb-4 rounded-lg">
            <input
              className="w-full p-2 outline-none rounded-lg "
              type="text"
              placeholder={t('searchTable')}
              onChange={(e) => searchOrder(e)}
            />
            <button className="flex items-center justify-center gap-3 text-orange-700 font-bold py-2 px-4 rounded-lg">
              <FaSearch />
            </button>
          </div>
        ) : (
          <div className="w-full flex items-center bg-white justify-between p-4 cursor-pointer">
          <motion.div whileTap={{ scale: 0.8 }} onClick={() => handler(false)}>
            <MdOutlineKeyboardBackspace className="text-textColor text-2xl " />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
          >
            <p className="text-xl font-bold text-gray-600">{t('orders')}</p>
          </motion.div>
          <motion.div
            whileTap={{scale:0.9}}
            className="flex items-center justify-center gap-1"
            title="Secured"
          >
          </motion.div>
        </div>
        )}
            <div className="w-full flex items-center justify-center gap-3 overflow-x-hidden flex-wrap">
              {
                (!filteredSessions || !sessions) ? <Spinner color={"#e80013"} loading={filteredSessions}/> :
                  filteredSessions.map((session: Session)=>{
                  session && session.orders.sort((a: any ,b: any) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))
                  return session && session.orders.map((item: Order) => (
                    tableId !== "" || item.status === 'PENDING' ?
                    <SingleOrder key={item.id} orderOpen={setOrderOpen} setOrderId={setOrderId} orderId={item.id} createdAt={item.createdAt}/>
                    : ''
                  ))
                })
              }
            </div>
      </div>
      )}
    </>
  );
};

export default OrderList;

import {useEffect, useState} from "react";
import { useStateValue } from "../../context/StateProvider";
import { useNavigationType, NavigationType } from "react-router-dom";
import { ToggleAdminMode } from "../../utils/functions";

const Payment = () => {
    const [{ org }, dispatch] = useStateValue();
    const [isExpired, setIsExpired] = useState(false);
    function checkIsExpired() {
      const today = new Date().getTime();
      const expireDate = new Date(org.settings.subscriptionPlan.expiresAt).getTime();
      if (expireDate < today) {
        setIsExpired(true);
      }
      // expired değilse gerekli fetch işemleri yapılcak
    }
    useEffect(()=>{
      checkIsExpired();
    })
    const navigationType = useNavigationType();
    useEffect(() => {
        if (navigationType === NavigationType.Pop) {
          ToggleAdminMode(dispatch, false);
        }
    },[navigationType]);
    return (
      <div className="w-full flex items-center justify-center pt-12 px-56">
        <p className="px-2 py-2 text-textColor font-semi-bold text-xl items-center justify-center text-center">
          Ürünümüz şu an için ücretsizdir. Bu sayfa daha sonra aktif olacaktır. Herhangi bir güncelleme yapmadan ürünümüzü kullanabilirsiniz. Karşılaşacağınız herhangi bir problemde bizimle iletişime geçebilirsiniz.
        </p>
        {/* {
          isExpired ?
          <><h1>Subscription Page</h1></> : <><h1>Upgrade Page</h1></>
        } */}
        
      </div>
    );
  }
  
  export default Payment;
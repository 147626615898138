import {Chicken, Fish, Icecream, Strawberry} from "../Assets";
import {Money} from "../../types";
import i18n from '../../i18n/i18n'

export const data = [

    {
        id: 2,
        title: i18n.t('showcaseStaticItem1Title'),
        desc: i18n.t('showcaseStaticItem1Desc'),
        price: new Money(7250),
        imgSrc: Strawberry
    },
    {
        id: 3,
        title: i18n.t('showcaseStaticItem2Title'),
        desc: i18n.t('showcaseStaticItem2Desc'),
        price: new Money(15000),
        imgSrc: Chicken
    },
    {
        id: 4,
        title: i18n.t('showcaseStaticItem3Title'),
        desc: i18n.t('showcaseStaticItem3Desc'),
        price: new Money(16000),
        imgSrc: Fish
    },{
        id: 1,
        title: i18n.t('showcaseStaticItem4Title'),
        desc: i18n.t('showcaseStaticItem4Desc'),
        price: new Money(5200),
        imgSrc: Icecream
    },

]
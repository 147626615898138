import {FaSearch} from "react-icons/fa";
import {FoodItem} from "../../../types";
import {SingleFoodItem} from "../../FoodItem";
import React, {useEffect, useState} from "react";
import {useStateValue} from "../../../context/StateProvider";
import {useTranslation} from "react-i18next";
import Spinner from "../../Spinner";

const Menu = () => {
    const {t, i18n} = useTranslation();
    const [{foodItems}, dispatch] = useStateValue();
    const [filteredFoodItems, setFilteredFoodItems] = useState<FoodItem[]>([]);

    const filterFood = (value: any) => {
        if (value.length === 0) {
            setFilteredFoodItems(foodItems);
        } else {
            const filteredFoodItems = foodItems.filter((foodItem: FoodItem) => foodItem.title.toLowerCase().includes(value.toLowerCase()));
            setFilteredFoodItems(filteredFoodItems);
        }
    }
    const searchFood = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterFood(e.target.value);
    }
    useEffect(() => {
        if (foodItems) {
            setFilteredFoodItems(foodItems);
        }
    }, [foodItems]);

    return (
        <div className="w-full flex flex-col justify-center">
            {/* search bar */}
            <div className="w-full flex justify-center p-2 bg-white mb-4 rounded-lg">
                <input
                    className="w-full p-2 outline-none rounded-lg "
                    type="text"
                    placeholder={t('searchProduct')}
                    onChange={(e) => searchFood(e)}
                />
                {/* search button */}
                <button
                    className="flex items-center justify-center gap-3 text-orange-700 font-bold py-2 px-4 rounded-lg">
                    <FaSearch/>
                </button>
            </div>
            {
                (!filteredFoodItems || !foodItems) ? <Spinner color={"#e80013"} loading={filteredFoodItems}/> :
                    <div className="w-full flex justify-center gap-3 overflow-x-hidden flex-wrap ">
                        {
                            filteredFoodItems.map((item: FoodItem) => (
                                <SingleFoodItem key={item.id} foodId={item.id} col admin isFromAdmin={true}/>
                            ))
                        }
                    </div>
            }
        </div>
    );
};

export default Menu;

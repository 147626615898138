import { BsQrCode } from "react-icons/bs";
import { FaPeopleLine } from "react-icons/fa6";
import { IoNotificationsSharp } from "react-icons/io5";
import { MdOutlineRestaurantMenu, MdSecurity, MdSupportAgent } from "react-icons/md";
import { BsPersonArmsUp } from "react-icons/bs";

const ServicesTr = () => {
  return (
    <div className='flex items-center justify-center'>
      <div className="flex flex-col py-12 w-full px-16">
        <p className="text-3xl lg:text-3xl font-bold text-center tracking-wide flex flex-col text-headingColor">
          Hizmetlerimiz
        </p>
        <br />
        <p className="text-md lg:text-md text-center flex flex-col text-textColor">
          Order Master olarak, restoran veya kafe yönetimizi yeni bir seviyeye taşıyacak kapsamlı bir hizmet yelpazesi sunmaktan gurur duyuyoruz. Yenilikçi çözümlerimiz, konukseverlik sektörünün benzersiz ihtiyaçlarını karşılamak üzere tasarlanmış olup, işlemlerinizi hızlandırmak ve müşteri deneyimlerini geliştirmek için size araçlar sunuyor.
        </p>
        <br />
        <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-center gap-3 overflow-x-hidden flex-wrap'>
          <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
            <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
              <BsQrCode className='text-3xl mx-2' /> QR Menü Entegrasyonu
            </p>
            <br />
            <p className="text-md lg:text-md flex flex-col text-textColor">
              Geleneksel kağıt menülerle vedalaşın. QR Menü Entegrasyonumuz ile modern bir dokunuş ekliyoruz. Müşteriler, bir QR kodunu tarayarak menünüze kolayca erişebilir, onlara sorunsuz ve etkileşimli bir yemek deneyimi sunabilirsiniz.
            </p>
            <br />
          </div>
          <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
            <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
              <FaPeopleLine className='text-3xl mx-2' /> Kolay Sipariş Yönetimi
            </p>
            <br />
            <p className="text-md lg:text-md flex flex-col text-textColor">
              Kolay Sipariş Yönetimi sistemimizle yeni bir verimlilik seviyesi yaşayın. Gelen siparişleri anlık olarak izleyin, hataları azaltın ve genel hizmet hızını artırın. Mutfak kontrolünü elinizde tutun ve her siparişin zamanında ve doğru bir şekilde teslim edilmesini sağlayın.
            </p>
            <br />
          </div>
          <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
            <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
              <IoNotificationsSharp className='text-3xl mx-2' /> Anlık Bildirimler
            </p>
            <br />
            <p className="text-md lg:text-md flex flex-col text-textColor">
              Anlık Bildirimler özelliğimizle restoran sahiplerinizi haberdar edin. Müşteriler oturduğunda, sipariş verdiklerinde veya yardım istediklerinde anında bildirim alın. Personel ile yönetim arasındaki iletişimi artırarak sorunsuz ve hızlı bir yemek ortamı sağlayın.
            </p>
            <br />
          </div>
          <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
            <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
              <MdOutlineRestaurantMenu className='text-3xl mx-2' /> Dinamik Menü Kontrolü
            </p>
            <br />
            <p className="text-md lg:text-md flex flex-col text-textColor">
              Dinamik Menü Kontrolü ile menünüzü kontrol altına alın. Fiyatları kolayca güncelleyin, ürünleri etkin veya etkisiz hale getirin ve anlık olarak değişen trendlere adapte olun. Bu özellik esneklik sağlar ve tekliflerinizi piyasa talepleriyle uyumlu tutmanıza olanak tanır.
            </p>
            <br />
          </div>
          <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
            <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
              <BsPersonArmsUp className='text-3xl mx-2' /> Müşteri Kendi Kurulumu
            </p>
            <br />
            <p className="text-md lg:text-md flex flex-col text-textColor">
              Müşterilerinizi Müşteri Kendi Kurulum özelliğimizle güçlendirin. Geleneksel kurulum sıkıntılarına veda edin. Müşterilerinize uygulamayı kolayca kurmalarına olanak tanıyarak modern ve kullanışlı bir yemek deneyimi yaşamalarını sağlayın.
            </p>
            <br />
          </div>
          <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
            <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
              <MdSecurity className='text-3xl mx-2' /> Güvenilir Sipariş Takibi
            </p>
            <br />
            <p className="text-md lg:text-md flex flex-col text-textColor">
              Güvenilir Sipariş Takip sistemimiz, hiçbir siparişin gözden kaçmasına izin vermez. Her siparişi yerleştirmeden teslimata kadar izleyerek hataları en aza indirin ve genel müşteri memnuniyetini artırın. Kaybolan siparişlere veda edin ve hassaslık yeni bir çağa hoş geldiniz deyin.
            </p>
            <br />
          </div>
          <div></div>
          <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
            <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
              <MdSupportAgent className='text-3xl mx-2' /> Danışmanlık ve Destek
            </p>
            <br />
            <p className="text-md lg:text-md flex flex-col text-textColor">
              Sadece hizmet sunmuyoruz; ortaklıklar kuruyoruz. Danışmanlık ve Destek hizmetlerimiz, uygulama sürecinizin ötesinde sizi yönlendirmek için burada. Uzman tavsiyeler, eğitim ve sürekli destek için bize güvenebilirsiniz. Order Master ile başarınızı garanti altına alın.
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesTr;

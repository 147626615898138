import "react-toastify/dist/ReactToastify.css";
import {
    About,
    Admin,
    Home,
    Kitchen,
    Login,
    Menu,
    Payment,
    Pricing,
    Profile,
    Services,
    Signup,
    SuperAdmin
} from "./Pages";
import {Cart, Footer, Header, Spinner} from "./components";
import {Route, Routes} from "react-router-dom";
import {
    calculateCartTotal,
    dispatchUsers,
    fetchAllDataForKitchen,
    fetchAllTableData,
    fetchCategories,
    fetchFoodData,
    fetchNotifications,
    fetchSessionData,
    fetchTableData,
    isAdmin,
    openSocket,
    openSocketKitchen,
    openSocketTable,
    ToggleAdminMode
} from "./utils/functions";

import {AnimatePresence} from "framer-motion";
import Contact from "./components/Contact";
import {ToastContainer} from "react-toastify";
import {useEffect, useState} from "react";
import {useStateValue} from "./context/StateProvider";
import {useTranslation} from "react-i18next";
import {dispatchOrgData} from "./db";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import {buildLocalizedPath} from "./i18n/i18n";

function App() {
    const [{showCart, showContactForm, user, org, foodItems, cartItems, adminMode}, dispatch] = useStateValue();
    const queryParameters = new URLSearchParams(window.location.search);
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // closed for non auth user
        if (queryParameters.get('oid')) {
            if (queryParameters.get('id')) {
                fetchAllTableData(dispatch, setLoading);
                openSocketTable(dispatch, (queryParameters.get('oid') || ''));
            } else {
                fetchAllDataForKitchen(dispatch, setLoading);
                openSocketKitchen(dispatch, (queryParameters.get('oid') || ''));
                ToggleAdminMode(dispatch, false);
            }
        } else {
            if (window.location.pathname === '/payment') {
                user && dispatchOrgData(dispatch);
                setTimeout(function () {
                    setLoading(false);
                }, 1000);
            } else {
                user && fetchCategories(dispatch)
                user && fetchFoodData(dispatch);
                user && fetchTableData(dispatch);
                user && dispatchUsers(dispatch);
                user && fetchSessionData(dispatch);
                user && openSocket(dispatch);
                user && fetchNotifications(dispatch);
                setTimeout(function () {
                    setLoading(false);
                }, 1000);
            }
            if (!user && window.location.pathname === '/') {
                const browserLanguage = navigator.language.slice(0, 2) // slice 'en' from en-US
                window.location.pathname = '/' + browserLanguage + '/';
                i18n.changeLanguage(browserLanguage)
            }

            if ((window.location.pathname === '/admin') || (window.location.pathname === '/superadmin')) {
                user && ToggleAdminMode(dispatch, true)
            } else {
                user && ToggleAdminMode(dispatch, false)
            }
        }
        if (org && org.settings && org.settings.language) {
            i18n.changeLanguage(org.settings.language)
        }
    }, []);

    useEffect(() => {
        foodItems &&
        cartItems.length > 0 &&
        calculateCartTotal(cartItems, foodItems, dispatch);
    }, [cartItems, foodItems, dispatch]);
    return (
        <>
            {
                loading ? <Spinner color={"#e80013"} loading={loading}/> :
                    <AnimatePresence exitBeforeEnter>
                        <ToastContainer pauseOnFocusLoss={false} autoClose={1500}/>
                        <div className="w-screen h-auto min-h-[100vh] flex flex-col bg-primary">
                            {showCart && <Cart/>}
                            {showContactForm && <Contact/>}
                            {!(adminMode && isAdmin(user)) && <Header/>}
                            <main
                                className={`${
                                    !(adminMode && isAdmin(user)) &&
                                    "mt-16 md:mt-16 md:py-6 py-4"
                                } w-full h-auto`}
                                onClick={() => {
                                }}
                            >
                                {/* Routes */}
                                <Routes>
                                    <Route path="/*" element={<Home/>}/>
                                    <Route path="/admin" element={<Admin/>}/>
                                    <Route path="/menu" element={<Menu/>}/>
                                    <Route path="/payment" element={<Payment/>}/>
                                    <Route path="/profile" element={<Profile/>}/>
                                    <Route path="/kitchen" element={<Kitchen/>}/>
                                    <Route path="/superadmin" element={<SuperAdmin/>}/>
                                    {/* Unauthorized, landing page routes */}
                                    <Route path={buildLocalizedPath("/login")} element={<Login/>}/>
                                    <Route path={buildLocalizedPath("/register")} element={<Signup/>}/>
                                    <Route path={buildLocalizedPath("/forgot-password")} element={<ForgotPassword/>}/>
                                    <Route path={buildLocalizedPath("/services")} element={<Services/>}/>
                                    <Route path={buildLocalizedPath("/about")} element={<About/>}/>
                                    <Route path={buildLocalizedPath("/pricing")} element={<Pricing/>}/>
                                </Routes>

                                {!(adminMode && isAdmin(user)) && <Footer/>}
                            </main>
                        </div>
                    </AnimatePresence>
            }
        </>
    );
}

export default App;

import {getAuth} from "firebase/auth";
import {sendMessage} from "../utils/functions";
import i18n from '../i18n/i18n'
import {ErrorCode} from "../domain/ErrorCode";

export const getUser = async (uid) => {
    const currentUser = await getAuth().currentUser;
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'users/' + uid, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getOrg = async (id) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'organizations/' + id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const dispatchOrgData = async (dispatch) => {
    const currentUser = await getCurrentUser();
    let userData = await getUser(currentUser.uid);
    let orgData = await getOrg(userData.organizations[0]);
    dispatch({
        type: "SET_ORG",
        org: orgData,
    });
    localStorage.setItem("org", JSON.stringify(orgData));
}

export const getOrgTable = async (id) => {
    const token = 'fromtable';
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const updateOrg = async (org) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'organizations/' + org.id, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            id: org.id,
            displayName: org.displayName,
            address: org.address,
            photoUrl: org.photoUrl,
            settings: org.settings,
        })
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const updateMenuItem = async (food) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'menu-items/' + food.id, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(food)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getMenuItems = async (tableId, organizationId) => {
    let result;
    let token = 'fromtable';
    let reqUrl = '';
    if (!tableId) {
        const currentUser = await getCurrentUser();
        token = await currentUser.getIdToken();
        reqUrl = process.env.REACT_APP_API_BASE_URL + 'menu-items/';
    } else {
        reqUrl = process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/menu-items/';
    }
    // table id will be send here
    await fetch(reqUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            if (response.status === 403) {
                window.location.href = "/payment"
            }
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getChartData = async (fromDate, toDate) => {
    let totalTurnover;
    let mostOrderedItems;
    let mostPeakDates;

    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    const urlParams = new URLSearchParams({
        fromDate: fromDate,
        toDate: toDate
    }).toString();
    const reqUrlTotalTurnover = process.env.REACT_APP_API_BASE_URL + 'analytics/total-turnover?' + urlParams;
    const reqUrlMostOrderedItems = process.env.REACT_APP_API_BASE_URL + 'analytics/most-ordered-items?' + urlParams;
    const reqUrlMostPeakDates = process.env.REACT_APP_API_BASE_URL + 'analytics/peak-dates?' + urlParams;
    await fetch(reqUrlTotalTurnover, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        totalTurnover = await response.json();
    })
    await fetch(reqUrlMostOrderedItems, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        mostOrderedItems = await response.json();
    })
    await fetch(reqUrlMostPeakDates, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        mostPeakDates = await response.json();
    })
    return {
        totalTurnover: totalTurnover.data,
        mostOrderedItems: mostOrderedItems.data,
        mostPeakDates: mostPeakDates.data
    };
};

export const addMenuItem = async (data) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'menu-items/', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

export const getTables = async () => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'tables/', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getAllCategories = async (organizationId) => {
    let result;
    let token = 'fromtable';
    let reqUrl = '';
    if (!organizationId) {
        const currentUser = await getCurrentUser();
        token = await currentUser.getIdToken();
        reqUrl = process.env.REACT_APP_API_BASE_URL + 'categories/';
    } else {
        reqUrl = process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/menu-items/categories';
    }
    await fetch(reqUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const updateAllCategories = async (categories) => {
    let result = [];
    try {
        for(let i = 0; i < categories.length; i++) {
            categories[i].sortIndex = i;
            categories[i].icon = categories[i].icon.type.name;
            await updateCategory(categories[i])
        }
        return result;
    } catch(e) {
        throw new Error('Server error!');
    }
    
};

export const deleteNotificationDb = async (id) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'notifications/' + id, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
}

export const updateNotification = async (notification) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'notifications/' + notification.id, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(notification)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
}

export const getAllNotifications = async () => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'notifications', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const addTable = async (data) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'tables/', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

export const updateTable = async (table) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'tables/' + table.id, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(table)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const deleteMenuItem = async (id) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();

    return await fetch(process.env.REACT_APP_API_BASE_URL + 'menu-items/' + id, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            const responseBody = await response.json();
            if (responseBody.error.errorCode === ErrorCode.MenuItemHasLinkError) {
                throw new Error(ErrorCode.MenuItemHasLinkError);
            }
            throw new Error('Server error!');
        }
    })
};

export const deleteTableDb = async (id) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();

    return await fetch(process.env.REACT_APP_API_BASE_URL + 'tables/' + id, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

async function getCurrentUser() {
    return await new Promise((resolve, reject) => {
        getAuth().onIdTokenChanged(async (user) => {
            if (!user) {
                resolve(undefined);
                localStorage.setItem("user", "undefined");
                localStorage.setItem("adminMode", "undefined");
                window.location.href = "/"
            } else {
                resolve(user);
            }
        })
    }).then((user) => user).catch(function (e) {
        throw (e);
    });
}

export const addCartItem = async (data, tableId, organizationId) => {
    const token = 'fromtable';
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/cart/', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

export const createNotification = async (title, tableId, organizationId, type) => {
    let message = '';
    switch (type) {
        case 'ORDER':
            message = title + i18n.t('orderMessage');
            break;
        case 'TABLE':
            message = title + i18n.t('tableMessage');
            break;
        case 'WAITER':
            message = title + i18n.t('waiterCallMessage');
            break;
        default:
            message = title;
            break;
    }
    const token = 'fromtable';
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/notifications', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            message: message,
            type: type
        })
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

export const getCartItems = async (tableId, organizationId) => {
    const token = 'fromtable';
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/cart/', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getCartNotes = async (tableId, organizationId) => {
    const token = 'fromtable';
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/notes/', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getTableDataForTable = async (tableId, organizationId) => {
    const token = 'fromtable';
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getSessions = async () => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'sessions', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getOrders = async (tableId, organizationId) => {
    const token = 'fromtable';
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/orders', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const getAllKitchenOrders = async (organizationId) => {
    const token = 'fromkitchen';
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/dashboard/orders', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const updateCartItem = async (data, tableId, organizationId) => {
    const token = 'fromtable';
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/cart/' + data.id, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

export const updateEmailDb = async () => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'users/' + currentUser.uid + '/email-address/', {
        method: 'PATCH',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            email: currentUser.email
        })
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
}

export const deleteCartItemDb = async (data, tableId, organizationId) => {
    const token = 'fromtable';
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/cart/' + data.id, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

export const deleteCartNoteDb = async (data, tableId, organizationId) => {
    const token = 'fromtable';
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/notes/' + data, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

export const addCartNoteDb = async (message, tableId, organizationId) => {
    const token = 'fromtable';
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/notes/', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            'message': message
        })
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};

export const createOrder = async (tableId, organizationId, socket) => {
    const token = 'fromtable';
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'public/organizations/' + organizationId + '/tables/' + tableId + '/cart/order', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        sendMessage(socket, 'orders_updated');
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
}

export const getPaymentDb = async (tableId) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'sessions/close', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            tableId: tableId,
            status: 'PAID'
        })
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const closeSessionDb = async (tableId) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'sessions/close', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            tableId: tableId,
            status: 'CANCELLED'
        })
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const deleteOrderDb = async (tableId, orderId) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'tables/' + tableId + '/orders/' + orderId, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const updateOrder = async (tableId, order) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'tables/' + tableId + '/orders/' + order.id, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(order)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const addCategory = async (name, icon) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'categories', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            name,
            icon
        })
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const deleteCategory = async (id) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'categories/' + id, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        }
    }).then(async (response) => {
        if (!response.ok) {
            const responseBody = await response.json();
            if (responseBody.error.errorCode === ErrorCode.CategoryHasLinkError) {
                throw new Error(ErrorCode.CategoryHasLinkError);
            }
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const updateCategory = async (data) => {
    const currentUser = await getCurrentUser();
    const token = await currentUser.getIdToken();
    let result;
    await fetch(process.env.REACT_APP_API_BASE_URL + 'categories/' + data.id, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
        result = await response.json();
    })
    return result.data;
};

export const fetchFaqsDb = async () => {
    // const currentUser = await getCurrentUser();
    // const token = await currentUser.getIdToken();
    // let result;
    // await fetch(process.env.REACT_APP_API_BASE_URL + 'faqs/', {
    //   method: 'GET',
    //   headers: {
    //     'Content-type': 'application/json; charset=UTF-8',
    //     'Authorization': 'Bearer ' + token
    //   }
    // }).then(async (response) => {
    //   if (!response.ok) {
    //     throw new Error('Server error!');
    //   }
    //   result = await response.json();
    // })
    // return result.data;

    return [
        {
            id: 1,
            question: 'Order Master nedir?',
            answer: `Order Master, restoran ve kafelerin siparişlerini ve menülerini kolayca yönetmelerini sağlayan bir web tabanlı uygulamadır. Bu uygulama, işletmelere QR kod tabanlı menüler sunarak ve bir sipariş takip ve yönetim sistemi sağlayarak hizmet verir. Order Master, restoranların daha az garson ile çalışmalarına olanak tanır, sipariş hatalarını önler, menüdeki ürünlerin fiyatlarını kolayca güncellemelerine ve ürünleri aktif veya pasif hale getirmelerine yardımcı olur. Order Master'ın kullanımı basit ve sezgiseldir. Restoran sahipleri, uygulama üzerinden siparişleri anında görüntüleyebilir ve müşteriler masaya oturduklarında, sipariş verdiklerinde veya garson çağırmak istediklerinde anında bildirim alırlar. Müşteriler, eski tarz sipariş uygulamalarından kurtulur ve uygulamayı kendileri kolayca kurabilirler. Order Master, restoran ve kafe işletmecilerine işlerini büyütme, verimlilik sağlama ve başarıya ulaşma fırsatları sunan bir çözüm olarak hizmet vermektedir.`
        },
        {
            id: 2,
            question: 'QR Menü nedir ve nasıl çalışır?',
            answer: `QR Menü, müşterilerin akıllı telefonlarını kullanarak restoranın menüsüne erişmelerini sağlayan dijital bir çözümdür. Bir QR kodunu tarayarak konuklar menüyü görüntüleyebilir ve doğrudan telefonlarından sipariş verebilirler.`
        },
        {
            id: 3,
            question: 'Sipariş takip ve yönetim sistemi restoranlara nasıl fayda sağlar?',
            answer: `Sipariş takip ve yönetim sistemimiz, restoranların siparişlerini verimli bir şekilde yönetmelerine yardımcı olur, geniş garson kadrosuna olan ihtiyacı azaltır, sipariş hatalarını en aza indirger ve menü öğelerinde ve fiyatlarında kolay güncellemeler yapmalarını sağlar.`
        },
        {
            id: 4,
            question: 'Uygulama kurulumu ve kullanımı kolay mıdır?',
            answer: `Kesinlikle, uygulamamızla ilgili her türlü sorun veya soru için özel destek sağlıyoruz. Yardım için destek ekibimizle iletişime geçebilirsiniz. Anasayfadan bize ulaşın butonun tıkladığımızda orada tüm iletişim bilgilerimiz ve ayrıca bir iletişim formu bulunmaktadır. Bu yollarla bize her zaman iletişim sağlayabilirisiniz.`
        },
        {
            id: 5,
            question: 'Uygulama ile ilgili sorun yaşarsam destek alabilir miyim?',
            answer: `Evet, uygulamamız kullanım kolaylığı için tasarlanmıştır. Müşterilerimiz uygulamayı kolayca kurabilir ve kullanabilir, geleneksel sipariş yöntemlerinden kurtularak modern ve akıcı bir yemek deneyimi sunar.`
        }
    ]
};

export const submitContactUsForm = async (data) => {
    return await fetch(process.env.REACT_APP_API_BASE_URL + 'public/support/contact-form', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
};
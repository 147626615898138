import TotalTurnover from "./TotalTurnover";
import MostOrderedItems from "./MostOrderedItems";
import PeakTimes from "./PeakTimes";
import {useEffect, useState} from "react";
import { useStateValue } from "../../../context/StateProvider";
import { fetchChartData } from "../../../utils/functions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Spinner from "../../Spinner";

const Dashboard = () => {
  const [{ chartData, org }, dispatch] = useStateValue();
  const { t, i18n } = useTranslation();
  const [dateRange, setDateRange] = useState({
    fromDate: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
    toDate: new Date().toISOString()  
  });
  const [selectVal, setSelectVal] = useState(-1);
  const [selectYear, setSelectYear] = useState(2024);
  const [isFirst, setIsFirst] = useState(true);

  function getDatesOfMonth(year: any, month: any) {
    return {
      fromDate: new Date(year, month, 2).toISOString(),
      toDate: new Date(year, month + 1, 1).toISOString()
    };
  }

  function dateRangeSetFunc(value: any) {    
    if(Number(value) === -1) {
      setDateRange({
        fromDate: new Date((new Date()).setDate((new Date()).getDate() - 30)).toISOString(),
        toDate: (new Date()).toISOString()  
      });
      setSelectYear(2024);
    } else {
      setDateRange(getDatesOfMonth(selectYear, Number(value)));
    }
    setSelectVal(value);
  }

  function dateYearSetFunc(value: any) {    
    if(Number(value) === 2024) {
      setDateRange({
        fromDate: new Date((new Date()).setDate((new Date()).getDate() - 30)).toISOString(),
        toDate: (new Date()).toISOString()  
      });
      setSelectVal(-1);
    } else {
      setSelectVal(0);
      setDateRange(getDatesOfMonth(value, 0));
    }
    setSelectYear(value);
  }

  useEffect(() => {
    async function fetchChartDataApi() {
      if(isFirst) {
        setIsFirst(false);
      } else {
        toast.success("Updating Dashboard...", {
          position: 'top-right',
          autoClose: 1500
        });
      }
      await fetchChartData(dispatch, dateRange.fromDate, dateRange.toDate);
    }

    fetchChartDataApi();
  }, [dateRange]);

  return (
    <>
    {
      !chartData ? <Spinner color={"#e80013"} loading={chartData}/> :
      <div className="w-full">
          {
            (org && org.settings.subscriptionPlan.type === "STANDARD") &&
              <p className="p-1 text-textColor font-semi-bold text-sm items-center justify-center">{t("analyticMessage1")}<a className="text-lg text-blue-800" href="/payment">PREMIUM</a>{t("analyticMessage2")}</p>
          }
        <div>
        <div className={"grid grid-cols-2 p-1" + ((org && org.settings.subscriptionPlan.type === "STANDARD") ? " disabled" : "")}>
            <select name="categories" id="categories" className="col-span-1 bg-transparent outline-none capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer" value={selectVal} onChange={(e) => dateRangeSetFunc(e.target.value)}>
              <option key={12} value={-1} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('last30Days')}
              </option>
              <option key={0} value={0} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('january')}
              </option>
              <option key={1} value={1} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('february')}
              </option>
              <option key={2} value={2} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('march')}
              </option>
              <option key={3} value={3} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('april')}
              </option>
              <option key={4} value={4} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('may')}
              </option>
              <option key={5} value={5} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('june')}
              </option>
              <option key={6} value={6} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('july')}
              </option>
              <option key={7} value={7} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('august')}
              </option>
              <option key={8} value={8} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('september')}
              </option>
              <option key={9} value={9} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('october')}
              </option>
              <option key={10} value={10} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('november')}
              </option>
              <option key={11} value={11} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                {t('december')}
              </option>
            </select>
            <select name="categories" id="categories" className="col-span-1 bg-transparent outline-none capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer" value={selectYear} onChange={(e) => dateYearSetFunc(e.target.value)}>
              <option key={0} value={2024} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2024
              </option>
              <option key={1} value={2025} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2025
              </option>
              <option key={2} value={2026} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2026
              </option>
              <option key={3} value={2027} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2027
              </option>
              <option key={4} value={2028} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2028
              </option>
              <option key={5} value={2029} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2029
              </option>
              <option key={6} value={2030} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2030
              </option>
              <option key={7} value={2031} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2031
              </option>
              <option key={8} value={2032} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2032
              </option>
              <option key={9} value={2033} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
                2033
              </option>
            </select>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-4 gap-4 p-1">
            <div className="col-span-3">
              <TotalTurnover />
            </div>
            <div className="col-span-1 flex flex-col justify-center align-center">
              <div className="flex flex-col justify-center align-center h-[30vh] w-[30vh] bg-green-50 border-2 border-green-200 rounded-full">
                <p className="flex justify-center text-xl font-bold pl-1 no-underline"> {t('totalTurnover')} </p>
                <p className="flex justify-center text-xl font-bold pl-1 no-underline text-green-800 ">{(org && org.settings.subscriptionPlan.type === "STANDARD") ? "***" : chartData.totalTurnover.orderTotalPriceSum + ' '} ₺</p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 p-1">
              <MostOrderedItems />
              <PeakTimes />
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default Dashboard;

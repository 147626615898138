import {CgDetailsMore} from "react-icons/cg";
import { motion } from "framer-motion";
import {AiOutlineClose, AiOutlineCheck} from "react-icons/ai";
import {useStateValue} from "../../../context/StateProvider";
import { BiMinus, BiPlus } from "react-icons/bi";
import { getFoodyById, updateOrderItemQty, deleteCategoryItem} from "../../../utils/functions";
import React, { useState, useEffect } from "react";
import { Alert } from '../../Admin/Alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useTranslation} from "react-i18next"; // Import css
import { LuArrowUpCircle, LuArrowDownCircle } from "react-icons/lu";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

export const CategoryItem = ({
  categoryItem,
  moveUp,
  moveDown,
  index,
  len
}: {
  categoryItem: any;
  moveUp: any;
  moveDown: any;
  index: any;
  len: any;
}) => {
  const { t, i18n } = useTranslation();
  const [{ categories }, dispatch] = useStateValue();

  function remove() {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert onClose={onClose} message={t('confirmationDeleteCategory')} onConfirm={()=>{deleteCategoryItem(categoryItem.id, categories, dispatch);}}/>
          );
        }
    });
  };

  return (
    <motion.div
      className={`w-full flex flex-row justify-center gap-3 rounded-md hover:bg-orange-200`}
    >
      <div className="w-full flex justify-left flex-row gap-2 px-3 py-1 items-center">
      <div className="flex flex-col">
          <div
            onClick={() => moveUp(categoryItem)}
            className={`mr-4 ${
              index === 0 ? "hidden" : "text-textColor cursor-pointer"
            }`}
          >
            <TiArrowSortedUp className="text-3xl"/>
          </div>
          <div
            onClick={() => moveDown(categoryItem)}
            className={`mr-4 ${
              index === len - 1
                ? "hidden"
                : "text-textColor cursor-pointer"
            }`}
          >
            <TiArrowSortedDown className="text-3xl"/>
          </div>
        </div>
        <div className="w-full group flex items-center gap-2  cursor-pointer justify-left">
          <p className="text-textColor font-semi-bold text-lg items-center justify-center">{categoryItem.name}</p>
        </div>
        <div className="w-full group flex items-center gap-2  cursor-pointer justify-center">
            <span
              className={`text-xl`}
            >
              {categoryItem.icon}
            </span>
        </div>
      </div>
      <div className="w-full flex flex-row justify-end gap-2 px-3 py-1 items-center">
        <motion.div
            whileTap={{scale: 1.1}}
            whileHover={{scale: 1.2}}
            className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer"
            title={t('delete')}
            onClick={remove}
        >
            <AiOutlineClose className="text-white md:text-xl"/>
        </motion.div>
      </div>

    </motion.div>
  );
};

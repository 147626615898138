import { Session } from "../../types";
import OrderList from "../Admin/OrderList";
import { motion } from "framer-motion";
import Action from "./action";
import {useStateValue} from "../../context/StateProvider";
import { useState } from "react";

export const SessionTable = ({
  tableId,
  col,
  admin,
  isFromAdmin,
  tableOpen,
  setTableId
}: {
  tableId?: string;
  col?: boolean;
  admin?:boolean;
  isFromAdmin?:boolean;
  tableOpen: any;
  setTableId: any;
}) => {
  let [{ sessions }] = useStateValue();

  let item = sessions.filter((s: Session) => tableId === s.tableId)[0];

  return (

    <motion.div
      whileTap={{ rotate: [0, -1, 1, -1, 0] }}
      className={`${
        !col ? "w-[275px] min-w-[275px]" : "w-[320px] min-w-[320px]"
      } md:w-[300px] md:min-w-[300px] ${
        col ? "my-12" : "my-2 md:my-5"
      } h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg hover:drop-shadow-sm cursor-pointer`}
    >
      <div className="w-full flex items-center justify-end flex-col">
        <p className="text-textColor font-semi-bold text-lg">{item && item.tableTitle.toUpperCase()}</p>
      </div>
      <div className="w-full flex items-center justify-between">
        <Action tableOpen={tableOpen} setTableId={setTableId} tableId={item && item.tableId} admin={admin} isFromAdmin={isFromAdmin} />
      </div>
    </motion.div>
  );
};

import {cartItem, cartNote, FoodItem, Order, Session, Table} from "../types";
import {v4 as uuidv4} from 'uuid';
import {
    firebaseEmptyUserCart,
    firebaseFetchCategories,
    firebaseGetAllUsers,
    firebaseGetUser,
    firebaseLogout,
    firebaseUpdateEmail,
    silentRemoveUploadedImage,
} from "../Firebase";
import {
    addCartItem,
    addCartNoteDb,
    addCategory,
    closeSessionDb,
    createNotification,
    deleteCartItemDb,
    deleteCartNoteDb,
    deleteCategory,
    deleteMenuItem,
    deleteNotificationDb,
    deleteOrderDb,
    deleteTableDb,
    fetchFaqsDb,
    getAllKitchenOrders,
    getAllNotifications,
    getCartItems,
    getCartNotes,
    getChartData,
    getMenuItems,
    getOrders,
    getOrgTable,
    getPaymentDb,
    getSessions,
    getTableDataForTable,
    getTables,
    updateCartItem,
    updateMenuItem,
    updateNotification,
    updateOrder,
    updateOrg,
    updateTable
} from "../db";

import {MdShoppingBasket} from "react-icons/md";
import {toast} from "react-toastify";
import * as io from 'socket.io-client';
import i18n, {buildLocalizedPath} from '../i18n/i18n'
import {ErrorCode} from "../domain/ErrorCode";

const queryParameters = new URLSearchParams(window.location.search);

export const addToCart = async (
    cartItems: cartItem[],
    foodItems: FoodItem[],
    menuItemId: string,
    dispatch: any,
    socket: any,
    title: any,
    orders: any
) => {
    if (cartItems.some((item: cartItem) => item["menuItemId"] === menuItemId)) {
        toast.error(i18n.t('itemAlreadyInCart'), {
            icon: <MdShoppingBasket className="text-2xl text-cartNumBg"/>,
            toastId: "itemAlreadyInCart",
        });
    } else {
        const data = {
            menuItemId: menuItemId,
            qty: 1,
        };
        toast
            .promise(addCartItem(data, (queryParameters.get('id') || ''), (queryParameters.get('oid') || '')), {
                pending: i18n.t('addToCartPending'),
                success: i18n.t('addToCartSuccess'),
                error: i18n.t('addToCartError'),
            })
            .then(async () => {
                if (orders.length === 0 && cartItems.length === 0) {
                    createNotificationRequest(title, socket, 'TABLE');
                }
                await fetchCartData(dispatch)
                calculateCartTotal(cartItems, foodItems, dispatch);
                sendMessage(socket, 'carts_updated', null);
            })
            .catch((error) => {
                console.log(error);
            });
    }
};

export const createNotificationRequest = (title: any, socket: any, type: any) => {
    if (type === 'WAITER') {
        toast
            .promise(createNotification(title, (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''), type), {
                success: i18n.t('waiterCallSuccess'),
                error: i18n.t('waiterCallFail'),
            })
            .then(async () => {
                sendMessage(socket, 'send_notification', {
                    type: type
                });
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        createNotification(title, (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''), type)
            .then(async () => {
                sendMessage(socket, 'send_notification', {
                    type: type
                });
            });
    }
}

export const dispatchtCartItems = (
    items: cartItem[],
    dispatch: any
) => {
    dispatch({
        type: "SET_CARTITEMS",
        cartItems: items,
    });

    return items;
};

export const dispatchtCartNotes = (
    items: cartNote[],
    dispatch: any
) => {
    dispatch({
        type: "SET_CARTNOTES",
        cartNotes: items,
    });

    return items;
};

export const dispatchtTableDataForTable = (
    item: any,
    dispatch: any
) => {
    dispatch({
        type: "SET_TABLE",
        table: item,
    });

    return item;
};

export const dispatchtOrders = (
    items: Order[],
    dispatch: any
) => {
    dispatch({
        type: "SET_ORDERS",
        orders: items,
    });

    return items;
};

export const dispatchtSessions = (
    items: Session[],
    dispatch: any
) => {
    dispatch({
        type: "SET_SESSIONS",
        sessions: items,
    });

    return items;
};

export const fetchCartData = async (dispatch: any) => {
    await getCartItems((queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
        .then((data) => {
            dispatchtCartItems(data, dispatch);
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchNoteData = async (dispatch: any) => {
    await getCartNotes((queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
        .then((data) => {
            dispatchtCartNotes(data, dispatch);
        })
        .catch((e) => {
            console.log(e);
        });
}

export const fetchTableDataForTable = async (dispatch: any) => {
    await getTableDataForTable((queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
        .then((data) => {
            dispatchtTableDataForTable(data, dispatch);
        })
        .catch((e) => {
            console.log(e);
        });
}


export const openSocketTable = async (dispatch: any, oid: string) => {
    const socket = io.connect('' + process.env.REACT_APP_API_SOCKET_URL);
    socket.emit('join_room', oid);
    socket.on('update_session', (data) => {
        fetchCartData(dispatch);
        fetchNoteData(dispatch);
        fetchOrderData(dispatch);
    });
    socket.on('update_carts', (data) => {
        fetchCartData(dispatch);
        fetchNoteData(dispatch);
    });
    socket.on('update_orders', (data) => {
        fetchOrderData(dispatch);
        fetchCartData(dispatch);
        fetchNoteData(dispatch);
    });
    dispatch({
        type: "SET_SOCKET",
        socket: socket,
    });
};

export const openSocketKitchen = async (dispatch: any, oid: string) => {
    const socket = io.connect('' + process.env.REACT_APP_API_SOCKET_URL);
    socket.emit('join_room', oid);
    socket.on('update_session', (data) => {
        getAllKitchenOrders(oid || '')
            .then((data) => {
                data && data.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
                dispatch({
                    type: "SET_KITCHENORDERS",
                    kitchenOrders: data,
                });
                playSound('ORDER');
            })
            .catch((e) => {
                console.log(e);
            });
    });
    dispatch({
        type: "SET_SOCKET",
        socket: socket,
    });
};

export const openSocket = async (dispatch: any) => {
    const socket = io.connect('' + process.env.REACT_APP_API_SOCKET_URL);
    const oid = localStorage.getItem("org") ? JSON.parse(localStorage.getItem("org") || '{}').id : '';
    socket.emit('join_room', oid);
    socket.on('update_session', (data) => {
        fetchSessionData(dispatch);
    });
    socket.on('update_carts', (data) => {
        fetchSessionData(dispatch);
    });
    socket.on('update_orders', (data) => {
        fetchSessionData(dispatch);
    });
    socket.on('show_notifcation', async (data) => {
        await fetchNotifications(dispatch);
        playSound(data.type);
    });
    dispatch({
        type: "SET_SOCKET",
        socket: socket,
    });
};

export const sendMessage = async (socket: any, message: any, data: any) => {
    const oid = queryParameters.get('oid') || (localStorage.getItem("org") ? JSON.parse(localStorage.getItem("org") || '{}').id : '');
    socket.emit(message, {
        room: oid,
        data: data
    });
};

export const fetchOrderData = async (dispatch: any) => {
    await getOrders((queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
        .then((data) => {
            data && data.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
            dispatchtOrders(data, dispatch);
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchSessionData = async (dispatch: any) => {
    await getSessions()
        .then((data) => {
            data && data.sort((a: any, b: any) => (a.tableTitle < b.tableTitle) ? 1 : ((b.tableTitle < a.tableTitle) ? -1 : 0))
            dispatchtSessions(data, dispatch);
        })
        .catch((e) => {
            console.log(e);
        });

};

export const fetchFoodData = async (dispatch: any) => {
    await getMenuItems()
        .then((data) => {
            data && data.sort((a: any, b: any) => (b.title < a.title) ? 1 : ((a.title < b.title) ? -1 : 0));
            dispatch({
                type: "SET_FOOD_ITEMS",
                foodItems: data,
            });
        })
        .then(() => {
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchChartData = async (dispatch: any, fromDate: any, toDate: any) => {
    await getChartData(fromDate, toDate)
        .then((data) => {
            dispatch({
                type: "SET_CHART_DATA",
                chartData: data,
            });
        })
        .then(() => {
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchFaqs = async (dispatch: any) => {
    await fetchFaqsDb()
        .then((data) => {
            dispatch({
                type: "SET_FAQS",
                faqs: data,
            });
        })
        .then(() => {
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchFoodDataForTable = async (dispatch: any, tableId: any, organizationId: any) => {
    await getMenuItems(tableId, organizationId)
        .then((data) => {
            data && data.sort((a: any, b: any) => (b.title < a.title) ? 1 : ((a.title < b.title) ? -1 : 0));
            dispatch({
                type: "SET_FOOD_ITEMS",
                foodItems: data,
            });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchTableData = async (dispatch: any) => {
    await getTables()
        .then((data) => {
            dispatch({
                type: "SET_TABLES",
                tables: data,
            });
        })
        .then(() => {
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchOrgData = async (dispatch: any, organizationId: any) => {
    await getOrgTable(organizationId)
        .then((data) => {
            i18n.changeLanguage(data.settings.language);
            dispatch({
                type: "SET_ORG",
                org: data,
            });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchCategories = async (dispatch: any) => {
    await firebaseFetchCategories()
        .then((data) => {
            data && data.sort((a: any, b: any) => a.sortIndex - b.sortIndex);
            dispatch({
                type: "SET_CATEGORIES",
                categories: data,
            });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchNotifications = async (dispatch: any) => {
    await getAllNotifications()
        .then((data) => {
            data && data.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
            dispatch({
                type: "SET_NOTIFICATIONS",
                notifications: data,
            });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const fetchAllTableData = async (dispatch: any, setLoading: any) => {
    await fetchOrgData(dispatch, (queryParameters.get('oid') || ''));
    await fetchCategoriesForTable(dispatch, (queryParameters.get('oid') || ''));
    await fetchFoodDataForTable(dispatch, (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''));
    await fetchTableDataForTable(dispatch);
    await fetchCartData(dispatch);
    setLoading(false);
    fetchNoteData(dispatch);
    fetchOrderData(dispatch);
};

export const fetchAllDataForKitchen = async (dispatch: any, setLoading: any) => {
    await fetchOrgData(dispatch, (queryParameters.get('oid') || ''));
    await getAllKitchenOrders((queryParameters.get('oid') || ''))
        .then((data) => {
            data && data.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
            dispatch({
                type: "SET_KITCHENORDERS",
                kitchenOrders: data,
            });
        })
        .catch((e) => {
            console.log(e);
        });
    setLoading(false);
};

export const makeReadAllNotificaitons = async (notifications: any, dispatch: any) => {
    if (notifications) {
        notifications.map((notification: any) => {
            if (!notification.read) {
                notification.read = true;
                updateNotification(notification);
            }
        })
        dispatch({
            type: "SET_NOTIFICATIONS",
            notifications: notifications,
        });
    }
}

export const deleteNotification = async (id: any, notifications: any, dispatch: any) => {
    const index = notifications.findIndex(
        (notification: any) => notification.id === id
    );
    if (index !== -1) {
        notifications.splice(index, 1);
        dispatch({
            type: "SET_NOTIFICATIONS",
            notifications: notifications,
        });
        await deleteNotificationDb(id)
            .catch((e) => {
                console.log(e);
            });
    }
}

export const fetchCategoriesForTable = async (dispatch: any, organizationId: any) => {
    await firebaseFetchCategories(organizationId)
        .then((data) => {
            data && data.sort((a: any, b: any) => a.sortIndex - b.sortIndex);
            dispatch({
                type: "SET_CATEGORIES",
                categories: data,
            });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const getFoodyById = (menu: FoodItem[], fid: string) => {
    return menu.find((item: FoodItem) => item.id === fid);
};

// Update Cart Item Quantity
export const updateCartItemQty = async (
    cartItems: cartItem[],
    foodItems: FoodItem[],
    item: cartItem,
    dispatch: any,
    val: number,
    socket: any
) => {
    const index = cartItems.findIndex(
        (cartItem: cartItem) => cartItem.id === item.id
    );
    if (index !== -1) {
        cartItems[index].qty += val;
        dispatch({
            type: "SET_CARTITEMS",
            cartItems: cartItems,
        });
        calculateCartTotal(cartItems, foodItems, dispatch);
        await updateCartItem(cartItems[index], (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
            .catch((e) => {
                console.log(e);
            });
        sendMessage(socket, 'carts_updated', null);
    }
};

// delete Cart note Quantity
export const deleteCartNote = async (
    cartNotes: cartNote[],
    id: string,
    dispatch: any,
    socket: any
) => {
    const index = cartNotes.findIndex(
        (cartNote: cartNote) => cartNote.id === id
    );
    if (index !== -1) {
        cartNotes.splice(index, 1);
        dispatch({
            type: "SET_CARTNOTES",
            cartNotes: cartNotes,
        });
        await deleteCartNoteDb(id, (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
            .catch((e) => {
                console.log(e);
            });
        sendMessage(socket, 'carts_updated', null);

    }
};

// delete Cart notes
export const deleteAllCartNotes = async (
    cartNotes: cartNote[],
    dispatch: any,
    socket: any
) => {
    cartNotes.map(async (note) => {
        await deleteCartNoteDb(note.id, (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
            .catch((e) => {
                console.log(e);
            });
    })
    dispatch({
        type: "SET_CARTNOTES",
        cartNotes: [],
    });
    sendMessage(socket, 'carts_updated', null);
};

// delete Cart note Quantity
export const addCartNote = async (
    message: string,
    socket: any,
    dispatch: any,
    cartNotes: any
) => {
    cartNotes.push({
        id: 'temporaryId',
        message: message
    });
    dispatch({
        type: "SET_CARTNOTES",
        cartNotes: cartNotes,
    });
    await addCartNoteDb(message, (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
        .then(() => {
            fetchNoteData(dispatch);
            sendMessage(socket, 'carts_updated', null);
        })
        .catch((e) => {
            console.log(e);
        });
};

//  Delete Cart Item
export const deleteCartItem = async (
    cartItems: cartItem[],
    foodItems: FoodItem[],
    item: cartItem,
    dispatch: any,
    socket: any
) => {
    const index = cartItems.findIndex(
        (cartItem: cartItem) => cartItem.id === item.id
    );
    if (index !== -1) {
        cartItems.splice(index, 1);
        dispatch({
            type: "SET_CARTITEMS",
            cartItems: cartItems,
        });
        calculateCartTotal(cartItems, foodItems, dispatch);
        await deleteCartItemDb(item, (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
            .then(() => {
            })
            .catch((e) => {
                console.log(e);
            });
        sendMessage(socket, 'carts_updated', null);
    }
};


// Calculate Total Price Round to 2 decimal places
export const calculateCartTotal = (
    cartItems: cartItem[],
    foodItems: FoodItem[],
    dispatch: any
) => {
    let total = 0;
    cartItems.forEach((item: cartItem) => {
        const foodItem = getFoodyById(foodItems, item.menuItemId);
        total += item.qty * (foodItem?.price.amount || 0);
    });
    dispatch({
        type: "SET_CART_TOTAL",
        cartTotal: (total / 100).toFixed(2),
    });
};

// Empty Cart
export const emptyCart = async (
    cartItems: cartItem[],
    foodItems: FoodItem[],
    cartNotes: any,
    dispatch: any,
    socket: any
) => {
    if (cartItems.length > 0) {
        dispatch({
            type: "SET_CARTITEMS",
            cartItems: [],
        });
        calculateCartTotal(cartItems, foodItems, dispatch);
        await firebaseEmptyUserCart(cartItems, (queryParameters.get('id') || ''), (queryParameters.get('oid') || ''))
            .then(() => {
            })
            .catch((e) => {
                console.log(e);
            });
        deleteAllCartNotes(cartNotes, dispatch, socket)
            .catch((e) => {
                console.log(e);
            });
    } else {
        toast.warn(i18n.t('cartIsAlreadyEmpty'));
    }
};

// Hide Cart
export const hideCart = (dispatch: any) => {
    document.body.classList.remove('modal-open');
    dispatch({
        type: "TOGGLE_CART",
        showCart: !true,
    });
};

// Hide Cart
export const hideContactform = (dispatch: any) => {
    dispatch({
        type: "TOGGLE_CONTACT_FORM",
        showContactForm: !true,
    });
};

export const shuffleItems = (items: any) => {
    let currentIndex = items.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [items[currentIndex], items[randomIndex]] = [
            items[randomIndex],
            items[currentIndex],
        ];
    }

    return items;
};

export const logout = async (user: any, dispatch: any, navigate: any) => {
    if (user) {
        await firebaseLogout()
            .then(() => {
                dispatch({
                    type: "SET_USER",
                    user: null,
                });
                dispatch({
                    type: "SET_ORG",
                    org: null,
                });
                dispatch({
                    type: "SET_CARTITEMS",
                    cartItems: [],
                });
                // turn off adminMode
                dispatch({
                    type: "SET_ADMIN_MODE",
                    adminMode: false,
                });

                localStorage.setItem("user", "undefined");
                localStorage.setItem("adminMode", "undefined");
                navigate(buildLocalizedPath("/"));
            })
            .catch((e: any) => {
                console.log(e);
            });
    } else {
        console.log("You are not logged in");
    }
};

export const ToggleAdminMode = (dispatch: any, state: boolean) => {
    dispatch({
        type: "SET_ADMIN_MODE",
        adminMode: state,
    });
    localStorage.setItem("adminMode", JSON.stringify(state));
};

export const isAdmin = (user: any) => {
    let isAdmin = (user?.role === "ORG_ADMIN") || user?.role === "SUPER_ADMIN"
    return isAdmin
};

export const isSuperAdmin = (user: any) => {
    let isSuperAdmin = user?.role === "SUPER_ADMIN"
    return isSuperAdmin
};
// get user
export const getUserData = async (user: any) => {
    return await firebaseGetUser(user.uid);
};

// update currentUser
export const updateOrgData = async (
    org: any,
    dispatch: any,
    alert: boolean
) => {
    await updateOrg(org)
        .then(() => {
            dispatch({
                type: "SET_ORG",
                org: org,
            });
        })
        .catch((e: any) => {
            console.log(e);
        })
        .then(() => {
            localStorage.setItem("org", JSON.stringify(org));
            alert && toast.success(i18n.t('saveSuccess'));
        });
};

export const updateEmail = async (
    email: any,
    password: any,
    dispatch: any,
    user: any,
    alert: boolean
) => {
    await firebaseUpdateEmail(email, password)
        .then((data: any) => {
            toast.success(i18n.t('saveSuccess'));
            localStorage.setItem("user", "undefined");
            localStorage.setItem("adminMode", "undefined");
            window.location.href = "/"
        })
        .catch((e: any) => {
            let errorText = e.message;
            if (errorText.includes('wrongPasswordError')) {
                toast.error(i18n.t('passwordError'));
            } else if (errorText.includes('sameEmailError')) {
                toast.error(i18n.t('sameEmailError'));
            } else if (errorText.includes('auth/email-already-in-use')) {
                toast.error(i18n.t('emailInUseError'));
            } else {
                toast.error(i18n.t('emailUpdateError'));
            }
        });
};

// update foodData
export const updateFoodData = async (
    food: any,
    dispatch: any,
    alert: boolean
) => {
    await updateMenuItem(food)
        .catch((e: any) => {
            console.log(e);
        });
};

// update foodData
export const updateTableData = async (
    table: any,
    dispatch: any,
    alert: boolean
) => {
    await updateTable(table)
        .catch((e: any) => {
            console.log(e);
        });
};

// get all users
export const dispatchUsers = async (dispatch: any) => {
    await firebaseGetAllUsers()
        .then((users: any) => {
            dispatch({
                type: "SET_USERS",
                users: users,
            });
        })
        .catch((e: any) => {
            console.log(e);
        });
}
export const getAllUser = async () => {
    await firebaseGetAllUsers().then((users: any) => {
        return users
    }).catch((e: any) => {
        console.log(e)
    })
}
// delete food
export const deleteFood = async (
    food: FoodItem,
    foodItems: FoodItem[],
    dispatch: any
) => {
    try {
        await deleteMenuItem(food.id);
    } catch (e: any) {
        if (e.message === ErrorCode.MenuItemHasLinkError) {
            toast.error(i18n.t('menuItemHasLinkError'));
            return;
        } else {
            throw e;
        }
    }
    silentRemoveUploadedImage(food.imageUrl);
    // remove food from foodItems
    const foodIndex = foodItems.indexOf(food);
    if (foodIndex !== -1) {
        foodItems.splice(foodIndex, 1)
    }
    dispatch({
        type: "SET_FOOD_ITEMS",
        foodItems
    })
    toast.success(i18n.t('saveSuccess'));
};

// delete food
export const deleteTable = async (
    table: Table,
    tables: Table[],
    dispatch: any
) => {

    //silentRemoveUploadedImage(table.imageUrl);
    await deleteTableDb(table.id);
    // remove food from foodItems
    const tableIndex = tables.indexOf(table);
    if (tableIndex !== -1) {
        tables.splice(tableIndex, 1)
    }
    dispatch({
        type: "SET_TABLES",
        tables
    })
    toast.success(i18n.t('saveSuccess'));
};

export const closeSession = async (
    tableId: string,
    sessions: Session[],
    dispatch: any,
    socket: any
) => {
    await closeSessionDb(tableId);
    // remove food from foodItems
    sendMessage(socket, 'sessions_updated', null);
    const sessionIndex = sessions.map(e => e.tableId).indexOf(tableId);
    if (sessionIndex !== -1) {
        sessions.splice(sessionIndex, 1)
    }
    dispatch({
        type: "SET_SESSIONS",
        sessions
    })
    toast.success(i18n.t('saveSuccess'));
};

export const getPayment = async (
    tableId: string,
    sessions: Session[],
    dispatch: any,
    socket: any
) => {
    await getPaymentDb(tableId);
    sendMessage(socket, 'sessions_updated', null);
    const sessionIndex = sessions.map(e => e.tableId).indexOf(tableId);
    if (sessionIndex !== -1) {
        sessions.splice(sessionIndex, 1)
    }
    dispatch({
        type: "SET_SESSIONS",
        sessions
    });
    fetchSessionData(dispatch);
    toast.success(i18n.t('saveSuccess'));
};

export const approveOrder = async (
    orderId: string,
    tableId: string,
    sessions: Session[],
    dispatch: any,
    socket: any
) => {
    const sessionIndex = sessions.map(e => e.tableId).indexOf(tableId);
    const orderIndex = sessions[sessionIndex].orders.map(e => e.id).indexOf(orderId);
    if (sessionIndex !== -1 && orderIndex !== -1) {
        sessions[sessionIndex].orders[orderIndex].status = 'APPROVED';
        await updateOrder(tableId, sessions[sessionIndex].orders[orderIndex]);
        fetchSessionData(dispatch);
        sendMessage(socket, 'sessions_updated', null);
    }
    dispatch({
        type: "SET_SESSIONS",
        sessions
    })
    toast.success(i18n.t('saveSuccess'));
};

export const deleteOrder = async (
    orderId: string,
    tableId: string,
    sessions: Session[],
    dispatch: any,
    socket: any
) => {
    await deleteOrderDb(tableId, orderId);
    sendMessage(socket, 'sessions_updated', null);
    const sessionIndex = sessions.map(e => e.tableId).indexOf(tableId);
    const orderIndex = sessions[sessionIndex].orders.map(e => e.id).indexOf(orderId);
    if (orderIndex !== -1) {
        sessions[sessionIndex].orders.splice(orderIndex, 1)
    }
    dispatch({
        type: "SET_SESSIONS",
        sessions
    })
    fetchSessionData(dispatch);
    toast.success(i18n.t('saveSuccess'));
};

export const updateOrderItemQty = async (
    sessions: Session[],
    orderId: string,
    orderItemId: string,
    dispatch: any,
    val: number,
    socket: any
) => {
    sessions.map((session) => {
        session.orders.map((order) => {
            if (order.id === orderId) {
                order.cartItems.map(async (item) => {
                    if (item.id === orderItemId) {
                        item.qty += val;
                        dispatch({
                            type: "SET_SESSIONS",
                            sessions
                        })
                        await updateOrder(session.tableId, order);
                        sendMessage(socket, 'sessions_updated', null);
                    }
                });
            }
        });
    });
};

export const deleteOrderItem = async (
    orderId: string,
    orderItemId: string,
    sessions: Session[],
    dispatch: any,
    socket: any
) => {
    sessions.map((session) => {
        session.orders.map(async (order) => {
            if (order.id === orderId) {
                order.cartItems = order.cartItems.filter((item) => {
                    return item.id !== orderItemId;
                });
                dispatch({
                    type: "SET_SESSIONS",
                    sessions
                })
                await updateOrder(session.tableId, order);
                sendMessage(socket, 'sessions_updated', null);
                toast.success(i18n.t('saveSuccess'));
            }
        });
    });
};

export const addOrderItem = async (
    orderId: string,
    foodItemId: string,
    qty: string,
    sessions: Session[],
    dispatch: any,
    socket: any
) => {
    sessions.map((session) => {
        session.orders.map(async (order) => {
            if (order.id === orderId) {
                const orderItem = {
                    id: uuidv4(),
                    menuItemId: foodItemId,
                    qty: Number(qty)
                }
                order.cartItems.push(orderItem);
                dispatch({
                    type: "SET_SESSIONS",
                    sessions
                })
                await updateOrder(session.tableId, order);
                sendMessage(socket, 'sessions_updated', null)
                toast.success(i18n.t('saveSuccess'));
            }
        });
    });
};

export const addCategoryItem = async (
    name: string,
    icon: string,
    dispatch: any
) => {
    await addCategory(name, icon);
    await fetchCategories(dispatch)
    toast.success(i18n.t('saveSuccess'));
};

export const deleteCategoryItem = async (
    id: string,
    categories: any,
    dispatch: any
) => {
    let toastId;
    try {
        toastId = toast.loading(i18n.t('deletePending'));
        await deleteCategory(id);
        toast.dismiss(toastId);
    } catch (e: any) {
        toast.dismiss(toastId);
        if (e.message === ErrorCode.CategoryHasLinkError) {
            toast.error(i18n.t('categoryHasLinkError'));
            return;
        } else {
            throw e;
        }
    }
    const categoryIndex = categories.map((e: { id: any; }) => e.id).indexOf(id);
    if (categoryIndex !== -1) {
        categories.splice(categoryIndex, 1)
    }
    dispatch({
        type: "SET_CATEGORIES",
        categories
    })
    toast.success(i18n.t('deleteSuccess'));
};

export const playSound = async (type: any) => {
    let audio = document.createElement('audio');
    switch (type) {
        case 'ORDER':
            audio.src = './sounds/doorbell-order.mp3';
            break;
        case 'TABLE':
            audio.src = './sounds/doorbell-table.mp3';
            break;
        case 'WAITER':
            audio.src = './sounds/doorbell-waiter.mp3';
            break;
        default:
            audio.src = './sounds/doorbell-order.mp3';
            break;
    }

    const playPromise = audio.play();
    if (playPromise !== undefined) {
        playPromise.then(function () {
            audio.remove();
        }).catch(function (error) {
            console.log(error);
        });
    }

};

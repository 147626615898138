import {motion} from 'framer-motion'
import { useStateValue } from '../../context/StateProvider';
import { toast } from "react-toastify";
import { fetchCartData, fetchOrderData, addCartNote, fetchNoteData, createNotificationRequest} from "../../utils/functions";
import { createOrder } from "../../db";
import { MdNote } from 'react-icons/md';
import { SingleNote } from './SingleNote';
import { useState} from "react";
import { AiOutlinePlus} from "react-icons/ai";
import {useTranslation} from "react-i18next";


const queryParameters = new URLSearchParams(window.location.search);

const CartTotal = ({ordersState}: {ordersState:any}) => {
    const { t, i18n } = useTranslation();
  const [{cartTotal, socket, cartNotes, table}, dispatch] = useStateValue();
  const [note, setNote] = useState("");

  const orderCreate = () => {
    try {
      toast
      .promise(createOrder((queryParameters.get('id') || ''), (queryParameters.get('oid') || ''), socket), {
        pending: t('createOrderPending'),
        success: t('createOrderSuccess'),
        error: t('createOrderError'),
      })
      .then(() => {
        fetchCartData(dispatch);
        fetchNoteData(dispatch);
        fetchOrderData(dispatch);
        createNotificationRequest(table.title, socket, 'ORDER');
      })
      .catch((error) => {
        console.log(error);
      });
    } catch {

    }
  }

  const addMessage = async () => {
    if (!note) {
      toast.error(t('missingRequiredFieldError'));
      return;
    }
    addCartNote(note, socket, dispatch, cartNotes);
    setNote('');
  }

  return (
    <div className='w-full mt-0 md:mt-0 mb-4 rounded rounded-[1rem] flex bg-cartItem px-4 py-1 flex-col items-center overflow-y-scroll scrollbar-hidden'>
        <div className="w-full flex-col items-center justify-between">
          {
            cartNotes && cartNotes.length > 0 &&
            <>
              <div className="w-full py-1 flex items-center justify-center">
                <p className='flex text-center text-white text-xl'>Notes</p>
              </div>
              <div className="flex-1 my-2 mx-6 overflow-y-scroll scrollbar-hidden  h-24">
                {
                  cartNotes.map((item: any) => (
                    <SingleNote id={item.id} message={item.message}/>
                  ))
                }
              </div>
            </>
          }
        </div>
        <div className={"w-full py-2 border-b border-gray-300 flex -tems-center gap-2"}>
            <MdNote className="text-xl text-gray-600"/>
            <input
                type="text"
                required
                placeholder={t('note')}
                className="h-full w-full bg-transparent pl-2 text-gray-400 outline-none border-none placeholder:text-gray-400"
                value={note}
                onChange={(e) => setNote(e.target.value)}
            />
            <motion.div
                whileTap={{scale: 1.1}}
                whileHover={{scale: 1.2}}
                className="w-8 h-8 md:w-6 md:h-6 rounded-lg bg-green-600 flex items-center justify-center cursor-pointer"
                title={t('addNote')}
                onClick={addMessage}
            >
                <AiOutlinePlus className="text-white text-2xl md:text-xl"/>
            </motion.div>
        </div>
        <div className="w-full py-1 flex items-center justify-between">
          <p className="text-gray-400 text-base md:text-lg ">{t('cartTotal')}</p>
          <p className="text-gray-400 text-base md:text-lg">-</p>
          <p className="text-gray-400 text-base md:text-lg "><span className="text-sm text-red-600">₺</span> {cartTotal}</p>
        </div>
        <div className="w-full border-b border-gray-600 my-"></div>
        <div className="w-full py-1 flex items-center justify-between">
          <p className="text-gray-50 text-base md:text-lg uppercase">{t('total')}</p>
          <p className="text-gray-50 text-base md:text-lg">-</p>
          <p className="text-gray-50 text-base md:text-lg "><span className="text-sm text-red-600">₺</span> {cartTotal}</p>
        </div>
        <div className='flex flex-col w-full px-2 mb-12'>
          <motion.button onClick = {() => orderCreate()} whileTap={{scale:0.8}} className='w-full p-2 rounded-full bg-gradient-to-tr from-green-300 to-green-600 text-gray-50 text-lg my-2 hover:shadow-lg'>
              {t('order')}
          </motion.button>
          <motion.button onClick = {() => ordersState(true)} whileTap={{scale:0.8}} className='w-full p-2 rounded-full bg-gradient-to-tr from-red-400 to-black-600 text-gray-50 text-lg my-2 hover:shadow-lg'>
              {t('previousOrders')}
          </motion.button>
        </div>
    </div>
  )
}

export default CartTotal
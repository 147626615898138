import {motion} from "framer-motion";
import {AiOutlineCheck} from "react-icons/ai";
import {useStateValue} from "../../../context/StateProvider";
import {useState} from "react";
import {toast} from "react-toastify";
import {addCategoryItem} from "../../../utils/functions";
import {iconMap} from '../../../utils/iconMap'
import {useTranslation} from "react-i18next";
import Select from "react-select";


export const CategoryItemAdd = () => {
    const {t, i18n} = useTranslation();
    const [{categories}, dispatch] = useStateValue();
    const [categoryName, setCategoryName] = useState("");
    const [icon, setIcon] = useState("");

    const clearForm = () => {
        setCategoryName("");
        setIcon("");
    };
    const addItem = async () => {
        if (!categoryName) {
            toast.error(t('missingRequiredFieldError'));
            return;
        }
        await addCategoryItem(categoryName, icon, dispatch);
        clearForm();
    }

    return (
        <>
            <motion.div
                className={`w-full flex flex-row justify-center gap-3 rounded-md`}
            >
                <div className="w-full flex justify-left flex-row gap-2 px-3 py-1 items-center">
                    <div className="w-full group flex items-center gap-2  cursor-pointer justify-end">
                        <input
                            type="text"
                            required
                            placeholder={t('enterCategoryName')}
                            autoFocus
                            className="outline-none w-full capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer placeholder:text-black-400"
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                        />
                    </div>
                    <div className="w-full group flex items-center gap-2  cursor-pointer justify-end">
                        <Select name="categories" id="categories"
                                menuPortalTarget={document.body}
                                className="outline-none w-full capitalize text-base p-2 rounded-md cursor-pointer placeholder:text-black-400"
                                options={iconMap}
                                placeholder={t('selectIcon')}
                                isSearchable={false}
                                defaultInputValue={''}
                                onChange={selectedOption => setIcon(selectedOption!.value)}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-row justify-end gap-2 px-3 py-1">
                    <motion.div
                        whileTap={{scale: 1.1}}
                        whileHover={{scale: 1.2}}
                        className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-green-600 flex items-center justify-center cursor-pointer"
                        title={t('confirm')}
                        onClick={addItem}
                    >
                        <AiOutlineCheck className="text-white md:text-xl"/>
                    </motion.div>
                </div>

            </motion.div>
        </>

    );
};

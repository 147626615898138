import {FoodItem, Money} from "../../types";
import {motion} from "framer-motion";
import Action from "./action";
import {useStateValue} from "../../context/StateProvider";
import {PiEyeSlash} from "react-icons/pi";

export const SingleFoodItem = ({
                                   foodId,
                                   col,
                                   admin,
                                   isFromAdmin
                               }: {
    foodId: string;
    col?: boolean;
    admin?: boolean;
    isFromAdmin?: boolean
}) => {
    let [{foodItems}] = useStateValue();
    let item = foodItems.filter((f: FoodItem) => foodId === f.id)[0];
    item.price = new Money(item.price.amount);
    const {title, price, imageUrl, description, status} = item;

    return (
        <motion.div
            whileTap={{rotate: [0, -1, 1, -1, 0]}}
            className={`${
                !col ? "w-[275px] min-w-[275px]" : "w-[320px] min-w-[320px]"
            } md:w-[300px] md:min-w-[300px] ${
                col ? "my-6" : "my-2 md:my-5"
            } h-auto bg-cardOverlay rounded-lg border-2 border-gray-200 p-2 px-3 backdrop-blur-lg hover:drop-shadow-sm cursor-pointer`}
        >
            <div className="w-full flex items-center justify-between">
                <motion.img
                    whileHover={{scale: 1.2}}
                    whileTap={{scale: 1.1}}
                    className="w-40 h-40 md:w-48 md:h-40 -mt-8 object-contain cursor-pointer"
                    alt={"Order Master - " + (description || "Food Item")}
                    src={imageUrl}
                    width={50}
                    height={50}
                    loading="lazy"
                    title={"Order Master - " + (description || "Food Item")}
                />
                <Action food={item} admin={admin} isFromAdmin={isFromAdmin}/>
            </div>
            <div className="w-full flex-col">
                <p className="text-right text-textColor font-semi-bold text-lg">{title}</p>
                <p className="text-right truncate mt-1 text-sm text-gray-500">{description} </p>
                <div className="flex gap-8">
                    <div className="justify-items-start">
                        {status === 'INACTIVE' && (
                            <PiEyeSlash fill={'gray'} fillOpacity={0.75} size={40}/>
                        )}
                    </div>
                    <p className="ml-auto text-right align-bottom">
                        <span className="text-sm text-red-600">₺</span>
                        <span className="text-base text-headingColor font-semibold">{price.toMajorUnit()}</span>
                    </p>
                </div>

            </div>
        </motion.div>
    );
};

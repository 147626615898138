import AboutEn from "./about_en";
import AboutTr from "./about_tr";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import {buildLocalizedCanonicalPath} from "../../i18n/i18n";

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('aboutMetaTitle')}</title>
        <meta name="description" content={t('aboutMetaDesc')}/>
        <link rel="canonical" href={buildLocalizedCanonicalPath("/about")} hrefLang={i18n.language}/>
      </Helmet>
      <h1 className="hidden"> {t('aboutMetaTitle')}</h1>
      {i18n.language === 'tr' ? <AboutTr/> : <AboutEn/>}
    </>
  );
}

export default About;
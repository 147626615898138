import React from 'react'
import {useTranslation} from "react-i18next";
import {Home1, Home2, Home3, Home4, Home5, Home6} from '../Assets'


const HomePageInfo = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className='mt-8'>
            <section
                className='grid grid-cols-1 md:grid-cols-2 gap-2 w-full bg-gradient-to-br from-orange-100 to-orange-100 px-3 md:px-6'
                id='info'>
                <div className="flex-1 flex flex-col items-center text-center justify-center gap-3  m-6 md:m-1 ">
                    <p className="text-3xl lg:text-6xl font-bold tracking-wide flex flex-col text-headingColor">
                        {t('homepageInfo1')}
                    </p>
                    <p className="text-xl text-textColor text-center md:text-center md:w-[80%]">
                        {t('homepageInfo2')}
                    </p>
                </div>
                <div className="flex-1 flex items-center justify-center text-center relative p-8">
                    <img src={Home1} alt="Order Master - Qr Menü Entegrasyonu Image"
                         title="Order Master - Qr Menü Entegrasyonu Image" width={50} height={50}
                         className='rounded-3xl w-[80%]' loading="lazy"/>
                </div>
            </section>
            <section className='grid grid-cols-1 md:grid-cols-2 gap-2 w-full px-3 md:px-6' id='info'>
                <div className="flex-1 flex items-center justify-center text-center relative p-8">
                    <img src={Home2} alt="Order Master - Dinamik Menü Kontrolü Image"
                         title="Order Master - Dinamik Menü Kontrolü Image" width={50} height={50}
                         className='rounded-3xl w-[80%]' loading="lazy"/>
                </div>
                <div className="flex-1 flex flex-col items-center text-center justify-center gap-3  m-6 md:m-1 ">
                    <p className="text-3xl lg:text-6xl font-bold tracking-wide flex flex-col text-headingColor">
                        {t('homepageInfo3')}
                    </p>
                    <p className="text-xl text-textColor text-center md:text-center md:w-[80%]">
                        {t('homepageInfo4')}
                    </p>
                </div>
            </section>
            <section
                className='grid grid-cols-1 md:grid-cols-2 gap-2 w-full bg-gradient-to-br from-red-100 to-red-100 px-3 md:px-6'
                id='info'>
                <div className="flex-1 flex flex-col items-center text-center justify-center gap-3  m-6 md:m-1 ">
                    <p className="text-3xl lg:text-6xl font-bold tracking-wide flex flex-col text-headingColor">
                        {t('homepageInfo5')}
                    </p>
                    <p className="text-xl text-textColor text-center md:text-center md:w-[80%]">
                        {t('homepageInfo6')}
                    </p>
                </div>
                <div className="flex-1 flex items-center justify-center text-center relative p-8">
                    <img src={Home3} alt="Order Master - Kolay Sipariş Yönetimi Image"
                         title="Order Master - Kolay Sipariş Yönetimi Image" width={50} height={50}
                         className='rounded-3xl w-[80%]' loading="lazy"/>
                </div>
            </section>
            <section className='grid grid-cols-1 md:grid-cols-2 gap-2 w-full px-3 md:px-6' id='info'>
                <div className="flex-1 flex items-center justify-center text-center relative p-8">
                    <img src={Home4} alt="Order Master - Anlık Bildirimler Image"
                         title="Order Master - Anlık Bildirimler Image" width={50} height={50}
                         className='rounded-3xl w-[80%]' loading="lazy"/>
                </div>
                <div className="flex-1 flex flex-col items-center text-center justify-center gap-3  m-6 md:m-1 ">
                    <p className="text-3xl lg:text-6xl font-bold tracking-wide flex flex-col text-headingColor">
                        {t('homepageInfo7')}
                    </p>
                    <p className="text-xl text-textColor text-center md:text-center md:w-[80%]">
                        {t('homepageInfo8')}
                    </p>
                </div>
            </section>
            <section
                className='grid grid-cols-1 md:grid-cols-2 gap-2 w-full bg-gradient-to-br from-orange-100 to-orange-100 px-3 md:px-6'
                id='info'>
                <div className="flex-1 flex flex-col items-center text-center justify-center gap-3  m-6 md:m-1 ">
                    <p className="text-3xl lg:text-6xl font-bold tracking-wide flex flex-col text-headingColor">
                        {t('homepageInfo9')}
                    </p>
                    <p className="text-xl text-textColor text-center md:text-center md:w-[80%]">
                        {t('homepageInfo10')}
                    </p>
                </div>
                <div className="flex-1 flex items-center justify-center text-center relative p-8">
                    <img src={Home5} alt="Order Master - Kolay Kurulum Image" title="Order Master - Kolay Kurulum Image"
                         width={50} height={50} className='rounded-3xl w-[80%]' loading="lazy"/>
                </div>
            </section>
            <section className='grid grid-cols-1 md:grid-cols-2 gap-2 w-full px-3 md:px-6' id='info'>
                <div className="flex-1 flex items-center justify-center text-center relative p-8">
                    <img src={Home6} alt="Order Master - Canlı Danışmanlık ve Destek Image"
                         title="Order Master - Canlı Danışmanlık ve Destek Image" width={50} height={50}
                         className='rounded-3xl w-[80%]' loading="lazy"/>
                </div>
                <div className="flex-1 flex flex-col items-center text-center justify-center gap-3  m-6 md:m-1 ">
                    <p className="text-3xl lg:text-6xl font-bold tracking-wide flex flex-col text-headingColor">
                        {t('homepageInfo11')}
                    </p>
                    <p className="text-xl text-textColor text-center md:text-center md:w-[80%]">
                        {t('homepageInfo12')}
                    </p>
                </div>
            </section>
        </div>
    )
}

export default HomePageInfo
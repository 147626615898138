import React, { useState, useEffect, useRef } from "react";
import { useStateValue } from "../../../context/StateProvider";
import { getFoodyById } from "../../../utils/functions";
import {useTranslation} from "react-i18next";

export const TableReceipt = ({
    key,
    receiptData
  }: {
    key: string;
    receiptData?: any;
  }) => {
    const { t, i18n } = useTranslation();
    const [{ org, foodItems }, dispatch] = useStateValue();
    const [totalPrice, setTotalPrice] = React.useState(0);
    const [foodItemArr, setFoodItemArr] = React.useState([]);

    useEffect(() => {
        let cartFoodItemsArr : any = [];
        let totalPrice : any = 0;
        receiptData.orders.map((order: any) => {
            order.cartItems.map((item: any) => {
                const foodItem = getFoodyById(foodItems, item.menuItemId);
                const index = cartFoodItemsArr.map((e:any) => e.id).indexOf(foodItem?.id);

                if (index !== -1){
                    cartFoodItemsArr[index].qty += item.qty;
                    cartFoodItemsArr[index].price += item.qty * foodItem?.price.amount!;
                    totalPrice += item.qty * foodItem?.price.amount!;
                } else {
                    cartFoodItemsArr.push({
                        id: foodItem?.id,
                        title: foodItem?.title,
                        qty: item.qty,
                        price: foodItem?.price.amount
                    })
                    totalPrice += (item.qty * foodItem?.price.amount!);
                }
            });
        });
        setTotalPrice(totalPrice/100);
        setFoodItemArr(cartFoodItemsArr);
    }, []);
    return (
        <>
            <div className="ticket">
            <img src={org.photoUrl || "./logo.png"} width={50} height={50} className="logo" alt="Order Master - Logo" loading="lazy"  title="Order Master - Logo"></img>
                <p className="centered header">
                    {org.displayName || "Order Master"}
                </p>
                <p className="centered">
                    {org.address || ""}
                </p>
                <p className="centered header">
                    {receiptData.tableTitle || "Order Master"}
                </p>
                <table>
                    <thead>
                        <tr>
                            <th className="quantity">{t('quantityAbbreviation')}</th>
                            <th className="description">{t('description')}</th>
                            <th className="price">₺</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            foodItemArr && foodItemArr.map((item: any) => (
                                <tr>
                                <td className="quantity">{item.qty}</td>
                                <td className="description">{item.title}</td>
                                <td className="price">{item.price/100}₺</td>
                            </tr>
                            ))
                        }
                        <tr>
                            <td className="quantity"></td>
                            <td className="description">{t('total')}</td>
                            <td className="price">{totalPrice}₺</td>
                        </tr>
                    </tbody>
                </table>
                <p className="centered italic underline">{t('thanksForYourPurchase')}
                    <br></br>{window.location.hostname}</p>
            </div>
        </>
    );
};

import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";

export const KitchenOrder = ({
  order
}: {
  order: any;
}) => {
  const [orderName, setOrderName] = useState("");


  useEffect(() => {
    if(order) {
      const date = new Date(order.createdAt);
      const name = (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        setOrderName(order.tableTitle + ' - ' + name);
    }
  }, []);

  return (
    <motion.div
      className={`w-full flex flex-row justify-center rounded-md`}
    >
      <div className="w-full flex flex-col gap-2 px-4 py-1 items-center bg-white rounded-lg my-2">
        <p className="text-textColor font-bold text-xl text-center items-center justify-center p-2">{orderName}</p>
        {
            order.orderItems && order.orderItems.map((item: any)=>{
                return (
                    <div className="w-full flex px-4 justify-between bg-slate-200 rounded-lg">
                        <p className="text-textColor text-md text-center items-center justify-center">{item.menuItemTitle}</p>
                        <p className="text-textColor text-md text-center items-center justify-center">{item.qty}</p>
                    </div>
                )
            })
        }
        {
            order.orderNotes && order.orderNotes.map((item: any)=>{
                return (
                    <div className="w-full flex px-4 items-center justify-center">
                        <p className="text-textColor text-md text-center items-center justify-center">{item.message}</p>
                    </div>
                )
            })
        }
      </div>
    </motion.div>
  );
};

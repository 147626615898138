import { motion } from "framer-motion";
import { FaUserCog } from "react-icons/fa";
import { MdTableBar } from "react-icons/md";
import { AiOutlineUser, AiOutlineLike } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "../../../context/StateProvider";
import { useEffect, useState} from "react";
import { isAdmin, logout, ToggleAdminMode } from "../../../utils/functions";
import {useTranslation} from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";
import { TiDelete } from "react-icons/ti";
import { makeReadAllNotificaitons, deleteNotification } from "../../../utils/functions";
import Spinner from "../../Spinner";

const Notifications = (props: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [{ notifications }, dispatch]  = useStateValue();

  function renderSwitch(param: any) {
    switch(param) {
        case 'ORDER':
            return <AiOutlineLike className="flex font-bold text-3xl text-orange-600"/>;
        case 'TABLE':
            return <MdTableBar className="flex font-bold text-3xl text-orange-600"/>;
        case 'WAITER':
            return <AiOutlineUser className="flex font-bold text-3xl text-orange-600"/>;
      default:
        return <AiOutlineUser className="flex font-bold text-3xl text-orange-600"/>;
    }
  }

  function formatDate(date: any) {
    let dateObject = new Date(date);
    let dateString = dateObject.getFullYear() + '-' + dateObject.getMonth() + 1 + '-' + dateObject.getDate() + ' ' + (dateObject.getHours() < 10 ? '0' : '') + dateObject.getHours() + ':' + (dateObject.getMinutes() < 10 ? '0' : '') + dateObject.getMinutes();
    return dateString;
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.6 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.6 }}
      className={(props.isOpened ? "group-hover:flex" : "hidden") + " w-96 h-2/3  bg-gray-50 rounded-lg shadow-xl flex-col absolute right-0 top-1 overflow-y-hidden z-40"}
    >
      <div className="flex px-4 py-2 bg-slate-400 transition-all duration-100 capitalize justify-between rounded-lg ">
        <p className="flex text-white font-bold text-xl">{t('notifications')}</p>
        <IoCloseSharp className="flex font-bold text-3xl" onClick={()=>{props.setIsOpened(false); makeReadAllNotificaitons(notifications, dispatch);}}/>
      </div>
      <div className="flex flex-col w-full h-5/6 overflow-y-auto">
        {
            !notifications ? <Spinner color={"#e80013"} loading={notifications}/> : 
            notifications.map((notificaion: any) => (
                <div className={(notificaion.read ? "" : "bg-orange-100 ") + "cursor-pointer h-14 px-4 py-2 flex items-center gap-3 hover:bg-slate-100 transition-all duration-100 ease-in-out text-base text-textColor justify-between"}>
                    <div className="flex items-center justify-center">
                        {
                            renderSwitch(notificaion.type)
                        }
                        <div>
                            <p className="font-normal text-xs px-1"> {formatDate(notificaion.createdAt)} </p>
                            <p className="font-normal text-sm px-1"> {notificaion.message} </p>
                        </div>
                    </div>
                    <TiDelete className="flex font-bold text-red-600 text-3xl" onClick={() => deleteNotification(notificaion.id, notifications, dispatch)}/>
                </div>
            ))
        }
      </div>
    </motion.div>
  );
};

export default Notifications;

import { Cheff } from '../../components/Assets'

const AboutEn = () => {
  return (
    <div className='flex'>
      <div className="flex flex-col py-12 px-10 md:w-[70%]">
        <p className="text-3xl lg:text-3xl font-bold text-center tracking-wide flex flex-col text-headingColor">
            About Order Master
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          Welcome to Order Master, where innovation meets efficiency in the realm of restaurant and cafe management. We understand the dynamic challenges faced by the food industry, and we have crafted a cutting-edge solution to revolutionize the way businesses operate.
        </p>
        <br/>
        <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex flex-col text-headingColor">
          Our Mission
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          At Order Master, our mission is to empower restaurants and cafes with a seamless and intuitive platform that simplifies order management, enhances menu control, and optimizes overall operational efficiency. We strive to be the catalyst for the digital transformation of the hospitality industry.
        </p>
        <br/>
        <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex flex-col text-headingColor">
          What Sets Us Apart
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>QR Menu Revolution:</span> Say goodbye to traditional menus and hello to our QR menu system. We bring the menu to the palm of your customers' hands, enhancing their dining experience and allowing for easy updates and modifications.
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Effortless Order Tracking:</span> Our order tracking and management system ensures that every order is meticulously monitored, reducing errors and streamlining the entire ordering process. No more lost orders or mix-ups!  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Reduced Dependence on Waitstaff:</span> We believe in giving control back to the business owner. With our application, you can significantly reduce the reliance on waitstaff, improving operational efficiency and minimizing labor costs.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Dynamic Menu Control:</span> Take charge of your menu like never before. Easily update prices, activate or deactivate products, and keep your offerings in tune with market trends—all at the click of a button.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Instant Notifications and Swift Operations:</span> With Order Master, restaurant owners can instantly view orders and receive notifications when a customer sits at a table, places an order, or requests a waiter. Customers will break free from old-fashioned ordering practices.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Easy Installation and Usage:</span> Order Master empowers customers to completely liberate themselves from outdated ordering methods. They can easily perform installations on their own, ensuring a faster and smoother experience than ever before.  
        </p>
        <br/>
        <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex flex-col text-headingColor">
          Why Choose Order Master
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Innovation at its Core:</span> We're not just a management system; we're a game-changer. Embrace the future of restaurant and cafe operations with Order Master.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Customer-Centric Approach:</span> Your success is our success. We prioritize user-friendly designs and functionalities, ensuring that our platform works for you, not the other way around.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Reliability and Security:</span> Trust is paramount in the digital world. Rest assured, Order Master is built with state-of-the-art security features to safeguard your data and operations.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-center text-textColor">
          Join us on the journey of transforming the dining experience. At Order Master, we are not just redefining systems; we are creating opportunities for growth, efficiency, and success.
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-center text-textColor">
          Ready to take your restaurant or cafe to the next level? Explore Order Master today!
        </p>
      </div>
      <div className="flex flex-col items-center justify-center pt-24 w-[30%] px-4 md:block hidden">
        <img src={Cheff} alt="Order Master - About Us Page Photo" title="Order Master - About Us Page Photo" width={50} height={50} className='w-full' loading="lazy"/>
      </div>
    </div>
  );
}

export default AboutEn;
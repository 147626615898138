// import React from 'react'
import { Link } from "react-router-dom";
import { MdShoppingBasket } from "react-icons/md";
import { motion } from "framer-motion";
import { useStateValue } from "../../context/StateProvider";
import React from "react"
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { Alert } from '../Admin/Alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { createNotificationRequest } from "../../utils/functions";
import {LuConciergeBell} from "react-icons/lu";
import {buildLocalizedPath} from "../../i18n/i18n";

const Navigations = ({ direction }: { direction?: string }) => {
  const { t, i18n } = useTranslation();
  const queryParameters = new URLSearchParams(window.location.search);
  const [{ showContactForm, cartItems, org, table, socket }, dispatch] = useStateValue();
  const [isFromPublic, setIsFromPublic] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  function checkIsExpired() {
    const today = new Date().getTime();
    const expireDate = new Date(org.settings.subscriptionPlan.expiresAt).getTime();
    if (expireDate < today) {
      setIsExpired(true);
    }
  }

  useEffect(() => {
    if (queryParameters.get('oid')) {
      setIsFromPublic(true);
      if (org) {
        checkIsExpired();
      }
    }
  }, []);

  const handleToggleCart = () => {
    dispatch({
      type: "TOGGLE_CART",
      showCart: true,
    });
  };

  function callWaiter() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Alert onClose={onClose} message={t('confirmationCallWaiter')} onConfirm={()=>{createNotificationRequest(table.title, socket, 'WAITER')}}/>
        );
      }
    });
  }

  const handleToggleContact = () => {
    dispatch({
      type: "TOGGLE_CONTACT_FORM",
      showContactForm: !showContactForm,
    });
  }
  return (
    <div className="flex items-center gap-8">
      {isFromPublic ? '' :
          <motion.ul
              initial={{opacity: 0, x: 200}}
              animate={{opacity: 1, x: 0}}
              exit={{opacity: 0, x: 200}}
              className={`flex items-center gap-8 ${direction && direction}`}
          >
            <motion.li
                whileHover={{scale: 1.1}}
                className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
            >
              <Link to={buildLocalizedPath('/')}>{t('home')}</Link>
            </motion.li>
            <motion.li
                whileHover={{scale: 1.1}}
                className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
            >
              <Link to={buildLocalizedPath('/services')}>{t('services')}</Link>
            </motion.li>
            <motion.li
                whileHover={{scale: 1.1}}
                className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
            >
              <Link to={buildLocalizedPath('/about')}>{t('aboutUs')}</Link>
            </motion.li>
            <motion.li
                whileHover={{scale: 1.1}}
                className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
                onClick={handleToggleContact}
            >
              {t('contactUs')}
            </motion.li>
            <motion.li
                whileHover={{scale: 1.1}}
                className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
            >
              <Link to={buildLocalizedPath('/pricing')}>{t('pricing')}</Link>
            </motion.li>
          </motion.ul>}
      {
          isFromPublic && !isExpired && table &&
          <>
            <motion.div
                whileTap={{scale: 0.9}}
                whileHover={{scale: 1.1}}
                initial={{opacity: 0, x: 200}}
                animate={{opacity: 1, x: 0}}
                exit={{opacity: 0, x: 200}}
                className="relative flex items-center justify-center text-textColor"
          onClick={callWaiter}
        >
          <LuConciergeBell className="text-4xl cursor-pointer" />
        </motion.div>
          {
            org && org.settings.feature.orderMode &&
            <motion.div
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1 }}
              className="relative flex items-center justify-center text-textColor"
              onClick={handleToggleCart}
            >
              <MdShoppingBasket className="text-4xl cursor-pointer" />
              {cartItems && (
                <div className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-cartNumBg flex items-center justify-center cursor-pointer">
                  <p className="text-sm text-white font-semibold">
                    {cartItems.length}
                  </p>
                </div>
              )}
            </motion.div>
          }
        </>
      }
    </div>
  );
};

export default Navigations;

import { AiFillDashboard, AiOutlineLike, AiOutlineTable, AiOutlineDesktop, AiOutlinePlusSquare } from "react-icons/ai";
import {
  MdAddModerator,
  MdList,
  MdRestaurantMenu,
  MdDensityMedium
} from "react-icons/md";
import { motion } from "framer-motion";
import {FaCogs, FaHandsHelping} from "react-icons/fa";
import AddFood from "./AddFood";
import Dashboard from "./Dashboard";
import Tables from "./Tables";
import Menu from "./Menu";
import AddTable from "./AddTable";
import OrderList from "./OrderList";
import ActiveTables from "./ActiveTables";
import Categories from "./Categories";
import { useStateValue } from "../../context/StateProvider";
import {useTranslation} from "react-i18next";
import { useState, useEffect } from 'react';
import Settings from "./Settings";
import Help from "./Help";
import Kitchen from "./Kitchen";

const SidenavMenu = ({
  activePage,
  setActivePage,
  setPageContent,
}: {
  activePage: string;
  setActivePage: any;
  setPageContent: any;
}) => {
  const { t, i18n } = useTranslation();
  const [{ org }, dispatch] = useStateValue();
  useEffect(() => {
    if(org && !org.settings.feature.orderMode && activePage === t('analytic')) {
      setActivePage(t('addProduct'));
      setPageContent(<AddFood/>)
    }
  },[org]);

  return (
    org &&
      <motion.nav
          initial={{opacity: 0, x: 200}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 200}}
          className="space-y-2 w-full "
      >
        {
          org.settings.feature.orderMode &&
          <>
            <NavItem
              activePage={activePage}
              svgIcon={<AiFillDashboard/>}
              title={t('analytic')}
              setActivePage={setActivePage}
              setPageContent={setPageContent}
              pageContent={<Dashboard/>}
            />
            <NavItem
                activePage={activePage}
                svgIcon={<AiOutlineLike/>}
                title={t('activeOrders')}
                setActivePage={setActivePage}
                setPageContent={setPageContent}
                pageContent={<OrderList tableId="" handler=""/>}
            />
            <NavItem
                activePage={activePage}
                svgIcon={<AiOutlineDesktop/>}
                title={t('activeTables')}
                setActivePage={setActivePage}
                setPageContent={setPageContent}
                pageContent={<ActiveTables/>}
            />
            <NavItem
                activePage={activePage}
                svgIcon={<MdRestaurantMenu/>}
                title={t('kitchen')}
                setActivePage={setActivePage}
                setPageContent={setPageContent}
                pageContent={<Kitchen/>}
            />
          </>
        }
        <NavItem
            activePage={activePage}
            svgIcon={<MdAddModerator/>}
            title={t('addProduct')}
            setActivePage={setActivePage}
            setPageContent={setPageContent}
            pageContent={<AddFood/>}
        />
        <NavItem
            activePage={activePage}
            svgIcon={<MdList/>}
            title={t('menu')}
            setActivePage={setActivePage}
            setPageContent={setPageContent}
            pageContent={<Menu/>}
        />
        <NavItem
            activePage={activePage}
            svgIcon={<MdDensityMedium/>}
            title={t('categories')}
            setActivePage={setActivePage}
            setPageContent={setPageContent}
            pageContent={<Categories/>}
        />
        <NavItem
            activePage={activePage}
            svgIcon={<AiOutlinePlusSquare/>}
            title={t('addTable')}
            setActivePage={setActivePage}
            setPageContent={setPageContent}
            pageContent={<AddTable/>}
        />
        <NavItem
            activePage={activePage}
            svgIcon={<AiOutlineTable/>}
            title={t('tables')}
            setActivePage={setActivePage}
            setPageContent={setPageContent}
            pageContent={<Tables/>}
        />
        <NavItem
            activePage={activePage}
            svgIcon={<FaCogs/>}
            title={t('settings')}
            setActivePage={setActivePage}
            setPageContent={setPageContent}
            pageContent={<Settings/>}
        />
        <NavItem
            activePage={activePage}
            svgIcon={<FaHandsHelping/>}
            title={t('help')}
            setActivePage={setActivePage}
            setPageContent={setPageContent}
            pageContent={<Help/>}
        />
      </motion.nav>
  );
}

const NavItem = ({
  activePage,
  svgIcon,
  title,
  setActivePage,
  setPageContent,
  pageContent,
}: {
  activePage: string;
  setActivePage: any;
  svgIcon: any;
  title: string;
  setPageContent: any;
  pageContent: JSX.Element;
}) => {
  const { t, i18n } = useTranslation();
  const handleClick = () => {
    setActivePage(title);
    setPageContent(pageContent);
  };
  const [{tables, foodItems, sessions}, dispatch] = useStateValue()
  const [activeOrders, setActiveOrders] = useState(0);

  function qtyOfActiveOrders() {
    let total = 0;
    sessions.map((session: any)=>{
      session.orders.map((item: any) => {
        if (item.status === 'PENDING') {
          total++;
        }
      })
    })
    return total;
  }

  useEffect(() => {
    if (sessions) {
      setActiveOrders(qtyOfActiveOrders())
    }
  }, [sessions]);

  return (
    <motion.div
      whileTap={{ scale: 1.1 }}
      onClick={handleClick}
      className={`flex items-center no-underline text-orange-50 hover:text-orange-100 p-3 rounded-md cursor-pointer hover:bg-orange-700 ${
        activePage === title ? "bg-orange-700" : ""
      }`}
    >
      <p className="font-bold text-xl">{svgIcon}</p>
      <div className="flex items-center justify-center gap-10 font-bold pl-3">{title}
        {
          (title === t('menu') || title === t('tables')) && (
            <div className=" w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center cursor-pointer">
            <p className="text-sm text-white font-semibold">
              {
                foodItems && tables && (title === t('menu') ? foodItems.length : tables.length)
              }
            </p>
          </div>
          )
        }
        {
          (title === t('activeOrders') || title === t('activeTables')) && (
            <div className=" w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center cursor-pointer">
            <p className="text-sm text-white font-semibold">
              {
                sessions && (title === t('activeOrders') ? activeOrders : sessions.length)
              }
            </p>
          </div>
          )
        }
      </div>
    </motion.div>
  );
};
export default SidenavMenu;

import { motion } from "framer-motion";
import { MdLogout, MdOutlineAdminPanelSettings } from "react-icons/md";
import { RiAdminLine } from "react-icons/ri";
import { AiOutlineUser} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "../../context/StateProvider";
import { isAdmin, logout, ToggleAdminMode, isSuperAdmin } from "../../utils/functions";
import {useTranslation} from "react-i18next";

const DropDown = ({ user }: { user: any;}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [{}, dispatch]  = useStateValue();

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.6 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.6 }}
      className="hidden group-hover:flex w-54  bg-gray-50 rounded-lg shadow-xl  flex-col absolute right-0 top-16"
    >
      <p className="px-10 py-2 flex items-center gap-3 bg-slate-100 transition-all duration-100 capitalize ease-in-out text-base text-headingColor">
        {user?.displayName || user?.email}
      </p>
      {isSuperAdmin(user) && (
        <Link
        className="cursor-pointer px-10 py-2 flex items-center gap-3 hover:bg-slate-100 transition-all duration-100 ease-in-out text-base text-textColor"
        to={"/superadmin"}
        onClick={() => ToggleAdminMode(dispatch, true)}
        >
          {t('superadministrator')}
          <MdOutlineAdminPanelSettings />
        </Link>
      )}
      {isAdmin(user) && (
        <Link
        className="cursor-pointer px-10 py-2 flex items-center gap-3 hover:bg-slate-100 transition-all duration-100 ease-in-out text-base text-textColor"
        to={"/admin"}
        onClick={() => ToggleAdminMode(dispatch, true)}
        >
          {t('administrator')}
          <RiAdminLine />
        </Link>
      )}
      <Link
        className="cursor-pointer px-10 py-2 flex items-center gap-3 hover:bg-slate-100 transition-all duration-100 ease-in-out text-base text-textColor"
        to={"/profile"}
        >
          {t('profile')}
          <AiOutlineUser />
      </Link>
      <p
        className="cursor-pointer px-10 py-2 flex items-center gap-3 hover:bg-slate-100 transition-all duration-100 ease-in-out text-base text-textColor"
        onClick={() => logout(user, dispatch, navigate)}
      >
        {t('logout')}
        <MdLogout />
      </p>
    </motion.div>
  );
};

export default DropDown;

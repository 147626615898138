import { AssetUploader, Loader } from "../..";
import { BiCategory, BiFoodMenu } from "react-icons/bi";
import {
  MdDeleteOutline,
  MdOutlineDataSaverOn,
  MdOutlineFastfood
} from "react-icons/md";
import {
  firebaseRemoveUploadedImage,
} from "../../../Firebase";

import CategoriesSelector from "./CategoriesSelector";
import { GiTakeMyMoney } from "react-icons/gi";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import { fetchFoodData } from "../../../utils/functions";
import { Money } from "../../../types";
import { addMenuItem } from "../../../db";
import {useTranslation} from "react-i18next";

const AddFood = () => {
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [priceAmount, setPriceAmount] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [loaderMessage, setLoadermessage] = useState("");
  const [{ categories }, dispatch] = useStateValue();

  const deleteImage = () => {
    setLoadermessage(t('removingPhoto'));
    firebaseRemoveUploadedImage(image, setImage, setLoading);
  };
  const saveItem = () => {
    setLoadermessage(`${t('savePending')} ${title}.`);
    setLoading(true);
    try {

      if (!title || !priceAmount || !image || !category) {
        if (!category) {
          toast.error(t('productCategoryMissing'));
          setLoading(false);
        } else {
          toast.error(t('missingRequiredFieldError'));
          setLoading(false);
        }
        return;
      } else {
        const data = {
          title: title,
          category: category,
          description: description,
          price: new Money(Number(priceAmount) * 100),
          imageUrl: image
        };
        toast
          .promise(addMenuItem(data), {
            pending: t('savePending'),
            success: t('saveSuccess'),
            error: t('saveError'),
          })
          .then(() => {
            clearForm();
            setLoading(false);
            fetchFoodData(dispatch);
          })
          .catch((error) => {
            console.log(error);
          });
        setLoadermessage("");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(t('saveError'));
    }
  };
  const clearForm = () => {
    setTitle("");
    setPriceAmount("");
    setImage(null);
    // setCategory("");
    setDescription("");
  };

  const validateNumber = (value: any) => {
    if (isNaN(value)) {
      toast.error(t('fieldValidationError'), { toastId: 123 });
      return "";
    }
    return value;
  };



  return (
    <div className="w-full h-fullflex items-center justify-center">
      <div className="border w-full  flex border-gray-300 items-center rounded-lg p-4 flex-col justify-center gap-4  ">
        <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
          <MdOutlineFastfood className="text-xl text-gray-600" />
          <input
            type="text"
            required
            placeholder={t('enterProductName')}
            autoFocus
            className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-3">
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <BiCategory className="text-xl text-gray-600" />
            <CategoriesSelector
              categories={categories}
              action={setCategory}
              selected={category}
            />
          </div>
        </div>
        <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-[225px]  md:h-[420px] round-lg">
          {loading ? (
            <Loader progress={loaderMessage} />
          ) : (
            <>
              {image ? (
                <>
                  <div className="relative h-full">
                    <img
                      src={image}
                      alt="uploaded food"
                      className="w-full h-full object-cover"
                      loading="lazy"
                      title="Order Master - Logo"
                    />
                    <motion.button
                      whileTap={{ scale: 1.1 }}
                      whileHover={{ scale: 1.2 }}
                      title={t('removePhoto')}
                      className="absolute bottom-3 right-3 rounded-full p-2 md:p-5 bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out"
                      onClick={() => deleteImage()}
                    >
                      <MdDeleteOutline className="text-white" />
                    </motion.button>
                  </div>
                </>
              ) : (
                <AssetUploader
                  action={setImage}
                  progressHandler={setLoadermessage}
                  promise={setLoading}
                />
              )}
            </>
          )}
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-3">
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <GiTakeMyMoney className="text-gray-600 text-2xl" />
            <input
              type="number"
              min="0.00"
              max="10000.00"
              step="0.1"
              required
              placeholder={t('enterPrice')}
              autoFocus
              className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
              value={priceAmount}
              onChange={(e) => setPriceAmount(validateNumber(e.target.value))}
            />
          </div>
        </div>
        <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
          <BiFoodMenu className="text-xl text-gray-600" />
          <input
            type="text"
            required
            placeholder={t('enterDescription')}
            autoFocus
            className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="w-full flex items-center justify-center">
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="ml-0 flex justify-center items-center gap-2 flex-row-reverse md:ml-auto w-full md:w-auto border-none outline-none rounded bg-orange-500 px-12 py-2 text-lg text-white"
            onClick={() => saveItem()}
          >
            <MdOutlineDataSaverOn /> {t('save')}
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default AddFood;

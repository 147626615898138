import React, {useState} from "react";
import {EmptyCartImg} from '../Assets'
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import {FaWhatsappSquare} from "react-icons/fa";
import {submitContactUsForm} from "../../db";

const Form = () => {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [subject, setSubject] = useState('')

    const submitForm = () => {
        try {
              if (!name || !email || !subject  || !message) {
                toast.error(t('missingRequiredFieldError'));
                return;
            }
            if (!/\S+@\S+\.\S+/.test(email)) {
                toast.error(t('fieldValidationError'));
                return;
            }
            const data = {
                name: name,
                email: email,
                subject: subject,
                message: message,
            };
            toast
                .promise(submitContactUsForm(data), {
                    pending: t('savePending'),
                    success: t('contactSaveSuccess'),
                    error: t('saveError'),
                })
                .then(() => {
                    clearForm();
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            toast.error(t('saveError'));
        }
    };

    const clearForm = () => {
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
    };

  return (
    <div className="h-full w-full flex items-center flex-col justify-center px-4 pt-10 bg-primary overflow-y-scroll scrollbar-hidden">
      <img src={EmptyCartImg} alt="not found" width={50} height={50} className="w-[30%] h-[30%]" loading="lazy" title="Order Master - Empty Cart"/>
      <p className="text-textColor font-semi-bold text-sm items-center justify-center text-center p-3">{t('contactUsDescription')}</p>
      <div className="mb-1 w-full flex itemx-center justify-center gap-y-3 flex-col">
      <div className="mb-1">
          <input
            type="text"
            className="form-control block w-full px-4 py-2  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-orange-600 focus:outline-none"
            placeholder={t('nameSurname')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-1">
          <input
            type="text"
            className="form-control block w-full px-4 py-2  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-orange-600 focus:outline-none"
            placeholder={t('email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-1">
          <input
            type="text"
            className="form-control block w-full px-4 py-2  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-orange-600 focus:outline-none"
            placeholder={t('subject')}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="mb-1">
          <textarea
            className="form-control block w-full min-h-[25vh] px-4 py-2  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-orange-600 focus:outline-none"
            placeholder={t('message')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="text-white bg-orange-600 hover:bg-orange-700 w-full focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-orange-600 dark:hover:bg-orange-700 focus:outline-none dark:focus:ring-orange-800 block"
            onClick={submitForm}
        >
           {t('sendMessage')}
        </button>
      </div>
      <div className="flex flex-col items-center justify-center mb-8">
        <p className="mb-2 cursor-pointer text-sm text-gray-500 dark:text-gray-400">
          <a href={"mailto:" + t('businessEmail')} className="hover:underline">
          {t('businessEmail')}
          </a>
        </p>
        <p className="text-sm cursor-pointer text-gray-500 dark:text-gray-400">
          <a href="tel:+905388977052" className="hover:underline">
          {t('businessPhone')}
          </a>
        </p>
        <p className="text-sm cursor-pointer text-gray-500 dark:text-gray-400 mt-1">
          <a href={"https://wa.me/" + t('whatsappPhone') + "/?text=" + t('whatsappMessage')} className="hover:underline">
            <FaWhatsappSquare className="text-textColor text-2xl " />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Form;

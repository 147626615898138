import { Cheff } from '../../components/Assets'

const AboutTr = () => {
  return (
    <div className='flex'>
      <div className="flex flex-col py-12 px-10 md:w-[70%]">
        <p className="text-3xl lg:text-3xl font-bold text-center tracking-wide flex flex-col text-headingColor">
            Order Master Hakkında
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
            Order Master ailesine hoş geldiniz. Burada, restoran ve kafe yönetiminde yenilik ile verimliliğin buluştuğu bir platformu sunuyoruz. Gıda sektörünün karşılaştığı dinamik zorlukları anlıyoruz ve işletmelerin işleyişini devrim niteliğinde bir çözümle dönüştürdük.
        </p>
        <br/>
        <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex flex-col text-headingColor">
            Misyonumuz
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
            Order Master olarak misyonumuz, restoranları ve kafeleri sipariş yönetimini basitleştiren, menü kontrolünü geliştiren ve genel işletme verimliliğini optimize eden sezgisel bir platform ile donatmaktır. Misyonumuz, konukseverlik sektörünün dijital dönüşümünün katalizörü olmaktır.
        </p>
        <br/>
        <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex flex-col text-headingColor">
            Bizi Diğerlerinden Ayıranlar
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>QR Menü Devrimi:</span> Geleneksel menülere veda edin, QR menü sistemimize merhaba deyin. Menüyü müşterilerinizin avuçlarına getiriyoruz, deneyimlerini arttırıyor ve menülerinizi kolayca güncellemenizi sağlıyoruz.
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Zahmetsiz Sipariş Takibi:</span> Sipariş takip ve yönetim sistemimiz, her siparişin titizlikle izlendiğinden emin olur, hataları azaltır ve tüm sipariş sürecini optimize eder. Artık kaybolan siparişler veya karışıklıklar yok!  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Garsonlara Duyulan Bağımlılığı Azaltma:</span> İşletme sahibine kontrolü geri verme konusuna inanıyoruz. Uygulamamızla garsonlara olan bağımlılığı önemli ölçüde azaltabilir, operasyonel verimliliği artırabilir ve işgücü maliyetlerini minimize edebilirsiniz.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Dinamik Menü Kontrolü:</span> Menünüzü hiç olmadığı kadar kontrol altına alın. Fiyatları kolayca güncelleyin, ürünleri etkin veya etkisiz hale getirin ve tekliflerinizi piyasa trendleriyle uyumlu tutun - hepsi bir tık uzakta.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Anlık Bildirimler ve Hızlı İşlem:</span> Order Master ile restoran sahibi siparişleri anında görebilir, müşteriler masaya oturduğunda sipariş verdiğinde veya garson çağırmak istediğinde anında bir bildirim alabilir. Müşteriler, eski tarz sipariş uygulamalarından tamamen kurtulacak ve kurulumu kendileri kolayca gerçekleştirebilecekler.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Kolay Kurulum ve Kullanım:</span> Order Master, müşterilere eski tarz sipariş uygulamalarından tamamen kurtulma imkanı sunar. Kendi kurulumlarını kolayca yapabilirler, böylece daha önce hiç olmadığı kadar hızlı ve sorunsuz bir deneyim yaşarlar.  
        </p>
        <br/>
        <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex flex-col text-headingColor">
            Neden Order Master Seçilmeli?
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Çekirdekte İnovasyon:</span> Sadece bir yönetim sistemi değil, bir oyun değiştiriciyiz. Order Master ile restoran ve kafe işleyişini geleceğe taşıyın.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Müşteri Odaklı Yaklaşım:</span> Başarınız bizim başarımızdır. Kullanıcı dostu tasarımlar ve işlevselliklere öncelik veriyor, platformumuzun size hizmet ettiğinden emin oluyoruz, tam tersi değil.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-textColor">
          <span className='font-bold'>Güvenilirlik ve Güvenlik:</span> Dijital dünyada güven çok önemlidir. Endişelenmeyin, Order Master verilerinizi ve işlemlerinizi korumak için son teknoloji güvenlik özellikleri ile donatılmıştır.  
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-center text-textColor">
            Bize katılın ve yeme içme deneyimini dönüştürme yolculuğuna ortak olun. Order Master'da sadece sistemleri yeniden tanımlamıyoruz; büyüme, verimlilik ve başarı için fırsatlar yaratıyoruz.
        </p>
        <br/>
        <p className="text-md lg:text-md flex flex-col text-center text-textColor">
            Restoranınızı veya kafenizi bir üst seviyeye taşımaya hazır mısınız? Hemen Order Master dünyasını keşfedin!
        </p>
      </div>
      <div className="flex flex-col items-center justify-center pt-24 w-[30%] px-4 md:block hidden">
        <img src={Cheff} alt="Order Master - About Us Page Photo" title="Order Master - About Us Page Photo" width={50} height={50} className='w-full' loading="lazy"/>
      </div>
    </div>
  );
}

export default AboutTr;
import { BsQrCode } from "react-icons/bs";
import { FaPeopleLine } from "react-icons/fa6";
import { IoNotificationsSharp } from "react-icons/io5";
import { MdOutlineRestaurantMenu, MdSecurity, MdSupportAgent } from "react-icons/md";
import { BsPersonArmsUp } from "react-icons/bs";



const ServicesEn = () => {
  return (
    <div className='flex items-center justify-center'>
      <div className="flex flex-col py-12 w-full px-16">
        <p className="text-3xl lg:text-3xl font-bold text-center tracking-wide flex flex-col text-headingColor">
            Our Services
        </p>
        <br/>
        <p className="text-md lg:text-md text-center flex flex-col text-textColor">
            At Order Master, we take pride in offering a comprehensive suite of services designed to elevate your restaurant or cafe management to new heights. Our innovative solutions are tailored to meet the unique needs of the hospitality industry, providing you with the tools to streamline operations and enhance customer experiences.
        </p>
        <br/>
        <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-center gap-3 overflow-x-hidden flex-wrap'>
            <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
                <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
                    <BsQrCode className='text-3xl mx-2' /> QR Menu Integration
                </p>
                <br/>
                <p className="text-md lg:text-md flex flex-col text-textColor">
                At Order Master, our mission is to empower restaurants and cafes with a seamless and intuitive platform that simplifies order management, enhances menu control, and optimizes overall operational efficiency. We strive to be the catalyst for the digital transformation of the hospitality industry.
                </p>
                <br/>
            </div>
            <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
                <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
                    <FaPeopleLine className='text-3xl mx-2' /> Effortless Order Management
                </p>
                <br/>
                <p className="text-md lg:text-md flex flex-col text-textColor">
                    Experience a new level of efficiency with our Effortless Order Management system. Monitor incoming orders in real-time, reduce errors, and enhance the overall speed of service. Take control of your kitchen and ensure every order is delivered promptly and accurately.
                </p>
                <br/>
            </div>
            <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
                <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
                    <IoNotificationsSharp className='text-3xl mx-2' /> Instant Notifications
                </p>
                <br/>
                <p className="text-md lg:text-md flex flex-col text-textColor">
                    Keep your restaurant owners in the loop with our Instant Notifications feature. Receive alerts when customers are seated, place orders, or require assistance. Enhance communication between staff and management, ensuring a smooth and responsive dining environment.
                </p>
                <br/>
            </div>
            <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
                <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
                    <MdOutlineRestaurantMenu className='text-3xl mx-2' /> Dynamic Menu Control
                </p>
                <br/>
                <p className="text-md lg:text-md flex flex-col text-textColor">
                    Take charge of your menu with our Dynamic Menu Control. Easily update prices, activate or deactivate items, and adapt to changing trends in real-time. This feature provides flexibility and empowers you to keep your offerings in sync with market demands.
                </p>
                <br/>
            </div>
            <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
                <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
                    <BsPersonArmsUp className='text-3xl mx-2' /> Customer Self-Service Installation
                </p>
                <br/>
                <p className="text-md lg:text-md flex flex-col text-textColor">
                    Empower your customers with our Customer Self-Service Installation. Say goodbye to the hassles of traditional installations. Allow your patrons to set up the application effortlessly, enabling them to enjoy a modern and convenient dining experience.
                </p>
                <br/>
            </div>
            <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
                <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
                    <MdSecurity className='text-3xl mx-2' /> Reliable Order Tracking
                </p>
                <br/>
                <p className="text-md lg:text-md flex flex-col text-textColor">
                    Our Reliable Order Tracking system ensures that no order goes unnoticed. Track each order from placement to delivery, minimizing errors and enhancing overall customer satisfaction. Say farewell to lost orders and welcome a new era of precision.
                </p>
                <br/>
            </div>
            <div></div>
            <div className='flex flex-col h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg '>
                <p className="text-xl lg:text-xl font-bold text-center tracking-wide flex items-center justify-center text-headingColor">
                    <MdSupportAgent className='text-3xl mx-2' /> Consultation and Support
                </p>
                <br/>
                <p className="text-md lg:text-md flex flex-col text-textColor">
                    We don't just provide services; we build partnerships. Our Consultation and Support services are here to guide you through the implementation process and beyond. Count on us for expert advice, training, and ongoing support to ensure your success with Order Master.
                </p>
                <br/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesEn;
import {CgDetailsMore} from "react-icons/cg";
import { motion } from "framer-motion";
import {AiOutlineClose, AiOutlineCheck} from "react-icons/ai";
import {useStateValue} from "../../../context/StateProvider";
import { BiMinus, BiPlus } from "react-icons/bi";
import { getFoodyById, updateOrderItemQty, deleteOrderItem, sendMessage} from "../../../utils/functions";
import React, { useState, useEffect } from "react";
import { Alert } from '../../Admin/Alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useTranslation} from "react-i18next"; // Import css

export const OrderItem = ({
  orderItem,
  orderId
}: {
  orderItem: any;
  orderId: string;
}) => {
  const { t, i18n } = useTranslation();
  const [{ foodItems, sessions, socket }, dispatch] = useStateValue();
  const foodItem: any = getFoodyById(foodItems, orderItem.menuItemId);

  function remove() {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert onClose={onClose} message="Do you want to delete this order item?" onConfirm={()=>{deleteOrderItem(orderId, orderItem.id, sessions, dispatch, socket);}}/>
          );
        }
    });
  };

  return (
    <motion.div
      className={`w-full flex flex-row justify-center gap-3 rounded-md hover:bg-orange-200`}
    >
      <div className="w-full flex justify-left flex-row gap-2 px-3 py-1 items-center">
        <div className="w-full group flex items-center gap-2  cursor-pointer justify-left">
          <p className="text-textColor font-semi-bold text-lg items-center justify-center">{foodItem.title}</p>
        </div>
        <div className="w-full group flex items-center gap-2  cursor-pointer justify-end">
          <motion.div
            className=""
            whileTap={{ scale: 0.75 }}
            onClick={orderItem.qty > 1 ? () => {updateOrderItemQty(sessions, orderId, orderItem.id, dispatch, -1, socket);}: () => {}}
          >
            <BiMinus className="text-black-50" />
          </motion.div>
          <p className="text-sm text-gray-50 w-5 h-5 rounded-sm bg-cartBg flex items-center justify-center cursor-default">
            {orderItem.qty}
          </p>
          <motion.div
            className=""
            whileTap={{ scale: 0.75 }}
            onClick={() => {updateOrderItemQty(sessions, orderId, orderItem.id, dispatch, 1, socket);}}
          >
            <BiPlus className="text-black-50" />
          </motion.div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-end gap-2 px-3 py-1">
        <motion.div
            whileTap={{scale: 1.1}}
            whileHover={{scale: 1.2}}
            className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer"
            title={t('delete')}
            onClick={remove}
        >
            <AiOutlineClose className="text-white md:text-xl"/>
        </motion.div>
      </div>

    </motion.div>
  );
};

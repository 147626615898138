import React from "react";
import {Link} from "react-router-dom"
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import {useStateValue} from "../../context/StateProvider";
import {MdOutlineRestaurantMenu} from "react-icons/md";
import {buildLocalizedPath} from "../../i18n/i18n";


const Left = () => {
    const {t, i18n} = useTranslation();
    const [{user}, dispatch] = useStateValue();

    return (
        <div className="py-2 flex-1 flex flex-col items-start justify-center gap-3">
            <p className="text-[2rem] lg:text-[4rem] font-bold tracking-wide flex flex-col text-headingColor">
                <span className="text-orange-600 text-[2.5rem] lg:text-[4.6rem]">ORDER MASTER</span>
                <h1>{t('homepageTitle')}</h1>
            </p>
            <p className="text-xl text-textColor text-left md:text-left md:w-[80%]">
                {t('homepageBody')}
            </p>
            {
                !user &&
                <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center p-2">
                    <Link to={buildLocalizedPath("/register")} className="items-center justify-center m-2">
                        <motion.button
                            whileHover={{scale: 1.1}}
                            className="text-white bg-gradient-to-br from-orange-400 to-orange-500 w-56 md:w-56 px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out duration-100"
                        >
                            {t('homepageRegister')}
                        </motion.button>
                    </Link>
                    <a href="https://ordermaster.net/menu?id=1c748211-c221-4958-9ed4-95ed6e955fca&oid=aefa7bad-70ab-4843-ad55-c493463195a3"
                       target="_blank" className="items-center justify-center m-2">
                        <motion.button
                            whileHover={{scale: 1.1}}
                            className="text-white bg-gradient-to-br from-red-700 to-red-500 w-56 md:w-56 px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out duration-100"
                        >
                            <p className="flex items-center justify-center"><MdOutlineRestaurantMenu
                                className="text-white text-xl mr-2"/> {t('goToSampleMenu')}</p>
                        </motion.button>
                    </a>
                </div>
            }
        </div>
    );
};

export default Left;

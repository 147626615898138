import React from "react";
import {useStateValue} from "../../context/StateProvider";
import {motion} from "framer-motion";
import {deleteTable} from "../../utils/functions";
import {MdDeleteForever, MdOutlineRestaurantMenu, MdLocalPrintshop} from "react-icons/md";
import {BiEditAlt} from "react-icons/bi";
import {Table} from "../../types";
import UpdateTable from "../../components/Admin/UpdateTable"
import Modal from 'react-modal';
import {useTranslation} from "react-i18next";


Modal.setAppElement('#root');

const Action = ({table, admin, isFromAdmin, handlePrint}: { table: Table; admin?: boolean; isFromAdmin?: boolean; handlePrint: any; }) => {
    const { t, i18n } = useTranslation();
    const [{ tables }, dispatch] = useStateValue();

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className="flex flex-col gap-2">
            <>

                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    contentLabel="Update Table Modal"
                >
                    <div style={{display: 'flex'}}>
                        <div className="flex justify-between w-full text-xl font-bold text-gray-600 pb-2 ">
                            {t('updateTable')}
                        </div>
                        <div style={{marginLeft: "auto"}}>
                            <motion.div
                                whileTap={{scale: 0.9}}
                                initial={{opacity: 0, x: 200}}
                                animate={{opacity: 1, x: 0}}
                                exit={{opacity: 0, x: 200}}
                                className="text-textColor"
                                onClick={closeModal}
                            >
                                <MdOutlineRestaurantMenu className="text-headingColor text-2xl"/>
                            </motion.div>
                        </div>
                    </div>
                    <UpdateTable id={table.id} closeModal={closeModal}/>
                </Modal>
                <motion.div
                    whileTap={{scale: 1.1}}
                    whileHover={{scale: 1.2}}
                    className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-green-600 flex items-center justify-center cursor-pointer"
                    title={t('edit')}
                    onClick={openModal}
                >
                    <BiEditAlt className="text-white md:text-xl"/>
                </motion.div>

                <motion.div
                    whileTap={{scale: 1.1}}
                    whileHover={{scale: 1.2}}
                    className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer"
                    onClick={() => deleteTable(table, tables, dispatch)}
                    title={t('delete')}
                >
                    <MdDeleteForever className="text-white md:text-xl"/>
                </motion.div>

                <motion.div
                    whileTap={{scale: 1.1}}
                    whileHover={{scale: 1.2}}
                    className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-orange-500 flex items-center justify-center cursor-pointer"
                    onClick={handlePrint}
                    title={t('print')}
                >
                    <MdLocalPrintshop className="text-white md:text-xl"/>
                </motion.div>
            </>
        </div>
    );
};

export default Action;

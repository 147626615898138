import {
  BsInstagram,
  BsTiktok,
  BsTwitter,
} from "react-icons/bs";

import { Link } from "react-router-dom";
import { Logo } from "../Assets";
import React from "react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {buildLocalizedPath} from "../../i18n/i18n";

const Footer = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const [isFromPublic, setIsFromPublic] = useState(false)
  useEffect(() => {
    if (queryParameters.get('oid')) {
      setIsFromPublic(true);
    }
  }, []);
  return (
    <footer className="p-4 bg-primary sm:p-6 w-full">
      <div className="flex justify-center md:justify-start items-center">
      {isFromPublic ?
          <motion.div  whileHover={{ rotate: [0, -10, 10, -10, 0] }} className="mb-3 md:mb-0">
            <motion.img
              whileHover={{ rotate: [0, -10, 10, -10, 0] }}
              src={Logo}
              className="w-10 md:w-36 object-contain"
              alt="Order Master - Organization Logo"
              width={50}
              height={50}
              loading="lazy"
              title="Order Master - Organization Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-headingColor">
              Order Master
            </span>
        </motion.div>
        :
        <motion.div  whileHover={{ rotate: [0, -10, 10, -10, 0] }} className="mb-3 md:mb-0">
          <Link to={buildLocalizedPath("/")} className="flex gap-8 items-center">
            <motion.img
              whileHover={{ rotate: [0, -10, 10, -10, 0] }}
              src={Logo}
              className="w-10 md:w-36 object-contain"
              alt="Order Master - Organization Logo"
              width={50}
              height={50}
              loading="lazy"
              title="Order Master - Organization Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-headingColor">
              Order Master
            </span>
          </Link>
        </motion.div>
      }
      </div>
      <hr className="my-2 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="flex flex-col items-center justify-center md:flex-row md:justify-between">
        <span className="text-sm text-gray-500 text-center dark:text-gray-400">
          © {" "}
            {
              new Date().getFullYear()
            }
           {" "}Order Master™. All Rights Reserved.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0 md:text-xl">
          <motion.a
            whileTap={{ scale: 1.1 }}
            target={"_blank"}
            rel="noreferrer"
            href="https://twitter.com/ordermasterapp"
            className="text-textColor h-10 w-10 bg-primary rounded-full flex items-center justify-center"
          >
            <BsTwitter />
          </motion.a>
          <motion.a
            whileTap={{ scale: 1.1 }}
            target={"_blank"}
            rel="noreferrer"
            href="https://instagram.com/ordermasterapp"
            className="text-textColor h-10 w-10 bg-primary rounded-full flex items-center justify-center"
          >
            <BsInstagram />
          </motion.a>
          <motion.a
            whileTap={{ scale: 1.1 }}
            target={"_blank"}
            rel="noreferrer"
            href="https://tiktok.com/@ordermasterapp"
            className="text-textColor h-10 w-10 bg-primary rounded-full flex items-center justify-center"
          >
            <BsTiktok />
          </motion.a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { useStateValue } from "../../context/StateProvider";
import CartBody from "./Body";
import CarttHeader from "./Header";
import { motion } from "framer-motion";
import EmptyCart from "../EmptyCart";
import NotFound from "../NotFound";
import Orders from "../Orders";
import { useState } from "react";
import {useTranslation} from "react-i18next";
const Cart = () => {
  const { t, i18n } = useTranslation();
  const [{ cartItems, orders }] = useStateValue();
  const [ordersOpen, setOrdersOpen] = useState(false);
  return (
    <>
      {ordersOpen ? (
        <Orders handler={setOrdersOpen} />
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            className={`w-full h-screen md:w-[350px] bg-white md:backdrop-blur-sm flex flex-col z-[101] drop-shadow-xl fixed top-0 right-0`}
          >
            <CarttHeader />
            <p className={(cartItems && cartItems.length>0 ? "text-white bg-cartBg" : "text-textColor") + " text-center text-xs"}> {t('cartMessage')} </p>
            {cartItems && cartItems.length > 0 ? (
              <CartBody action={setOrdersOpen} />
            ) : (
              <div>
              <div className="h-full w-full flex-1 flex items-center justify-center">
                <EmptyCart />
              </div>
              {orders &&
                <motion.button onClick = {() => setOrdersOpen(true)} whileTap={{scale:0.8}} className='w-full p-2 rounded-full bg-gradient-to-tr from-red-600 to-red-600 text-gray-50 text-lg my-2 hover:shadow-lg'>
                  {t('previousOrders')}
                </motion.button>
              }
              </div>
            )}
          </motion.div>
          {!cartItems && <NotFound text={"Cart Items not available"} />}
        </>
      )}
    </>
  );
};

export default Cart;

import AvatarI from "../../img/avatar.png";
import Cheff1I from "../../img/chef1.png";
import CheffI from "../../img/cheff.png";
import LogoI from "../../img/chef1.png";
import BikeDeliveryI from "../../img/delivery.png";
import HeroBgI from "../../img/hero-bg.png";
import EmptyCartI from "../../img/emptyCart.svg";
import NotFoundI from "../../img/NotFound.svg";
import Visa from "../../img/visa.png";
import Momo from "../../img/momo.png";
// Showcase Banner Static Assets
import IcreamI from "../../img/i1.png";
import StrawberryI from "../../img/f5.png";
import ChickenI from "../../img/c3.png";
import FishI from "../../img/fi3.png";
import Home1I from "../../img/home1.jpg"
import Home2I from "../../img/home2.jpg"
import Home3I from "../../img/home3.jpg"
import Home4I from "../../img/home4.jpg"
import Home5I from "../../img/home5.jpg"
import Home6I from "../../img/home6.jpg"



// Exports
export const Logo = LogoI
export const Avatar = AvatarI
export const Cheff = CheffI
export const Cheff1 = Cheff1I
export const BikeDelivery = BikeDeliveryI
export const HeroBg = HeroBgI
export const Icecream = IcreamI
export const Strawberry = StrawberryI
export const Chicken = ChickenI
export const Fish = FishI
export const EmptyCartImg = EmptyCartI
export const NotFoundImg = NotFoundI
export const CreditCard = Visa
export const MOMO = Momo
export const Home1 = Home1I
export const Home2 = Home2I
export const Home3 = Home3I
export const Home4 = Home4I
export const Home5 = Home5I
export const Home6 = Home6I
import { useStateValue } from "../../../context/StateProvider";
import React from 'react';
import BarChart from "./BarChart";
import {useTranslation} from "react-i18next";

const TotalTurnover = () => {
    const { t, i18n } = useTranslation();
  const [{ chartData, org }, dispatch] = useStateValue();

  return (
    <div className="bg-white-200 min-h-[9rem] p-10 rounded-lg">
      <p className="flex justify-center w-full text-xl font-bold text-gray-600 border-b-2 border-orange-200 px-6">
          {t('totalTurnoverChart')}
      </p>
      {
        chartData && <div className={(org && org.settings.subscriptionPlan.type === "STANDARD") ? "blur" : ""}><BarChart chartData={chartData.totalTurnover} ></BarChart></div>
      }
    </div>
  )
}

export default TotalTurnover
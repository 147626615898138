import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import {useStateValue} from "../../../context/StateProvider";
import { useReactToPrint } from "react-to-print";
import Modal from 'react-modal';
import {MdOutlineReceiptLong, MdOutlineRestaurantMenu, MdLocalPrintshop} from "react-icons/md";
import { Session } from "../../../types";
import { OrderReceipt } from "./orderReceipt";
import { TableReceipt } from "./tableReceipt";
import {useTranslation} from "react-i18next";

export const Receipt = ({
    key,
    id,
    isOrder
  }: {
    key: string;
    id: string;
    isOrder?: boolean;
  }) => {
    const { t, i18n } = useTranslation();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [receiptData, setReceiptData] = React.useState({});
    const [{ sessions }, dispatch] = useStateValue();

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const printRef: any = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current!,
    })

    useEffect(() => {
        if (isOrder) {
            sessions && sessions.forEach((session: any) => {
                session.orders.forEach((order: any) => {
                  if(order.id === id) {
                    setReceiptData({
                        cartItems: order.cartItems,
                        orderNotes: order.notes,
                        tableTitle: session.tableTitle
                    });
                  }
                })
              });
        } else {
            setReceiptData(sessions.filter((s: Session) => id === s.tableId)[0]);
        }
      }, []);

    return (
        <>
            <div className="flex flex-col gap-2">
                <>

                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        contentLabel="Update Table Modal"
                    >
                        <div style={{display: 'flex'}}>
                            <div className="flex justify-between w-full text-xl font-bold text-gray-600 pb-2 ">
                                {t('receipt')}
                            </div>
                            <div style={{marginLeft: "auto"}}>
                                <motion.div
                                    whileTap={{scale: 0.9}}
                                    initial={{opacity: 0, x: 200}}
                                    animate={{opacity: 1, x: 0}}
                                    exit={{opacity: 0, x: 200}}
                                    className="text-textColor"
                                    onClick={closeModal}
                                >
                                    <MdOutlineRestaurantMenu className="text-headingColor text-2xl"/>
                                </motion.div>
                            </div>
                        </div>
                        <div ref={printRef} className="ml-0 flex justify-center items-center">
                            {/* invoice will come here https://github.com/parzibyte/print-receipt-thermal-printer/blob/master/index.html */}
                            {isOrder ? <OrderReceipt key={key} receiptData={receiptData}/> : <TableReceipt key={key} receiptData={receiptData}/>}
                        </div>
                        <div className="w-full flex items-center justify-center">
                            <motion.button
                                whileHover={{scale: 1.1}}
                                className="ml-0 flex justify-center items-center gap-2 flex-row-reverse md:ml-auto w-full md:w-auto border-none outline-none rounded bg-orange-500 px-12 py-2 text-lg text-white"
                                onClick={handlePrint}
                            >
                                <MdLocalPrintshop/> {t('print')}
                            </motion.button>
                        </div>
                    </Modal>
                    <motion.div
                        whileTap={{scale: 1.1}}
                        whileHover={{scale: 1.2}}
                        className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-yellow-600 flex items-center justify-center cursor-pointer"
                        title={t('receipt')}
                        onClick={openModal}
                    >
                        <MdOutlineReceiptLong className="text-white md:text-xl"/>
                    </motion.div>
                </>
            </div>
        </>
    );
};

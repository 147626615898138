import { cartItem } from "../../../types";
import { OrderItem } from "../../Admin/OrderItem";
import { OrderItemAdd } from "../../Admin/OrderItemAdd";
import { OrderNotes } from "../OrderNotes";
import { useStateValue } from "../../../context/StateProvider";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { motion } from "framer-motion";
import {useTranslation} from "react-i18next";

const OrderDetail = ({
  orderId,
  handler
}: {
  orderId: string;
  handler: any;
}) => {
    const { t, i18n } = useTranslation();
    const [{ sessions }, dispatch] = useStateValue();
    let orderItems: any;
    let orderNotes: any;
    sessions && sessions.forEach((session: any) => {
      session.orders.forEach((order: any) => {
        if(order.id === orderId) {
          orderItems = order.cartItems;
          orderNotes = order.notes;
        }
      })
    });

  return (
    <>
      <div className="w-full flex flex-col justify-center">

        <div className="w-full flex items-center bg-white justify-between p-4 cursor-pointer">
          <motion.div whileTap={{ scale: 0.8 }} onClick={() => handler(false)}>
            <MdOutlineKeyboardBackspace className="text-textColor text-2xl " />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
          >
            <p className="text-xl font-bold text-gray-600">{t('foodItems')}</p>
          </motion.div>
          <motion.div
            whileTap={{scale:0.9}}
            className="flex items-center justify-center gap-1"
            title="Secured"
          >
          </motion.div>
        </div>
            <div className="w-full flex items-center justify-center gap-3 overflow-x-hidden flex-wrap">
              <div className="flex-1 my-2 mx-6 rounded-xl overflow-y-scroll scrollbar-hidden  h-52">
                {
                  orderItems && orderItems.map((item: cartItem) => (
                    <OrderItem key={item.id} orderItem={item} orderId={orderId}/>
                  ))
                }
              </div>
              {
                orderNotes && <OrderNotes notes={orderNotes}></OrderNotes>
              }
              <OrderItemAdd orderId={orderId}/>
            </div>
      </div>
    </>
  );
};

export default OrderDetail;

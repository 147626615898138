import {AssetUploader, Loader} from "../..";
import {BiCategory, BiFoodMenu} from "react-icons/bi";
import {useEffect, useState} from "react";
import {MdDeleteOutline, MdOutlineDataSaverOn, MdOutlineFastfood} from "react-icons/md";
import {firebaseRemoveUploadedImage,} from "../../../Firebase";

import {GiPowerButton, GiTakeMyMoney} from "react-icons/gi";
import {motion} from "framer-motion";
import {toast} from "react-toastify";
import {useStateValue} from "../../../context/StateProvider";
import {fetchFoodData, updateFoodData} from "../../../utils/functions";
import CategoriesSelector from "../AddFood/CategoriesSelector";
import {Money} from "../../../types";
import {useTranslation} from "react-i18next";

const UpdateFood = (props: any) => {
    const { t, i18n } = useTranslation();
    const [title, setTitle] = useState("");
    const [priceAmount, setPriceAmount] = useState("");
    const [image, setImage] = useState(null);
    const [category, setCategory] = useState("");
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("INACTIVE")
    const [orgId, setOrgId] = useState("");
    const [id, setId] = useState("");
    const [loaderMessage, setLoadermessage] = useState("");
    const [{foodItems, categories}, dispatch] = useStateValue();
    const [btnText, setBtnText] = useState(t('save'))

    const deleteImage = async () => {
        setLoadermessage(t('removingPhoto'));
        firebaseRemoveUploadedImage(image, setImage, setLoading);
        const data = {
            id: id,
            title: title,
            category: category,
            description: description,
            price: new Money(Number(priceAmount) * 100),
            status: status,
            imageUrl: null,
            organizationId: orgId
        };

        await updateFoodData(data, dispatch, false)
    };

    const saveChanges = async () => {
        setBtnText(t('savePending'));
        if (!title || !priceAmount || !image || !category) {
            toast.error(t('missingRequiredFieldError'))
            setBtnText(t('save'))
        } else {
            const data = {
                id: id,
                title: title,
                category: category,
                description: description,
                price: new Money(Number(priceAmount) * 100),
                status: status,
                imageUrl: image,
                organizationId: orgId
            };

            await updateFoodData(data, dispatch, true)
            await fetchFoodData(dispatch);
            setBtnText(t('save'));
            props.closeModal()
        }

    };

    const updatePhotoUrl = async (newUrl: any) => {
        setImage(newUrl);
        const data = {
            id: id,
            title: title,
            category: category,
            description: description,
            price: new Money(Number(priceAmount) * 100),
            status: status,
            imageUrl: image,
            organizationId: orgId
        };

        await updateFoodData(data, dispatch, false)
    }

    const setFormItems = async (id: string) => {
        const food = foodItems.filter((item: any) => id === item.id)[0];
        const formattedPrice = String(new Money(food.price.amount).toMajorUnit())
        setTitle(food.title);
        setPriceAmount(formattedPrice);
        setImage(food.imageUrl);
        setCategory(food.category);
        setDescription(food.description);
        setStatus(food.status);
        setOrgId(food.organizationId);
        setId(food.id);
    }

    const validateNumber = (value: any) => {
        if (isNaN(value)) {
            toast.error("Please enter a valid number", {toastId: 123});
            return "";
        }
        return value;
    };

    useEffect(() => {
        setFormItems(props.id);
    }, []);


    return (
        <>

            <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
                <MdOutlineFastfood className="text-xl text-gray-600"/>
                <input
                    type="text"
                    required
                    placeholder={t('enterProductName')}
                    autoFocus
                    className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>

            <div className="w-full flex flex-col md:flex-row items-center gap-3">
                <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <BiCategory className="text-xl text-gray-600"/>
                    <CategoriesSelector
                        categories={categories}
                        action={setCategory}
                        selected={category}
                    />
                </div>
            </div>
            <div
                className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-[225px]  md:h-[420px] round-lg">
                {loading ? (
                    <Loader progress={loaderMessage}/>
                ) : (
                    <>
                        {image ? (
                            <>
                                <div className="relative h-full">
                                    <img
                                        src={image}
                                        alt="uploaded food"
                                        className="w-full h-full object-cover"
                                        loading="lazy"
                                        title="Order Master - Food"
                                    />
                                    <motion.button
                                        whileTap={{scale: 1.1}}
                                        whileHover={{scale: 1.2}}
                                        title={t('removePhoto')}
                                        className="absolute bottom-3 right-3 rounded-full p-2 md:p-5 bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out"
                                        onClick={() => deleteImage()}
                                    >
                                        <MdDeleteOutline className="text-white"/>
                                    </motion.button>
                                </div>
                            </>
                        ) : (
                            <AssetUploader
                                action={updatePhotoUrl}
                                progressHandler={setLoadermessage}
                                promise={setLoading}
                            />
                        )}
                    </>
                )}
            </div>

            <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <GiTakeMyMoney className="text-gray-600 text-2xl"/>
                <input
                    type="number"
                    min="0.00"
                    max="10000.00"
                    step="0.1"
                    required
                    placeholder={t('enterPrice')}
                    autoFocus
                    className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
                    value={priceAmount}
                    onChange={(e) => setPriceAmount(validateNumber(e.target.value))}
                />
            </div>
            <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
                <BiFoodMenu className="text-xl text-gray-600"/>
                <input
                    type="text"
                    required
                    placeholder={t('enterDescription')}
                    autoFocus
                    className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>

            <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2 settings text-textColor">
                <GiPowerButton className="text-gray-600 text-xl"/>
                {t('status')}
                <label className="switch">
                    <input
                        className="bg-transparent outline-none w-full capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer"
                        value={12}
                        type="checkbox"
                        checked={status === 'ACTIVE'}
                        onChange={e => setStatus(e.target.checked ? 'ACTIVE' : 'INACTIVE')}>
                    </input>
                    <span className="slider"></span>
                </label>
            </div>

            <div className="w-full flex items-center justify-center">
                <motion.button
                    whileHover={{scale: 1.1}}
                    className="ml-0 flex justify-center items-center gap-2 flex-row-reverse md:ml-auto w-full md:w-auto border-none outline-none rounded bg-orange-500 px-12 py-2 text-lg text-white"
                    onClick={() => saveChanges()}
                >
                    <MdOutlineDataSaverOn/> {btnText}
                </motion.button>
            </div>

        </>
    );
};

export default UpdateFood;

import { useStateValue } from "../../../context/StateProvider";
import React from 'react';
import LineChart from "./LineChart";
import {useTranslation} from "react-i18next";

const PeakTimes = () => {
  const [{ chartData, org }, dispatch] = useStateValue();
    const { t, i18n } = useTranslation();

  return (
    <div className="bg-white-200 min-h-[9rem] p-10 rounded-lg">
      <p className="flex justify-center w-full text-xl font-bold text-gray-600 border-b-2 border-orange-200 px-6">
          {t('peakDatesChart')}
      </p>
      {
        chartData && <div className={(org && org.settings.subscriptionPlan.type === "STANDARD") ? "blur" : ""}><LineChart chartData={chartData.mostPeakDates} ></LineChart></div>
      }
    </div>
  )
}

export default PeakTimes
import { FaSearch } from "react-icons/fa";
import { Table } from "../../../types";
import { SingleTable } from "../../Table";
import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../context/StateProvider";
import {useTranslation} from "react-i18next";
import Spinner from "../../Spinner";

const Tables = () => {
    const { t, i18n } = useTranslation();
    const [{ tables }, dispatch] = useStateValue();
    const [filteredTables, setFilteredTables] = useState<Table[]>();

    const filterTable = (value: any) => {
        if(value.length === 0) {
          setFilteredTables(tables);
        }else{
          const filteredFoodItems = tables.filter((table:Table) => table.title.toLowerCase().includes(value.toLowerCase()));
          setFilteredTables(filteredFoodItems);
        }
    }
    const searchTable = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterTable(e.target.value);
    }
    useEffect(() => {
      if (tables) {
        setFilteredTables(tables);
      }
    }, [tables]);
  return (
    <div className="w-full flex flex-col justify-center">
      {/* search bar */}
      <div className="w-full flex justify-center p-2 bg-white mb-4 rounded-lg">
        <input
          className="w-full p-2 outline-none rounded-lg "
          type="text"
          placeholder={t('searchTable')}
          onChange={(e) => searchTable(e)}
        />
        {/* search button */}
        <button className="flex items-center justify-center gap-3 text-orange-700 font-bold py-2 px-4 rounded-lg">
          <FaSearch />
        </button>
      </div>
      {
        (!filteredTables) ? <Spinner color={"#e80013"} loading={filteredTables}/> :
          <div className="w-full flex items-center justify-center gap-3 overflow-x-hidden flex-wrap">
          {
              filteredTables.map((item: Table) => (
                  <SingleTable key={item.id} tableId={item.id} col admin isFromAdmin = {true}/>
              ))
          }
          </div>
      }
    </div>
  );
};

export default Tables;

export type foodItemStatic = {
  id: number;
  title: string;
  desc: string;
  price: Money;
  imgSrc: string;
}
export type foodItemsStatic = {
  items: foodItemStatic[];
}
export type FoodItem = {
    id: string;
    title: string;
    description?: string;
    price: Money;
    imageUrl: string;
    category: string;
    status: string;
};

export type Table = {
  id: string;
  title: string;
  imageUrl: string;
};

export type FoodItems = {
  items: FoodItem[];
}

export type Tables = {
  items: Table[];
}

export type FoodCategory = {
  sortIndex: number;
  id: string;
  name: string;
  tag: string;
  icon?: JSX.Element
}

export type cartItem = {
  id: string;
  menuItemId: string;
  qty: number;
}

export type cartNote = {
  id: string;
  message: string;
}

export type Order = {
  id: string;
  cartItems: cartItem[];
  status: string; //pending approved delivered
  totalPrice: Money
  createdAt: string;
}

export type Session = {
  tableId: string;
  tableTitle: string;
  orders: Order[];
}

export type cartItems = {
  items: cartItem[]
}

export type User = {
  uid: string;
  email?: string;
  displayName?:string;
  phoneNumber?: string;
  providerId: string;
  photoUrl?: string;

}

export class Money {
  amount: number; // in minor units (kuru$)
  currency: string;

  constructor(amount: number) {
    this.amount = amount;
    this.currency = 'TRY'; // ileride enum ile degistirip diger diller eklenebilir
  }

  fromMajorUnit(amount: number) {
    if(this.amount == 0) {
      return new Money(0);
    }
    return new Money(amount * 100)
  }

  toMajorUnit() {
    if(this.amount == 0) {
      return 0;
    }
    return this.amount / 100
  }
}

export type FoodCategories = FoodCategory[];
import { useEffect } from "react";
import { useStateValue } from "../../../context/StateProvider";
import { fetchFaqs } from "../../../utils/functions";
import Accordion from "./Accordion";
import { useTranslation } from "react-i18next";
import Spinner from "../../Spinner";

// ****************UPDATE ORG WILL BE DONE**********************
const Settings = () => {
  const { t, i18n } = useTranslation();
  const [{ faqs }, dispatch] = useStateValue();
  useEffect(() => {
    async function fetchFaqsApi() {
      await fetchFaqs(dispatch);
    }

    fetchFaqsApi();
  }, []);

  return (
      <div>
        <p className="text-3xl lg:text-3xl font-bold text-center tracking-wide flex flex-col text-headingColor mt-4">
          {t('faqs')}
        </p>
        <div className="flex flex-col my-2 mx-6 rounded-xl overflow-y-scroll scrollbar-hidden">
          {
            !faqs ? <Spinner color={"#e80013"} loading={faqs}/> : faqs.map((item: any)=>{
              return <Accordion question={item.question} answer={item.answer} />
            })
          }
        </div>
      </div>
  );
};

export default Settings;

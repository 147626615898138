import { IoMdNotificationsOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { useStateValue } from "../../context/StateProvider";
import { ToggleAdminMode } from "../../utils/functions";
import {useTranslation} from "react-i18next";
import { motion } from "framer-motion";
import Notifications from "./Notifications";
import { useEffect, useState} from "react";



const Content = ({ pageTitle, Element }: { pageTitle: string, Element:JSX.Element }) => {
  const { t, i18n } = useTranslation();
  const [{ notifications }, dispatch] = useStateValue();
  const [isOpened, setIsOpened] = useState(false)

  function getNumberOfUnreadNotifications() {
    let total = 0;
    notifications.map((notificaion: any) => {
      if (!notificaion.read) {
        total += 1;
      }
    })
    return total;
  }
  return (
    <div className="flex flex-col w-[80%] h-screen px-2">
      <div className="flex justify-between w-full text-xl font-bold text-gray-600 border-b-2 border-orange-200 pt-6 pb-2 px-6">
        {pageTitle}

        {/* home button */}
        <motion.div
          whileTap={{ scale: 0.9 }}
          whileHover={{ scale: 1.1 }}
          className="relative flex items-center justify-center text-textColor"
          onClick={() => setIsOpened(!isOpened)}
        >
          <IoMdNotificationsOutline className="text-4xl" />
          {notifications && (
            <div className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-cartNumBg flex items-center justify-center cursor-pointer">
              <p className="text-sm text-white font-semibold">
                {getNumberOfUnreadNotifications()}
              </p>
            </div>
          )}
          {/* <div className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-cartNumBg flex items-center justify-center cursor-pointer">
            <p className="text-sm text-white font-semibold">
              {3}
            </p>
          </div> */}
        </motion.div>
        <Notifications isOpened={isOpened} setIsOpened={setIsOpened}/>
      </div>
      <div className="flex-1 my-2 mx-6 border-8 border-gray-200 rounded-xl border-dotted overflow-y-scroll scrollbar-hidden">{Element}</div>

    </div>
  );
};
export default Content;
import { Table } from "../../types";
import { motion } from "framer-motion";
import Action from "./action";
import {useStateValue} from "../../context/StateProvider";
import { useReactToPrint } from "react-to-print";
import React, { useRef } from "react";


export const SingleTable = ({
  tableId,
  col,
  admin,
  isFromAdmin
}: {
  tableId: string;
  col?: boolean;
  admin?:boolean;
  isFromAdmin?:boolean
}) => {
  let [{ tables, org }] = useStateValue();
  let item = tables.filter((t: Table) => tableId == t.id)[0];
  const { id, title, imageUrl } = item;

  const printRef: any = useRef();
  const handlePrint = useReactToPrint({
      content: () => printRef.current!,
  })

  const saveImage = async ()=>{
    const imageBlob = await fetch(imageUrl)
      .then((response) => response.arrayBuffer())
      .then((buffer) => new Blob([buffer], {type: "image/jpeg"}));
    const link = document.createElement("a");
    link.href = URL.createObjectURL(imageBlob);
    link.download = title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <motion.div
      whileTap={{ rotate: [0, -1, 1, -1, 0] }}
      className={`${
        !col ? "w-[275px] min-w-[275px]" : "w-[320px] min-w-[320px]"
      } md:w-[300px] md:min-w-[300px] ${
        col ? "my-12" : "my-2 md:my-5"
      } h-auto bg-cardOverlay rounded-lg p-2 px-3 backdrop-blur-lg hover:drop-shadow-sm cursor-pointer`}
    >
      <div className="w-full flex items-center justify-between">
      <div ref={printRef} className="ml-0 flex justify-center items-center">
        <div className="ticket">
          <img src={org.photoUrl || "./logo.png"} width={50} height={50} className="logo" alt="Order Master - Organization Logo" title="Order Master - Organization Logo" loading="lazy"></img>
          <p className="centered header">
              {org.displayName || "Order Master"}
          </p>
          <img src={imageUrl} className="imageQr" width={50} height={50} onClick={()=>{saveImage();}} alt="Order Master - Organization Table QR" title="Order Master - Organization Table QR" loading="lazy"></img>
          <p className="centered header">
              {title || "Order Master"}
          </p>
          <p className="centered italic underline">{window.location.hostname}</p>
        </div>
      </div>
      <Action table={item} admin={admin} isFromAdmin={isFromAdmin} handlePrint={handlePrint}/>
      </div>
    </motion.div>
  );
};

import { useStateValue } from "../../context/StateProvider";
import { motion } from "framer-motion";
import EmptyCart from "../EmptyCart";
import NotFound from "../NotFound";
import Orders from "../Orders";
import { useState, CSSProperties } from "react";
import {useTranslation} from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
    display: "block",
    margin: "auto",
    borderWidth: "3px"
  };

const Spinner = ({
    loading,
    color
  }: {
    loading: any;
    color: any;
  }) => {
  return (
    <div className="flex items-center justify-center w-full h-96">
      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Spinner;

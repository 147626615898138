import { AiFillDashboard } from "react-icons/ai";
import { motion } from "framer-motion";
import {FaCogs} from "react-icons/fa";
import DashboardSuper from "./DashboardSuper";
import { useStateValue } from "../../context/StateProvider";
import {useTranslation} from "react-i18next";
import { useState, useEffect } from 'react';
import SettingsSuper from "./SettingsSuper";
import OrganizationsSuper from "./OrganizationsSuper";
import {MdList} from "react-icons/md";

const SidenavMenuSuper = ({
  activePage,
  setActivePage,
  setPageContent,
}: {
  activePage: string;
  setActivePage: any;
  setPageContent: any;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <motion.nav
        initial={{opacity: 0, x: 200}}
        animate={{opacity: 1, x: 0}}
        exit={{opacity: 0, x: 200}}
        className="space-y-2 w-full "
    >
      <NavItem
        activePage={activePage}
        svgIcon={<AiFillDashboard/>}
        title={t('analytic')}
        setActivePage={setActivePage}
        setPageContent={setPageContent}
        pageContent={<DashboardSuper/>}
      />
      <NavItem
          activePage={activePage}
          svgIcon={<MdList/>}
          title={t('organizations')}
          setActivePage={setActivePage}
          setPageContent={setPageContent}
          pageContent={<OrganizationsSuper/>}
      />
      <NavItem
          activePage={activePage}
          svgIcon={<FaCogs/>}
          title={t('settings')}
          setActivePage={setActivePage}
          setPageContent={setPageContent}
          pageContent={<SettingsSuper/>}
      />
    </motion.nav>
  );
}

const NavItem = ({
  activePage,
  svgIcon,
  title,
  setActivePage,
  setPageContent,
  pageContent,
}: {
  activePage: string;
  setActivePage: any;
  svgIcon: any;
  title: string;
  setPageContent: any;
  pageContent: JSX.Element;
}) => {
  const { t, i18n } = useTranslation();
  const handleClick = () => {
    setActivePage(title);
    setPageContent(pageContent);
  };
  const [{tables, foodItems, sessions}, dispatch] = useStateValue()
  const [activeOrders, setActiveOrders] = useState(0);

  function qtyOfActiveOrders() {
    let total = 0;
    sessions.map((session: any)=>{
      session.orders.map((item: any) => {
        if (item.status === 'PENDING') {
          total++;
        }
      })
    })
    return total;
  }

  useEffect(() => {
    if (sessions) {
      setActiveOrders(qtyOfActiveOrders())
    }
  }, [sessions]);

  return (
    <motion.div
      whileTap={{ scale: 1.1 }}
      onClick={handleClick}
      className={`flex items-center no-underline text-orange-50 hover:text-orange-100 p-3 rounded-md cursor-pointer hover:bg-orange-700 ${
        activePage === title ? "bg-orange-700" : ""
      }`}
    >
      <p className="font-bold text-xl">{svgIcon}</p>
      <div className="flex items-center justify-center gap-10 font-bold pl-3">{title}
        {
          (title === t('menu') || title === t('tables')) && (
            <div className=" w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center cursor-pointer">
            <p className="text-sm text-white font-semibold">
              {
                foodItems && tables && (title === t('menu') ? foodItems.length : tables.length)
              }
            </p>
          </div>
          )
        }
        {
          (title === t('activeOrders') || title === t('activeTables')) && (
            <div className=" w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center cursor-pointer">
            <p className="text-sm text-white font-semibold">
              {
                sessions && (title === t('activeOrders') ? activeOrders : sessions.length)
              }
            </p>
          </div>
          )
        }
      </div>
    </motion.div>
  );
};
export default SidenavMenuSuper;

import React, { useState, useEffect, useRef } from "react";
import "./receipt.css"
import { useStateValue } from "../../../context/StateProvider";
import { getFoodyById } from "../../../utils/functions";
import {useTranslation} from "react-i18next";

export const OrderReceipt = ({
    key,
    receiptData
  }: {
    key: string;
    receiptData?: any;
  }) => {
    const { t, i18n } = useTranslation();
    const [{ org, foodItems }, dispatch] = useStateValue();
    const [totalPrice, setTotalPrice] = React.useState(0);
    const [foodItemArr, setFoodItemArr] = React.useState([]);
    const [notesArr, setNotesArr] = React.useState([]);

    useEffect(() => {
        let cartFoodItemsArr : any = [];
        let cartNotesArr : any = [];
        let totalPrice : any = 0;
        receiptData.cartItems.map((item: any) => {
            const foodItem = getFoodyById(foodItems, item.menuItemId);

            cartFoodItemsArr.push({
                title: foodItem?.title,
                qty: item.qty,
                price: foodItem?.price
            })
            totalPrice += (item.qty * foodItem?.price.amount!);
        });
        receiptData.orderNotes.map((item: any) => {
            cartNotesArr.push(item.message);
        });
        setTotalPrice(totalPrice/100);
        setFoodItemArr(cartFoodItemsArr);
        setNotesArr(cartNotesArr);
    }, []);
    return (
        <>
            <div className="ticket">
                <img src={org.photoUrl || "./logo.png"} width={50} height={50} className="logo" alt="Order Master - Logo" loading="lazy" title="Order Master - Logo"></img>
                <p className="centered header">
                    {org.displayName || "Order Master"}
                </p>
                <p className="centered header">
                    {receiptData.tableTitle || "Order Master"}
                </p>
                <table>
                    <thead>
                        <tr>
                            <th className="quantity">{t('quantityAbbreviation')}</th>
                            <th className="description">{t('description')}</th>
                            <th className="price"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            foodItemArr && foodItemArr.map((item: any) => (
                                <tr>
                                <td className="quantity">{item.qty}</td>
                                <td className="description">{item.title}</td>
                            </tr>
                            ))
                        }
                        <tr>
                            <td className="quantity"></td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    {
                        notesArr && notesArr.map((item: any) => (
                            <p className="centered">{item}</p>
                        ))
                    }
                </div>
                <p className="centered italic underline">{window.location.hostname}</p>
            </div>
        </>
    );
};

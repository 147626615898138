import { ShowcaseBanner, HomePageInfo} from "../../components"
import { useNavigationType, NavigationType } from "react-router-dom";
import { useEffect } from "react";
import { useStateValue } from "../../context/StateProvider";
import { ToggleAdminMode } from "../../utils/functions";
import { Helmet } from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {buildLocalizedCanonicalPath, buildLocalizedPath} from "../../i18n/i18n";

const Home = () => {
  const [ {}, dispatch] = useStateValue();
  const { t, i18n } = useTranslation();

  const navigationType = useNavigationType();
  useEffect(() => {
      if (navigationType === NavigationType.Pop) {
        ToggleAdminMode(dispatch, false)
      }
  },[navigationType]);
  return (
    <>
      <Helmet>
        <title>{t('homeMetaTitle')}</title>
        <meta name="description" content={t('homeMetaDesc')}/>
        <link rel="canonical" href={buildLocalizedCanonicalPath("/")} hrefLang={i18n.language}/>
      </Helmet>
      <div className='flex w-full h-auto flex-col items-center justify-center'>
        <ShowcaseBanner />
        <HomePageInfo />
        {/* <FruitsSection /> */}
        {/* <MenuSection /> */}

      </div>
    </>
  )
}

export default Home
import {useNavigate} from "react-router-dom";
import {ImageBox} from ".";
import {toast} from "react-toastify";

import {motion} from "framer-motion";
import {useState} from "react";
import {useStateValue} from "../../context/StateProvider";
import {forgotPassword} from "../../Firebase";
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [{user}, dispatch] = useStateValue();
    const [email, setEmail] = useState("");

    const ForgotPassword = () => {
        if (!user) {
            if (email.length > 0) {
                toast.promise(
                    forgotPassword(email),
                    {
                        pending: t('forgotPasswordEmailPending'),
                        success: t('forgotPasswordEmailSuccess') + ': ' + email,
                        error: t('forgotPasswordEmailError'),
                    }
                ).then(async () => {
                    setEmail('');
                }
                ).catch((error) => {
                        // const errorCode = error.code;
                        const errorMessage = error.message;
                        toast.error(errorMessage, {autoClose: 1500});
                    }
                );

            } else {
                toast.warn(t('missingRequiredFieldError'), {autoClose: 1500});
            }
        }
    };

    return (
        <section className="w-full h-auto ">
            <div className="container md:py-10 h-full">
                <div className="flex justify-center items-center flex-wrap h-full g-3 text-gray-800">
                    <ImageBox/>
                    <div className="w-full md:w-[30rem]">
                        <div
                            className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                            <p className="text-center text-textColor text-lg font-semibold mx-4 mb-0 uppercase">
                                {t('forgotPassword')}
                            </p>
                        </div>
                        <form className="p-2">
                            <div className="mb-6">
                                <input
                                    type="text"
                                    className="form-control block w-full px-4 py-2  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-orange-600 focus:outline-none"
                                    placeholder={t('emailAddress')}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <motion.p
                                className="cursor-pointer flex items-center justify-center px-7 py-3 bg-gradient-to-br from-orange-400 to-orange-500 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-orange-600 hover:shadow-lg focus:bg-orange-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                                onClick={ForgotPassword}
                                whileHover={{scale: 1.1}}
                            >
                                {t('resetPassword')}
                            </motion.p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ForgotPassword;

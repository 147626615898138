import {GiBeerBottle, GiBowlOfRice, GiChickenOven, GiFruitTree, GiMeat} from "react-icons/gi";
import {MdOutlineFastfood, MdOutlineIcecream} from "react-icons/md";
import {FaFish} from "react-icons/fa";
import i18n from '../i18n/i18n'

export const iconMap = [
    {
        label: i18n.t('defaultIcon'),
        value: '',
        icon: <MdOutlineFastfood/>
    },
    {
        label: <GiChickenOven/>,
        value: 'GiChickenOven',
        icon: <GiChickenOven/>
    },
    {
        label: <GiFruitTree/>,
        value: 'GiFruitTree',
        icon: <GiFruitTree/>
    },
    {
        label: <GiBeerBottle/>,
        value: 'GiBeerBottle',
        icon: <GiBeerBottle/>
    },
    {
        label: <GiMeat/>,
        value: 'GiMeat',
        icon: <GiMeat/>
    },
    {
        label: <GiBowlOfRice/>,
        value: 'GiBowlOfRice',
        icon: <GiBowlOfRice/>
    },
    {
        label: <MdOutlineIcecream/>,
        value: 'MdOutlineIcecream',
        icon: <MdOutlineIcecream/>
    },
    {
        label: <FaFish/>,
        value: 'FaFish',
        icon: <FaFish/>
    },
    {
        label: <MdOutlineFastfood/>,
        value: 'MdOutlineFastfood',
        icon: <MdOutlineFastfood/>
    }
];
import {  BiArch } from "react-icons/bi";
import {BsFillHouseFill, BsSpeaker} from "react-icons/bs";
import {
  MdOutlineDataSaverOn,
  MdDeleteOutline,
  MdOutlineLanguage,
  MdWorkspacePremium,
  MdEmail,
  MdPassword
} from "react-icons/md";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useState } from "react";
import { useStateValue } from "../../context/StateProvider";
import { AssetUploader, Loader } from "../../components";
import { updateOrgData, updateEmail } from "../../utils/functions";
import { firebaseRemoveUploadedImage, forgotPassword } from "../../Firebase";
import { useTranslation } from "react-i18next";
import { Alert } from "../../components/Admin/Alert";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {buildLocalizedPath} from "../../i18n/i18n";

const UpdateProfile = () => {
    const { t, i18n } = useTranslation();
    const [{ org, user }, dispatch] = useStateValue();
    const [displayName, setDisplayName] = useState(org.displayName)
    const [photoUrl, setPhotoURL] = useState(org.photoUrl)
    const [loading, setLoading] = useState(false)
    const [address, setAddress] = useState(org.address)
    const [language, setLanguage] = useState(org.settings.language)
    const [loaderMessage, setLoadermessage] = useState("");
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');

    function resetPassword () {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <Alert onClose={onClose} message={t('resetPasswordConfirmMessage')} onConfirm={()=>{updatePassword()}}/>
            );
          }
        });
    }

    const updatePassword = () => {
        if (email.length > 0) {
            forgotPassword(email)
            .then(async () => {
              localStorage.setItem("user", "undefined");
              localStorage.setItem("adminMode", "undefined");
              window.location.href = "/"
            }
            ).catch((error) => {
                    // const errorCode = error.code;
                    const errorMessage = error.message;
                    toast.error(errorMessage, {autoClose: 1500});
                }
            );

        } else {
            toast.warn(t('missingRequiredFieldError'), {autoClose: 1500});
        }
      };

      function updateEmailConfirm () {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <Alert onClose={onClose} message={t('updateEmailConfirmMessage')} onConfirm={()=>{saveChanges()}}/>
            );
          }
        });
    }

    const saveChanges = async () => {
        if (!email || !password) {
          toast.error(t('missingRequiredFieldError'));
          return;
        }
        if(email.length < 0 || password.length < 0)
        {
          toast.error(t('fieldValidationError'))
        }else{
          await updateEmail(email, password, dispatch, user, true);
        }
      };

      return (
        <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="flex justify-center items-center w-full text-3xl font-bold text-gray-600 pt-6">
            {t('profile')}
            </div>
            <div className="border w-full  md:w-[60%]  flex border-gray-300 items-center rounded-lg p-4 flex-col justify-center gap-4  mt-8 mg:mt-10">
                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
                        <MdEmail className="text-xl text-gray-600"/>
                        <input
                            type="email"
                            required
                            placeholder={t('email')}
                            className="h-full w-full bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
                        <MdPassword className="text-xl text-gray-600"/>
                        <input
                            type="password"
                            required
                            placeholder={t('password')}
                            className="h-full w-full bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
            <div className="w-full flex justify-between">
                <motion.button
                    whileHover={{scale: 1.1}}
                    className="flex justify-center items-center w-full md:w-auto border-none outline-none rounded bg-green-500 px-12 py-2 text-lg text-white"
                    onClick={() => updateEmailConfirm()}
                >
                    {t('updateEmail')}
                </motion.button>
                <motion.button
                    whileHover={{scale: 1.1}}
                    className="flex justify-center items-center w-full md:w-auto border-none outline-none rounded bg-orange-500 px-12 py-2 text-lg text-white"
                    onClick={() => resetPassword()}
                >
                    {t('resetMyPassword')}
                </motion.button>
            </div>
        </div>
    </div>
  );
};

export default UpdateProfile;

import {app, firestore, storage} from "../firebase.config";
import {collection, deleteDoc, doc, getDocs, query, setDoc} from "firebase/firestore";
import {createUserWithEmailAndPassword, getAuth, sendEmailVerification, signInWithPopup} from "firebase/auth";
import {deleteObject, getDownloadURL, ref, uploadBytesResumable,} from "firebase/storage";

import {MdOutlineCloudUpload} from "react-icons/md";
import {toast} from "react-toastify";
import {Categories} from "../utils/categories";
import {sendPasswordResetEmail, signInWithEmailAndPassword, verifyBeforeUpdateEmail} from "@firebase/auth";
import i18n from '../i18n/i18n'
import {deleteCartItemDb, getAllCategories, getOrg, getUser, updateEmailDb} from "../db";
import imageCompression from "browser-image-compression";

export const firebaseUploadImage = async (
    imageFile,
    promise,
    progressHandler,
    action,
    to
) => {
    promise(true);
    // progressHandler(0)
    toast.info(i18n.t('savePending'), {
        icon: <MdOutlineCloudUpload className="text-blue-600"/>,
    });
    const storageRef = ref(
        storage,
        `Images/${to}/${Date.now()}-${imageFile.name}`
    );
    const options = {
        maxSizeMB: 1,
        alwaysKeepResolution: true,
        useWebWorker: true,
    } // compression options
    const compressedFile = await imageCompression(imageFile, options);
    const uploadPhoto = uploadBytesResumable(storageRef, compressedFile);
    uploadPhoto.on(
        "state_changed",
        (snapshot) => {
            progressHandler(
                `${i18n.t('uploadStatus')}: ${Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )}%`
            );
        },
        (error) => {
            console.log(error);
            toast.error(i18n.t('saveError'));
            action(null);
            setTimeout(() => {
                promise(false);
            }, 3000);
        },
        () => {
            getDownloadURL(uploadPhoto.snapshot.ref).then((downloadUrl) => {
                action(downloadUrl);
                promise(false);
                toast.success(i18n.t('saveSuccess'));
            });
        }
    );
};

export const firebaseRemoveUploadedImage = (
    ImageFile,
    imageHandler,
    promise
) => {
    const dummy = "https://firebasestorage.googleapis.com/v0"
    promise(true);
    toast.info(i18n.t('removingPhoto'), {
        icon: <MdOutlineCloudUpload className="text-blue-600"/>,
        autoClose: 1500,
        toastId: "remove-image",
    });
    if (ImageFile.includes(dummy)) {
        const deleteRef = ref(storage, ImageFile);
        deleteObject(deleteRef).then(() => {
            imageHandler(null);
            promise(false);
            toast.success(i18n.t('saveSuccess'), {autoClose: 1500, toastId: "remove-image"});
        });
    } else {
        imageHandler(null);
        promise(false);
        toast.success(i18n.t('saveSuccess'), {autoClose: 1500, toastId: "remove-image"});
    }
};

export const silentRemoveUploadedImage = (ImageFile) => {
    const deleteRef = ref(storage, ImageFile);
    deleteObject(deleteRef).then(() => {
    });
};

// Authenticate user using PROVIDER
export const AUTHPROVIDER = async (provider) => {
    const firebaseAuth = getAuth(app);
    const {
        user: {refreshToken, providerData},
    } = await signInWithPopup(firebaseAuth, provider);
    // add provider data to user
    const currentUser = await getAuth().currentUser;
    const token = await currentUser.getIdToken();
    await fetch(process.env.REACT_APP_API_BASE_URL + 'onboarding/register', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then((response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
    await updateEmailDb();
    let userData = await getUser(currentUser.uid);
    let orgData = await getOrg(userData.organizations[0]);
    return {refreshToken, userData, orgData};
};

export const firebaseUpdateEmail = async (email, password) => {
    const currentUser = await getAuth().currentUser;
    const firebaseAuth = getAuth(app);
    if (currentUser) {
        if (email !== currentUser.email) {
            getAuth().useDeviceLanguage();
            try {
                let userCredential = await signInWithEmailAndPassword(firebaseAuth, currentUser.email, password)
                return verifyBeforeUpdateEmail(userCredential.user, email)
            } catch (e) {
                throw new Error('wrongPasswordError');
            }
        } else {
            throw new Error('sameEmailError');
        }
    }
};

// Signup with email and password
export const EMAILSIGNUP = async (email, password) => {
    const firebaseAuth = getAuth(app);
    firebaseAuth.useDeviceLanguage();
    const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password)
    await sendEmailVerification(userCredential.user);
    const token = await getAuth().currentUser.getIdToken();
    await fetch(process.env.REACT_APP_API_BASE_URL + 'onboarding/register', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + token
        },
    }).then((response) => {
        if (!response.ok) {
            throw new Error('Server error!');
        }
    })
    const currentUser = await getAuth().currentUser;
    await getAuth().signOut();
    // !! -- Deprecated after implementing email validation -- !!
    // let userData = await getUser(currentUser.uid);
    // let orgData = await getOrg(userData.organizations[0]);
    // return {userData, orgData}
};

//  Signin with email and password
export const EMAILSIGNIN = async (email, password) => {
    const firebaseAuth = getAuth(app);
    await signInWithEmailAndPassword(firebaseAuth, email, password)
    const currentUser = getAuth().currentUser;
    if (!currentUser.emailVerified) {
        toast.error(i18n.t('emailNotVerifiedError'));
        return Promise.reject("");
    }
    await updateEmailDb();
    let userData = await getUser(currentUser.uid);
    let orgData = await getOrg(userData.organizations[0]);
    return {userData, orgData};
};

export const forgotPassword = async (email) => {
    const firebaseAuth = getAuth(app);
    firebaseAuth.useDeviceLanguage();
    await sendPasswordResetEmail(firebaseAuth, email)
};

// Fetch All Food Products  from Firestore
export const firebaseFetchCategories = async (organizationId) => {
    let items = await getAllCategories(organizationId);
    let categories = Categories(items);
    return categories;
}

//  Delete Cart from Firestore
export const firebaseEmptyCart = async () => {
    await deleteDoc(doc(firestore, "CartItems"));
}

//  Empty user cart from firestore
export const firebaseEmptyUserCart = async (cartItems, tableId, organizationId) => {
    cartItems.forEach((item) => {
        deleteCartItemDb(item, tableId, organizationId);
    })
}

// Logout user
export const firebaseLogout = async () => {
    await getAuth(app).signOut();
}

// ADMIN USER MANAGEMENT

// firestore add to users collection
export const firebaseAddUser = async (data) => {
    // check if user already exists
    const user = await firebaseGetUser(data.uid);
    if (user.length === 0) {
        await setDoc(doc(firestore, "user", `${data.uid}`), data, {
            merge: true,
        });
    }
}

// get user
export const firebaseGetUser = async (uid) => {
    const user = await getDocs(
        query(collection(firestore, "user"))
    );
    let users = user.docs.map((doc) => doc.data());
    return users.filter((user) => user.id === uid)
}

// update user
export const firebaseUpdateUser = async (data) => {
    await setDoc(doc(firestore, "users", `${data.uid}`), data, {
        merge: true,
    });
}

// firebase get all users
export const firebaseGetAllUsers = async () => {
    const users = await getDocs(
        query(collection(firestore, "users"))
    );
    let usersData = users.docs.map((doc) => doc.data());
    return usersData
}

// delete food
export const firebaseDeleteFood = async (id) => {
    await deleteDoc(doc(firestore, "Food", `${id}`));
}
import React from "react";
import {useTranslation} from "react-i18next";
export type FoodCategory = {
  id: number;
  name: string;
  tag: string;
};

export type FoodCategories = FoodCategory[];

interface Props {
  categories: FoodCategories;
  action: any;
  selected: string;
}
const CategoriesSelector: React.FC<Props> = ({
  categories,
  action,
  selected,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <select name="categories" id="categories" className="bg-transparent outline-none w-full capitalize text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer" onChange={(e) => action(e.target.value)}>
      <option defaultValue={selected? selected: t('selectCategory')}  className="bg-white capitalize">
        {selected? selected: t('selectCategory')}
      </option>
      {categories && categories
        // .filter((cat) => cat.tag !== selected)
        .map((category, index) => (
          <option key={index} value={category.tag} className = "text-base border-0 outline-none uppercase bg-white text-headingColor">
            {category.name}
          </option>
        ))}
    </select>
  );
};

export default CategoriesSelector;

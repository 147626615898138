import {Link, useNavigate} from "react-router-dom";
import ProviderAuth, {ImageBox} from ".";
import {toast} from "react-toastify";

import {motion} from "framer-motion";
import {useState} from "react";
import {useStateValue} from "../../context/StateProvider";
import {EMAILSIGNIN} from "../../Firebase";
import {dispatchUsers, fetchFoodData, fetchTableData, ToggleAdminMode, fetchSessionData, fetchCategories, openSocket, fetchNotifications} from "../../utils/functions";
import {useTranslation} from "react-i18next";
import { Helmet } from "react-helmet-async";
import {buildLocalizedCanonicalPath, buildLocalizedPath} from "../../i18n/i18n";

const Login = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [{user}, dispatch] = useStateValue();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const fetchInitialData = async () => {
        ToggleAdminMode(dispatch, false);
        fetchFoodData(dispatch);
        fetchTableData(dispatch);
        dispatchUsers(dispatch);
        fetchSessionData(dispatch);
        openSocket(dispatch);
        fetchCategories(dispatch);
        fetchNotifications(dispatch);
    }

    const EmailAuth = () => {
        if (!user) {
            if (email.length > 0 && password.length > 0) {
                toast.promise(
                    EMAILSIGNIN(email, password),
                    {
                        pending: t('loginPending'),
                        success: t('loginSuccess'),
                        error: t('loginError'),
                    }
                ).then(async ({userData, orgData}) => {
                        i18n.changeLanguage(orgData.settings.language);
                        navigate(buildLocalizedPath("/"));
                        // Signed in
                        const user = userData;
                        dispatch({
                            type: "SET_USER",
                            user: user,
                        });
                        localStorage.setItem("user", JSON.stringify(user));

                        const org = orgData;
                        dispatch({
                            type: "SET_ORG",
                            org: org,
                        });
                        localStorage.setItem("org", JSON.stringify(org));

                        await fetchInitialData();
                    }
                ).catch((error) => {
                        // const errorCode = error.code;
                        const errorMessage = error.message;
                        toast.error(errorMessage, {autoClose: 1500});
                    }
                );

            } else {
                toast.warn(t('missingRequiredFieldError'), {autoClose: 1500});
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('loginMetaTitle')}</title>
                <meta name="description" content={t('loginMetaDesc')}/>
                <link rel="canonical" href={buildLocalizedCanonicalPath("/login")} hrefLang={i18n.language}/>
            </Helmet>
            <h1 className="hidden"> {t('loginMetaTitle')}</h1>
            <section className="w-full h-auto ">
                <div className="container md:py-10 h-full">
                    <div className="flex justify-center items-center flex-wrap h-full g-3 text-gray-800">
                        <ImageBox/>
                        <div className="w-full md:w-[30rem]">
                            <div
                                className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                                <p className="text-center text-textColor text-lg font-semibold mx-4 mb-0 uppercase">
                                    {t('login')}
                                </p>
                            </div>
                            <form className="p-2">
                                <ProviderAuth />
                                <div
                                    className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                                    <p className="text-center text-textColor text-sm font-semibold mx-4 mb-0">
                                        OR
                                    </p>
                                </div>
                                <div className="mb-6">
                                    <input
                                        type="text"
                                        className="form-control block w-full px-4 py-2  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-orange-600 focus:outline-none"
                                        placeholder={t('emailAddress')}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                                <div className="mb-6">
                                    <input
                                        type="password"
                                        className="form-control block w-full px-4 py-2  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-orange-600 focus:outline-none"
                                        placeholder={t('password')}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <div className="flex justify-between items-center mb-6">
                                    <Link
                                        to={buildLocalizedPath("/forgot-password")}
                                        className="text-orange-600 hover:text-orange-700 focus:text-orange-700 active:text-orange-800 duration-200 transition ease-in-out"
                                    >
                                        {t('forgotPassword')}
                                    </Link>
                                </div>

                                <motion.p
                                    className="cursor-pointer flex items-center justify-center px-7 py-3 bg-gradient-to-br from-orange-400 to-orange-500 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-orange-600 hover:shadow-lg focus:bg-orange-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                                    onClick={EmailAuth}
                                    whileHover={{scale: 1.1}}
                                >
                                    {t('login')}
                                </motion.p>

                                <div
                                    className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                                    <p className="text-center text-sm text-textColor font-semibold mx-4 mb-0">
                                        {t('dontHaveAnAccount')}
                                    </p>
                                </div>
                                <Link to={buildLocalizedPath("/register")}>
                                    <motion.p
                                        whileHover={{scale: 0.99}}
                                        className="cursor-pointer flex items-center justify-center px-7 py-3 bg-gradient-to-br from-yellow-400 to-yellow-500 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-orange-600 hover:shadow-lg focus:bg-orange-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                                    >
                                        {t('register')}
                                    </motion.p>
                                </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;

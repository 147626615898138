import React from 'react'
import { EmptyCartImg } from '../Assets'
import {useTranslation} from "react-i18next";

const EmptyCart = () => {
    const { t, i18n } = useTranslation();
  return (
    <div className='w-full p-5 flex flex-col items-center gap-4 justify-center'>
        <img className='h-[340px]' width={50} height={50} src={EmptyCartImg} alt='empty cart' loading="lazy" title='Order Master - Empty Cart'/>
        <p className="text-textColor  font-semibold">{t('cartIsEmpty')}</p>
    </div>
  )
}

export default EmptyCart
import React from 'react'
import { HeroBg } from '../Assets'
import StaticsImages from './Statics'
import { data } from './showcaseStatic'
const Right = () => {
  return (
    <div className="py-2 flex-1 flex items-center relative">
      <img src={HeroBg}  alt='Order Master - Homepage Image' title="Order Master - Homepage Image" width={50} height={50} className='ml-auto lg:h-[550px] h-[420px] w-full lg:w-auto' loading="lazy"/>
      <StaticsImages items = {data} />
    </div>
  )
}

export default Right
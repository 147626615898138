import {
  MdOutlineDataSaverOn,
  MdOutlineFastfood
} from "react-icons/md";

import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import { fetchTableData } from "../../../utils/functions";
import { addTable } from "../../../db";
import {useTranslation} from "react-i18next";

const AddTable = () => {
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaderMessage, setLoadermessage] = useState("");
  const [{ foodItems }, dispatch] = useStateValue();

  const saveItem = () => {
    setLoadermessage(`${t('savePending')} ${title}.`);
    setLoading(true);
    try {
      if (!title) {
        toast.error(t('missingRequiredFieldError'));
        setLoading(false);
        return;
      } else {
        const data = {
          title: title
        };
        toast
          .promise(addTable(data), {
            pending: t('savePending'),
            success: t('saveSuccess'),
            error: t('saveError'),
          })
          .then(() => {
            clearForm();
            setLoading(false);
            fetchTableData(dispatch);
          })
          .catch((error) => {
            console.log(error);
          });
        setLoadermessage("");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(t('saveError'));
    }
  };
  const clearForm = () => {
    setTitle("");
  };

  return (
    <div className="w-full h-fullflex items-center justify-center">
      <div className="border w-full  flex border-gray-300 items-center rounded-lg p-4 flex-col justify-center gap-4  ">
        <div className="w-full py-3 border-b border-gray-300 flex -tems-center gap-2">
          <MdOutlineFastfood className="text-xl text-gray-600" />
          <input
            type="text"
            required
            placeholder={t('enterTableName')}
            autoFocus
            className="h-full w-full  bg-transparent pl-2 text-textColor outline-none border-none placeholder:text-gray-400"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="w-full flex items-center justify-center">
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="ml-0 flex justify-center items-center gap-2 flex-row-reverse md:ml-auto w-full md:w-auto border-none outline-none rounded bg-orange-500 px-12 py-2 text-lg text-white"
            onClick={() => saveItem()}
          >
            <MdOutlineDataSaverOn /> {t('save')}
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default AddTable;

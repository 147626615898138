import { Avatar, Logo } from "../Assets";
import { Link } from "react-router-dom";
import { MdShoppingBasket } from "react-icons/md";
import DropDown from "./DropDown";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import LoginAction from "./LoginAction";
import MobileNav from "./mobile-nav";
import Navigations from "./Navigations";
import { RiArrowDropDownLine } from "react-icons/ri";
import { motion } from "framer-motion";
import { useStateValue } from "../../context/StateProvider";
import React from "react"
import { useEffect, useState } from "react";
import { Alert } from '../Admin/Alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useTranslation} from "react-i18next"; // Import css
import { createNotificationRequest } from "../../utils/functions";
import {LuConciergeBell} from "react-icons/lu";
import {buildLocalizedPath} from "../../i18n/i18n";

const Header = () => {
  //
  // const firebaseAuth = getAuth(app);
  const queryParameters = new URLSearchParams(window.location.search);
  const [{ user, showCart, cartItems, org, table, socket }, dispatch] = useStateValue();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);
  const [isFromPublic, setIsFromPublic] = useState(false);
  const { t, i18n } = useTranslation();
  const [isExpired, setIsExpired] = useState(false);

  function checkIsExpired() {
    const today = new Date().getTime();
    const expireDate = new Date(org.settings.subscriptionPlan.expiresAt).getTime();
    if (expireDate < today) {
      setIsExpired(true);
    }
  }

  useEffect(() => {
    if (queryParameters.get('oid')) {
      setIsFromPublic(true);
      if (org) {
        checkIsExpired();
      }
    }
  }, []);

  const handleToggleCart = () => {
    document.body.classList.add('modal-open');
    dispatch({
      type: "TOGGLE_CART",
      showCart: !showCart,
    });
  };

  function callWaiter() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Alert onClose={onClose} message={t('confirmationCallWaiter')} onConfirm={()=>{createNotificationRequest(table.title, socket, 'WAITER')}}/>
        );
      }
    });
  }

  return (
    <header className="w-screen fixed z-50 bg-cardOverlay backdrop-blur-md md:p-3 md:px-4 lg:p-6 lg:px-16">
      {/* Tablet and Desktop */}
      <div className="hidden md:flex w-full justify-between itesm-center">
        { isFromPublic ?
          <motion.div
          whileHover={{ scale: 1.1 }}
          className="flex items-center gap-2 cursor-pointer"
        >
          <img src={(org && org.photoUrl) || Logo} width={50} height={50} alt="Order Master - Organization Logo" title="Order Master - Organization Logo" className="md:w-6 lg:w-10 object-cover" loading="lazy"/>
          <p className="text-headingColor md:text-lg lg:text-xl font-bold">
            {(org && org.displayName) || 'Order Master'}
          </p>
        </motion.div>
        :
        <Link to={buildLocalizedPath("/")}>
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="flex items-center gap-2 cursor-pointer"
          >
            <img src={Logo} width={50} height={50} alt="Order Master - Organization Logo" title="Order Master - Organization Logo" className="md:w-6 lg:w-8 object-cover" loading="lazy"/>
            <p className="text-headingColor md:text-lg lg:text-xl font-bold">
              Order Master
            </p>
          </motion.div>
        </Link>
        }

        {/* navigation */}
        <Navigations />

        {/* User */}

        {user && !isFromPublic ? (
          <div className={`group flex items-center gap-3 px-3 py-1 rounded-lg`}>
            <motion.div
              whileHover={{ scale: 1.1 }}
              className=" flex items-center justify-center"
            >
              <img
                src={user.photoUrl || Avatar}
                className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-2xl rounded-full cursor-pointer object-contain"
                alt="profile"
                referrerPolicy="no-referrer"
                loading="lazy"
                title="Order Master - Profile Photo"
              />
              <p className="text-headingColor cursor-pointer flex items-center justify-center gap-2">
                <RiArrowDropDownLine />
              </p>
            </motion.div>
            <DropDown user={user} />
          </div>
        ) : ( isFromPublic ? '' :
          <LoginAction text={t('login')} />
        )}
      </div>

      {/* Mobile */}
      <motion.div
        className="flex md:hidden w-full p-0 items-center justify-between"
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 200 }}
      >
        {isOpenMobileNav ? (
          <MobileNav isOpen={isOpenMobileNav} setIsOpen={setIsOpenMobileNav} />
        ) : (
          <div className="p-3 flex items-center justify-between w-full">
            {isFromPublic ? '' :<motion.div
              whileTap={{ scale: 0.9 }}
              className=" flex items-center justify-center"
              onClick={() => setIsOpenMobileNav(!isOpenMobileNav)}
            >
              <HiOutlineMenuAlt2 className="text-headingColor text-4xl" />
            </motion.div>}
            { isFromPublic ?
                <motion.div
                whileHover={{ scale: 1.1 }}
                className="flex items-center gap-2 cursor-pointer"
              >
                <img src={(org && org.photoUrl) || Logo} width={50} height={50} alt="Order Master - Organization Logo" title="Order Master - Organization Logo" className="w-16 object-cover" loading="lazy"/>
                <p className="text-headingColor text-xl font-bold">
                {(org && org.displayName) || 'Order Master'}
                </p>
              </motion.div>
            :
              <Link to={buildLocalizedPath('/')}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <img src={Logo} width={50} height={50} alt="Order Master - Organization Logo" title="Order Master - Organization Logo" className="w-8 object-cover" loading="lazy"/>
                  <p className="text-headingColor text-xl font-bold">
                    Order Master
                  </p>
                </motion.div>
              </Link>}
              {
                isFromPublic && !isExpired  && table &&
                <>
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1 }}
                  initial={{ opacity: 0, x: 200 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 200 }}
                  className="relative flex items-center justify-center text-textColor"
                  onClick={callWaiter}
                >
                  <LuConciergeBell className="text-5xl cursor-pointer" />
                </motion.div>
                {
                  org && org.settings.feature.orderMode &&
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.1 }}
                    initial={{ opacity: 0, x: 200 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 200 }}
                    className="relative flex items-center justify-center text-textColor"
                    onClick={handleToggleCart}
                  >
                    <MdShoppingBasket className="text-5xl cursor-pointer" />
                    {cartItems && (
                      <div className="absolute -top-2 -right-2 w-7 h-7 rounded-full bg-cartNumBg flex items-center justify-center">
                        <p className="text-xl text-white font-semibold">
                          {cartItems.length}
                        </p>
                      </div>
                    )}
                  </motion.div>
                }
                </>
              }
            {user && !isFromPublic ? (
              <div
                className={`flex items-center gap-3 px-3 py-1 rounded-lg relative`}
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="group flex items-center justify-center"
                >
                  <img
                    src={user?.photoUrl ? user.photoUrl : Avatar}
                    className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-2xl rounded-full cursor-pointer"
                    onClick={() => setIsOpen(!isOpen)}
                    loading="lazy"
                    alt="Order Master - Profile Photo"
                    title="Order Master - Profile Photo"
                  />
                  <p className="text-headingColor cursor-pointer flex items-center justify-center gap-2">
                    <RiArrowDropDownLine />
                  </p>
                  {isOpen && <DropDown user={user}  />}
                </motion.div>
              </div>
            ) : (
              isFromPublic ? '' : <LoginAction mobile />
            )}
          </div>
        )}
      </motion.div>
    </header>
  );
};

export default Header;

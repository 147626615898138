import React, {useEffect} from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {useTranslation} from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

// Options to hide 0 values
export const options = {
    plugins: {
        datalabels: {
            display: function (context: any) {
                return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
            }
        }
    }
};

const PieChart = ({
    chartData
  }: {
    chartData: any;
  }) => {
    const { t, i18n } = useTranslation();

    const data  = {
        labels: chartData.labels,
        datasets: [
            {
            label: t('chartNumberOfItem'),
            data: chartData.data,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(205, 49, 82, 0.2)',
                'rgba(4, 112, 185, 0.2)',
                'rgba(205, 156, 36, 0.2)',
                'rgba(25, 142, 142, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(205, 49, 82, 1)',
                'rgba(4, 112, 185, 1)',
                'rgba(205, 156, 36, 1)',
                'rgba(25, 142, 142, 1)',
            ],
            borderWidth: 2,
            },
        ],
    };
    useEffect(() => {
        
    }, []);

    return (
        <>
            {
                <Pie data={data} options={options}/>
            }
        </>
    );
}

export default PieChart
import { fetchSessionUser, fetchSessionUserMode, fetchSessionOrg } from "../utils/fetchSessionData";

const sessionUser = fetchSessionUser();
const sessionOrg = fetchSessionOrg();
const sessionUserMode = fetchSessionUserMode();
export const initialState = {
    user: sessionUser,
    org: sessionOrg,
    foodItems: null,
    tables: null,
    showCart: false,
    showContactForm: false,
    cartItems: [],
    cartTotal: 0,
    adminMode: sessionUserMode,
    users: [],
    paymentMethod: 'mobile_money',
    checkoutData: {},
}
// import React from 'react'

import { MdOutlineRestaurantMenu, MdShoppingBasket } from "react-icons/md";
import React from "react"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../Assets";
import { motion } from "framer-motion";
import { useStateValue } from "../../context/StateProvider";
import {useTranslation} from "react-i18next";
import {buildLocalizedPath} from "../../i18n/i18n";

const MobileNav = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: any;
}) => {
  const [{ showContactForm, showCart, cartItems, org }, dispatch] = useStateValue();
  const queryParameters = new URLSearchParams(window.location.search);
  const [isFromPublic, setIsFromPublic] = useState(false);
  const { t, i18n } = useTranslation();

  const handleToggleCart = () => {
    dispatch({
      type: "TOGGLE_CART",
      showCart: !showCart,
    });
  };
  const handleToggleContact = () => {
    dispatch({
      type: "TOGGLE_CONTACT_FORM",
      showContactForm: !showContactForm,
    });
  }
  useEffect(() => {
    if (queryParameters.get('oid')) {
      setIsFromPublic(true);
    }
  }, []);
  return (
    <div className="flex flex-col bg-cardOverlay backdrop-blur-sm items-start justify-start gap-16 w-screen h-screen  overflow-y-hidden  z-50 overflow-hidden ">
      <motion.div className="flex items-center justify-between w-screen h-24  px-10">
        {
          isFromPublic && org && org.settings.feature.orderMode &&
            <motion.div
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.1 }}
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            className="relative flex items-center justify-center text-textColor"
            onClick={handleToggleCart}
          >
            <MdShoppingBasket className="text-4xl cursor-pointer" />
            {cartItems && (
              <div className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-cartNumBg flex items-center justify-center">
                <p className="text-sm text-white font-semibold">
                  {cartItems.length}
                </p>
              </div>
            )}
          </motion.div>
        }
        <motion.div
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0, x: 200 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 200 }}
          className="relative flex items-center justify-center text-textColor"
          onClick={() => setIsOpen(!isOpen)}
        >
          <MdOutlineRestaurantMenu className="text-headingColor text-4xl" />
        </motion.div>
      </motion.div>
      <div
        className={`flex items-center justify-center w-full  h-72 gap-10 flex-col`}
      >
        {
          isFromPublic &&
          <Link onClick={() => setIsOpen(!isOpen)} to={'/menu'} className="text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out px-10">
            Menu
          </Link>
        }
        <Link onClick={() => setIsOpen(!isOpen)} to={buildLocalizedPath('/services')} className="text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out px-10">
          {t('services')}
        </Link>
        <Link onClick={() => setIsOpen(!isOpen)} to={buildLocalizedPath('/about')} className="text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out px-10">
          {t('aboutUs')}
        </Link>
        <p onClick={handleToggleContact} className="text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out px-10">
          {t('contactUs')}
        </p>
        <Link onClick={() => setIsOpen(!isOpen)} to={buildLocalizedPath('/pricing')} className="text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out px-10">
          {t('pricing')}
        </Link>
      </div>

      <Link
        to={buildLocalizedPath("/")}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center  justify-center w-full"
      >
        <motion.div
          whileTap={{ scale: 0.9 }}
          whileHover={{ scale: 1.1 }}
          className="flex items-center gap-2 cursor-pointer"
        >
          <img src={Logo} alt="Order Master - Organization Logo" title="Order Master - Organization Logo" height={50} width={50} className="w-16 object-cover" loading="lazy"/>
          <p className="text-headingColor text-3xl font-bold">Order Master</p>
        </motion.div>
      </Link>
    </div>
  );
};

export default MobileNav;

import React from 'react';
import {useEffect} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, DatasetChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useTranslation} from "react-i18next";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
        position: 'top' as const,
        },
        title: {
        display: false,
        text: 'Chart.js Line Chart',
        },
    },
    scale: {
        ticks: {
            precision: 0,
            min: 0
        }
    }
};

const LineChart = ({
    chartData
  }: {
    chartData: any;
  }) => {
    const { t } = useTranslation();

    const data = {
        labels: chartData.labels,
        datasets: [
          {
            label: t('chartNumberOfItem'),
            data: chartData.data,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          }
        ],
      };
    useEffect(() => {
        
    }, []);

    return (
        <>
            {
                <Line data={data} options={options} />
            }
        </>
    );
}

export default LineChart
import {CgDetailsMore} from "react-icons/cg";
import { Order, Session } from "../../types";
import { motion } from "framer-motion";
import {AiOutlineClose, AiOutlineCheck} from "react-icons/ai";
import {approveOrder, deleteOrder} from "../../utils/functions";
import {useStateValue} from "../../context/StateProvider";
import React, { useState, useEffect } from "react";
import { Alert } from '../Admin/Alert';
import { confirmAlert } from 'react-confirm-alert';
import { Receipt } from "../Admin/Receipt";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useTranslation} from "react-i18next"; // Import css

export const SingleOrder = ({
  setOrderId,
  orderOpen,
  orderId,
  createdAt,
}: {
  setOrderId: any;
  orderOpen: any;
  orderId: string;
  createdAt: string;
}) => {
  const { t, i18n } = useTranslation();
  const [{ sessions, socket }, dispatch] = useStateValue();
  const [orderName, setOrderName] = useState("");
  const [orderStatus, setOrderStatus] = useState("APPROVED")
  const [tableId, setTableId] = useState("");

  function approve() {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert onClose={onClose} message={t('approveOrderConfirmation')} onConfirm={()=>{approveOrder(orderId, tableId, sessions, dispatch, socket);}}/>
          );
        }
    });
  };

  function remove() {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Alert onClose={onClose} message={t('deleteOrderConfirmation')} onConfirm={()=>{deleteOrder(orderId, tableId, sessions, dispatch, socket);}}/>
          );
        }
    });
  };

  useEffect(() => {
    if(sessions) {
      const date = new Date(createdAt);
      const name = (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
      sessions.forEach((session: any) => {
        session.orders.forEach((order: any) => {
          if(order.id === orderId) {
            setTableId(session.tableId);
            setOrderStatus(order.status);
            setOrderName(session.tableTitle + ' - ' + name);
          }
        })
     })
    }
  }, []);

  return (
    <motion.div
      className={`w-full flex flex-row justify-center gap-3 rounded-md hover:bg-orange-200`}
    >
      <div className="w-full flex justify-left flex-row gap-2 px-3 py-1 items-center">
        <p className="text-textColor font-semi-bold text-lg items-center justify-center">{orderName}</p>
      </div>
      <div className="w-full flex flex-row justify-end gap-2 px-3 py-1">
        {
          sessions && sessions.map((session: Session)=>{
            return session && session.orders.map((item: Order) => (
              item.id === orderId && item.status === "PENDING" ?
              <motion.div
                  whileTap={{scale: 1.1}}
                  whileHover={{scale: 1.2}}
                  className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-green-600 flex items-center justify-center cursor-pointer"
                  title={t('confirm')}
                  onClick={approve}
              >
                  <AiOutlineCheck className="text-white md:text-xl"/>
              </motion.div>
              : ''
            ))
          })
        }

        <motion.div
            whileTap={{scale: 1.1}}
            whileHover={{scale: 1.2}}
            className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer"
            title={t('delete')}
            onClick={remove}
        >
            <AiOutlineClose className="text-white md:text-xl"/>
        </motion.div>
        <motion.div
                whileTap={{scale: 1.1}}
                whileHover={{scale: 1.2}}
                className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-orange-600 flex items-center justify-center cursor-pointer"
                title={t('details')}
                onClick={() => {orderOpen(true); setOrderId(orderId);}}
            >
                <CgDetailsMore className="text-white md:text-xl"/>
        </motion.div>
        <Receipt key={orderId} id={orderId} isOrder={true} ></Receipt>
      </div>

    </motion.div>
  );
};

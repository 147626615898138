import {Money, Order} from "../../types";
import {useStateValue} from "../../context/StateProvider";
import {useEffect, useState} from "react";
import {getFoodyById} from "../../utils/functions";
import {useTranslation} from "react-i18next";

const OrderItem = ({item}: { item: Order }) => {
    const {t} = useTranslation();
    const [{foodItems, orders}, dispatch] = useStateValue();
    item.totalPrice = new Money(item.totalPrice.amount);
    const {id, cartItems, status, totalPrice, createdAt} = item;
    const [createTime, setCreateTime] = useState('');
    const [cartFoodItems, setCartFoodItems] = useState<any>([]);
    useEffect(() => {
        const date = new Date(createdAt);
        setCreateTime((date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes());
        let cartFoodItemsArr: any = [];
        cartItems.map((item) => {
            const foodItem = getFoodyById(foodItems, item.menuItemId);
            cartFoodItemsArr.push({
                title: foodItem?.title,
                qty: item.qty,
                price: foodItem?.price
            })
        });
        setCartFoodItems(cartFoodItemsArr);
    }, [orders]);

    return (
        <div
            className="w-full p-1 px-2 rounded-lg bg-cartItem hover:shadow-md flex items-center justify-between gap-2 cursor-default ">
            <div className=" flex items-center  gap-2">
                <div className="flex flex-col gap-0 ">
                    <p className="text-base text-gray-50">{t('orderTime')}:{' ' + createTime}</p>
                    {
                        cartFoodItems && cartFoodItems.map((item: any) => (
                            <div className=" w-full flex justify-between items-center gap-2">
                                <div className="group flex items-center gap-2 cursor-default">
                                    <p className="text-sm text-gray-50">{item.title}</p>
                                </div>
                                <div className="group flex items-center gap-2 cursor-default">
                                    <p className="text-sm block text-gray-50 w-5 h-5 rounded-sm bg-cartBg flex items-center justify-center cursor-default">{item.qty}</p>
                                </div>
                                <div className="group flex items-center gap-2 cursor-default">
                                    <p className="text-sm block text-gray-50">x</p>
                                </div>
                                <div className="group flex items-center gap-2 cursor-default">
                                    <p className="text-sm block text-gray-50"><span
                                        className="text-xs text-red-600">₺</span>{item.price.toMajorUnit()}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="group flex items-center gap-2  cursor-default">
                <p className="text-sm block text-gray-300 font-semibold">
                    {t('orderTotal')}{':'}<span className="text-xs text-red-600">₺</span>{totalPrice.toMajorUnit()}
                </p>
            </div>
        </div>
    );
};

export default OrderItem;
